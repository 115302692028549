import { createStore, createLogger } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";

export default (LOG_STORE) =>
  createStore({
    strict: true,
    state: {
      prompt: {
        visible: false,
        message: null,
        webhook: null,
        schema: null,
      },

      settings: {
        add_next: false, // when creating states, a Next Action is added automatically
      },

      styles: {
        logic: {
          bgColorAction: "has-background-green-4",
          bgColorNext: "has-background-green-5",
        },
        time: {
          bgColorAction: "has-background-orange-4",
          bgColorNext: "has-background-orange-5",
        },
        data: {
          bgColorAction: "has-background-yellow-4",
          bgColorNext: "has-background-yellow-5",
        },
        control: {
          bgColorAction: "has-background-purple-4",
          bgColorNext: "has-background-purple-5",
        },
        telegram: {
          bgColorAction: "has-background-blue-4",
          bgColorNext: "has-background-blue-5",
        },
        twilio: {
          bgColorAction: "has-background-blue-4",
          bgColorNext: "has-background-blue-5",
        },
        default: {
          bgColorAction: "has-background-blue-4",
          bgColorNext: "has-background-blue-5",
          text: "has-text-white",
        },
      },

      selected: {
        game: null, // name of the game e.g. "test"
        level: null, // name of level open in editor e.g. "how_to",
        level_id: null, // _id of level currently selected
        action: null, // name of action selected. if not null sidebar is open, getter for schema
        state: null, // if action selected, or state name etc.
        is_live: false, // UI is in live mode
        session: null, // id of session selected
        serverlogVisible: false, // show or hide serverlog component
        authVisible: false, // show Auth component
      },

      data: {
        game_list: null, // list of all games
        game: null, // list of all levels in selected game
        level: null, // object with level data
        level_schema: null, // level schema
        level_variables: null, // list of all variables and media for toolbar
        media: null, // list of all media files of this game
        actions: null, // list of all actions
        sessions: null, // list of all sessions in current game
        collections: { _keys: {} }, // object with all collections documents and their keys
        plugins: null, // list of all plugins active in current game
        plugins_list: null, // list of all adaptor plugins
        serverlog: [], // last log messages received from server
        items: [], // list of changed Plugin Items or Collection Documents
      },
    },
    actions,
    mutations,
    getters,
    plugins: LOG_STORE
      ? [
          createLogger({
            collapsed: false, // auto-expand logged mutations
            // eslint-disable-next-line no-unused-vars
            filter(mutation, stateBefore, stateAfter) {
              // returns `true` if a mutation should be logged
              // `mutation` is a `{ type, payload }`
              return mutation.type !== "aBlocklistedMutation";
            },
            // eslint-disable-next-line no-unused-vars
            actionFilter(action, state) {
              // same as `filter` but for actions
              // `action` is a `{ type, payload }`
              return action.type !== "aBlocklistedAction";
            },
            transformer(state) {
              // transform the state before logging it.
              // for example return only a specific sub-tree
              return state;
            },
            mutationTransformer(mutation) {
              // mutations are logged in the format of `{ type, payload }`
              // we can format it any way we want.
              return mutation;
            },
            actionTransformer(action) {
              // Same as mutationTransformer but for actions
              return action.type;
            },
            logActions: true, // Log Actions
            logMutations: true, // Log mutations
            logger: console, // implementation of the `console` API, default `console`
          }),
        ]
      : [],
  });
