<template>
  <div>
    <div v-if="data">
      <div>
        <div @click.stop="$emit('selectAction')" class="is-cursor-pointer">
          <span class="has-text-black has-text-weight-semibold">
            {{ data.name || data.action }}
          </span>
          <!-- <hr class="state-hr has-background-black" /> -->
        </div>
        <component
          v-if="getFormRenderer"
          :is="getFormRenderer"
          :id="id"
          :data="data"
          @dataChanged="updateAction"
          @dataValidation="onDataValidation"
        ></component>
        <FormDefault
          v-else
          :data="data"
          :id="id"
          :schema="schema"
          @dataChanged="updateAction"
          @dataValidation="onDataValidation"
        />
      </div>
      <button
        @click.stop="$emit('selectAction')"
        class="button has-text-weight-semibold is-small mt-1 is-full-width has-background-white has-border"
      >
        CLOSE
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, resolveDynamicComponent, inject } from "vue";

//import telegram_dialog from "@/components/Form/telegram_dialog.vue";
import FormDefault from "@/components/Form/form_default.vue";

export default {
  name: "ActionEditor",
  emits: ["selectAction", "validateAction"],
  props: {
    stateId: {
      type: String,
      required: true,
    },
  },
  components: {
    //telegram_dialog,
    FormDefault,
  },
  setup(props, { emit }) {
    const componentExists = (name) => {
      return typeof resolveDynamicComponent(name) !== "string";
    };
    const store = useStore();
    const dragOverState = inject("dragOverState"); // Note: counter to handle drag over state events
    const id = computed(() => store.state.selected.action);
    const data = computed(() =>
      store.getters.actionByIdResolved(store.state.selected.action)
    );
    const schema = computed(() => store.getters.selectedActionSchema);
    const getFormRenderer = computed(() => {
      let componentName = data.value.plugin + "_" + data.value.action;
      return componentExists(componentName) ? componentName : null;
    });
    const updateAction = (e) => {
      // on update action we asume somthing got dropped or there was no droppabale data
      // so we reset the states drag over style
      // we need to do this right now, because we can not allow event propagation to the state
      dragOverState.value = 0;
      store.dispatch("updateAction", {
        value: e,
        actionId: id.value,
        stateId: props.stateId,
      });
    };
    const onDataValidation = (value) => {
      // console.log("ActionEditor data validation:", value.errors);
      emit("validateAction", value.valid);
    };
    return {
      id,
      data,
      schema,
      updateAction,
      getFormRenderer,
      onDataValidation,
    };
  },
};
</script>
