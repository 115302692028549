import OpenAPIClientAxios from "openapi-client-axios";
import { useToast } from "vue-toastification";

export class RestApi {
  constructor(API_URL) {
    this.toast = useToast();
    this.api = new OpenAPIClientAxios({
      definition,
      axiosConfigDefaults: {
        baseURL: API_URL,
        /*auth: {
          username: "Development",
          password: "dev_password",
        },*/
        headers: {
          common: {
            Authorization: "Basic " + localStorage.authtoken, // set by Auth.vue which will be shown on every 401 (Not authenticated) response
          },
        },

        withCredentials: true,
      },
    });
  }

  initClient() {
    const client = this.api.initSync();
    client.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );
    return client;
  }

  errorHandler(error) {
    console.log(error.response.status);
    if (!error.response) {
      console.error("API", error);
      this.toast.error(
        `Error talking to the server. Got message: ${error.message}.\nMaybe the server is down, or you are using the wrong API path. \nYou are trying to connect to the server at ${this.api.axiosConfigDefaults.baseURL}`
      );
    } else if (error.response.status == 401) {
      // if Authentication Error: Show Auth Component Auth.vue
      localStorage.setItem("authtoken", null);
      let event = new Event("toggleAuth", { bubbles: true });
      document.dispatchEvent(event);
    }

    if (error.response?.data?.name && error?.response?.data?.message)
      console.error(
        "API",
        error.response.data.name,
        error.response.data.message,
        error.response
      );

    error.name = "API Error";
    return Promise.reject(error);
  }

  successHandler(response) {
    return response;
  }
}

// TODO: dynamiclly load API_DEFINITION;
const definition = {
  openapi: "3.0.2",
  info: {
    title: "adaptor:ex RESTful API",
    version: "0.0.1",
    description:
      'Access, create and modify adaptor games. With authentication enabled all requests return 401 status "#/components/responses/UnauthorizedError"',
    license: {
      name: "MIT",
      url: "https://mit-license.org/",
    },
    contact: {
      name: "adaptor@machinaex.de",
      url: "https://www.machinaex.com",
    },
  },
  servers: [
    {
      url: "{protocol}://{domain}:{port}/api",
      description: "Custom server",
      variables: {
        protocol: {
          enum: ["http", "https"],
          default: "http",
        },
        domain: {
          default: "localhost",
        },
        port: {
          default: "8080",
        },
      },
    },
    {
      url: "https://adaptordev.machinaex.org/api",
      description: "Adaptor Development server",
    },
  ],
  tags: [
    {
      name: "root",
      description: "adaptor:ex main features, relevant to all games",
    },
    {
      name: "user",
      description: "organize users and user sessions",
    },
    {
      name: "game",
      description: "interact with a game",
    },
    {
      name: "level",
      description: "get level data, create and modify levels",
    },
    {
      name: "state",
      description: "get levels state data, create and modify states",
    },
    {
      name: "schema",
      description: "get json schema architecture data",
    },
    {
      name: "session",
      description: "get, launch and cancel sessions",
    },
    {
      name: "plugin",
      description:
        "add and remove plugins and get informations about active plugins",
    },
    {
      name: "plugin items",
      description:
        "add and remove plugins and get informations about active plugins",
    },
    {
      name: "collection",
      description:
        "add and remove data collections and create, update and remove  data collection documents.\n",
    },
    {
      name: "items",
      description: "combined plugin and data collection items endpoint",
    },
  ],
  paths: {
    "/config": {
      get: {
        tags: ["root"],
        summary: "get adaptor config",
        operationId: "getConfig",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    url: {
                      type: "string",
                      description:
                        "url shows plugins where to find the adaptor in the internet",
                    },
                    host: {
                      type: "string",
                      default: "http://localhost",
                    },
                    port: {
                      type: "integer",
                      default: 8080,
                    },
                    database: {
                      type: "object",
                      description:
                        "database connection setup. NeDB requires files property, MongoDB requires url property.",
                      properties: {
                        type: {
                          type: "string",
                          enum: ["nedb", "mongodb"],
                        },
                        url: {
                          type: "string",
                          default:
                            "mongodb://localhost:27017?replicaSet=adaptor-repl",
                        },
                      },
                    },
                    level: {
                      type: "string",
                      description: "current log level",
                      enum: ["error", "warn", "info", "debug", "trace"],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "/plugins": {
      get: {
        tags: ["root", "plugin"],
        summary: "get available plugins",
        description:
          "get a list of all plugins that may be installed by games. Descriptions are yet to be made accessible by plugins.\n",
        operationId: "getPlugins",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    name: {
                      type: "string",
                    },
                    description: {
                      type: "string",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "/user": {
      get: {
        tags: ["root", "user"],
        summary: "get user list",
        operationId: "getUser",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/user",
                  },
                },
              },
            },
          },
        },
      },
      post: {
        tags: ["root", "user"],
        summary: "create a new user",
        description: "creates a new user that can login to this adaptor editor",
        operationId: "createUser",
        requestBody: {
          description: "user properties",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/user",
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
    },
    "/me": {
      get: {
        tags: ["root", "user"],
        summary: "get information about this user session",
        operationId: "getMe",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/user_session",
                },
              },
            },
          },
        },
      },
      put: {
        tags: ["root", "user"],
        summary: "change my user data",
        operationId: "updateMe",
        requestBody: {
          description: "modified user data",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/user",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/games": {
      get: {
        tags: ["root", "game"],
        summary: "get list of existing games",
        operationId: "getGames",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/game_preview",
                  },
                },
              },
            },
          },
        },
      },
    },
    "/game": {
      post: {
        tags: ["root", "game"],
        summary: "create a new game",
        description:
          "creates a new game and database or loads game from an existing database",
        operationId: "createGame",
        requestBody: {
          description: "game properties",
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                  template: {
                    type: "string",
                    enum: ["basic", "multiplayer", "openworld"],
                  },
                },
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
    },
    "/game/{game}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      get: {
        tags: ["game"],
        summary: "get game data overview",
        description: "preview data describing the game.",
        operationId: "getGame",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/game",
                },
              },
            },
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["game"],
        description: "delete game with name",
        operationId: "deleteGame",
        responses: {
          204: {
            description: "game delete successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/setup": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      post: {
        tags: ["game"],
        summary: "edit game setup",
        description: "change game setup elements.\n",
        deprecated: true,
        operationId: "editGameSetup",
        requestBody: {
          description: "update values",
          content: {
            "application/json": {
              schema: {
                type: "object",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/media": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      get: {
        tags: ["game"],
        summary: "get list of media files",
        operationId: "getMedia",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      post: {
        tags: ["level"],
        summary: "create new level",
        description:
          'If only name (required) is provided, a default level will be created as descirbed in "#/components/schemas/level/default"',
        operationId: "createLevel",
        requestBody: {
          description: "level properties",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/level",
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
      get: {
        tags: ["level"],
        summary: "find levels by using query parameters",
        description:
          "omit parameters to get all the levels or use _sort_by parameter to get a sorted list of levels that match the query",
        operationId: "findLevel",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "level data",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/level",
                  },
                },
              },
            },
          },
        },
      },
    },
    "/game/{game}/level/{level}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/level",
        },
      ],
      get: {
        tags: ["level"],
        summary: "get level data",
        operationId: "getLevel",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/level",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      put: {
        tags: ["level"],
        summary: "update level data",
        description:
          "Replace level document. Make sure to include created_at and created_by properties otherwise they are lost. Known Bug: If you use name as identication, updating the name property will leed to missing modification information\n",
        operationId: "updateLevel",
        requestBody: {
          description: "modified level data",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/level",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["level"],
        summary: "delete level with _id",
        operationId: "deleteLevel",
        responses: {
          204: {
            description: "level delete successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level/{level}/{operator}": {
      post: {
        tags: ["level"],
        summary: "edit level using update operator",
        description:
          "Known Bug: If you use name as identification, updating the name property will leed to missing modification information\n",
        operationId: "editLevel",
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
          {
            $ref: "#/components/parameters/level",
          },
          {
            $ref: "#/components/parameters/operator",
          },
        ],
        requestBody: {
          description: "update values",
          content: {
            "application/json": {
              schema: {
                type: "object",
                example: {
                  name: "newName",
                  "config.arguments.Player.query": "{name:'Testplayer'}",
                },
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level/{level}/variables": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/level",
        },
      ],
      get: {
        tags: ["level"],
        summary: "get variables and functions of level context",
        operationId: "getLevelVariables",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level/{level}/states": {
      get: {
        tags: ["level", "state"],
        summary:
          "get list of level states (This is not implemented because it probably has no use)",
        deprecated: true,
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
          {
            $ref: "#/components/parameters/level",
          },
        ],
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/state",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level/{level}/state": {
      post: {
        tags: ["state", "level"],
        summary: "create new state",
        operationId: "createState",
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
          {
            $ref: "#/components/parameters/level",
          },
        ],
        requestBody: {
          description:
            "create new states, actions and contents. Use existing state id to replace existing state.",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/states",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200StateUpdate",
          },
          201: {
            $ref: "#/components/responses/201StateCreated",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
    },
    "/game/{game}/level/{level}/state/{state}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/level",
        },
        {
          $ref: "#/components/parameters/state",
        },
      ],
      get: {
        tags: ["level", "state"],
        summary: "get state data (not implemented yet)",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/state",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      put: {
        tags: ["level", "state"],
        summary: "replace state data",
        operationId: "updateState",
        requestBody: {
          description: "modified state data",
          content: {
            "application/json": {
              schema: {
                type: "object",
                description:
                  "state properties, action and content elements of a selected state",
                properties: {
                  state: {
                    $ref: "#/components/schemas/state",
                  },
                  actions: {
                    type: "object",
                    additionalProperties: {
                      $ref: "#/components/schemas/action",
                    },
                  },
                  contents: {
                    type: "object",
                    additionalProperties: {
                      $ref: "#/components/schemas/content",
                    },
                  },
                },
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200StateUpdate",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["level", "state"],
        summary: "delete state with id",
        operationId: "deleteState",
        responses: {
          200: {
            $ref: "#/components/responses/200StateUpdate",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/level_schema": {
      get: {
        tags: ["level", "schema", "game"],
        summary: "get current game level schema",
        description:
          "each level has to validate against this schema. All plugin action and  listener schemas are merged inside. It may change and you can subscribe  to changes. See ./asyncapi.yaml. This might be of \n",
        operationId: "getLevelSchema",
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
        ],
        responses: {
          200: {
            description:
              "successful. Response content-length might well be > 50000",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/meta_schema",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/actions": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      get: {
        tags: ["schema", "game"],
        summary: "get schemas of all actions currently available",
        description:
          "Omit query parameters to get all actions available.  Use query parameters to get a single action or all actions of a plugin.\n",
        operationId: "getActions",
        parameters: [
          {
            name: "action",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            name: "plugin",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/action_schema",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/session": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      post: {
        tags: ["session"],
        summary: "launch new session",
        operationId: "launchSession",
        requestBody: {
          description: "session properties",
          content: {
            "application/json": {
              schema: {
                type: "object",
                required: ["level"],
                properties: {
                  name: {
                    type: "string",
                    description: "optional name property to identify session",
                  },
                  level: {
                    type: "string",
                    description: "level name or _id the session is based on",
                  },
                  arguments: {
                    $ref: "#/components/schemas/arguments",
                  },
                },
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      get: {
        tags: ["session"],
        summary: "find sessions by using query parameters",
        description:
          "omit parameters to get all the sessions or use _sort_by parameter to get  a sorted list of sessions that match the query\n",
        operationId: "findSessions",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            name: "level",
            description: "level _id",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            name: "level_name",
            description: "level name property",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "session data",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/session",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/session/{session}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/session",
        },
      ],
      get: {
        tags: ["session"],
        summary: "get session data",
        operationId: "getSession",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/session",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["session"],
        summary: "cancel session with _id",
        operationId: "cancelSession",
        responses: {
          204: {
            description: "session cancel successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/session/{session}/_next": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/session",
        },
      ],
      post: {
        tags: ["session"],
        summary: "cue next state",
        operationId: "next",
        requestBody: {
          description: "state identification query",
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                  id: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Executed",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      post: {
        tags: ["plugin"],
        summary: "add plugin to palette",
        operationId: "addPlugin",
        requestBody: {
          description: "plugin name",
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
        responses: {
          201: {
            description: "plugin added successfully",
            content: {
              "text/plain": {
                schema: {
                  type: "boolean",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      get: {
        tags: ["plugin", "schema"],
        summary: "find plugin by using query parameters",
        description:
          "omit parameters to get all the plugins or use _sort_by parameter to get  a sorted list of plugins that match the query\n",
        operationId: "findPlugins",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "plugin data",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/plugin",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
      ],
      get: {
        tags: ["plugin", "schema"],
        summary: "get plugin settings and items",
        operationId: "getPlugin",
        description:
          "Plugin contains schemas for settings and collections. Schemas might change. You can subsricbe to changes. See ./asyncapi.yaml",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/plugin",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["plugin"],
        summary: "remove plugin from game",
        description: "This deletes all the items and settings!",
        operationId: "removePlugin",
        responses: {
          204: {
            description: "plugin remove successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/settings": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
      ],
      put: {
        tags: ["plugin"],
        summary: "update plugin settings",
        description: "Replace plugin settings\n",
        operationId: "updatePluginSettings",
        requestBody: {
          description: "modified plugin settings data",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/plugin/properties/settings/properties/data",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/_load": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
      ],
      post: {
        tags: ["plugin"],
        summary: "load plugin setup and data",
        operationId: "loadPlugin",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/plugin",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/_connect": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
      ],
      post: {
        tags: ["plugin"],
        summary: "connect plugin",
        operationId: "connectPlugin",
        requestBody: {
          description:
            "plugin item settings. If omitted connects with current settings.",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/plugin/properties/settings",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Connect",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/_disconnect": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
      ],
      post: {
        tags: ["plugin"],
        summary: "disconnect plugin",
        operationId: "disconnectPlugin",
        responses: {
          200: {
            $ref: "#/components/responses/200Connect",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
      ],
      post: {
        tags: ["plugin", "plugin items"],
        summary: "create a new item in a plugin collection",
        operationId: "createPluginItem",
        requestBody: {
          description: "item to be added to plugin collection",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/plugin_item",
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
      get: {
        tags: ["plugin", "plugin items"],
        summary: "find plugin items by using query parameters",
        description:
          "omit parameters to get all the items or use _sort_by parameter to get a sorted list of items that match the query",
        operationId: "findPluginItems",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "list of items",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/plugin_item",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}/{item}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
        {
          $ref: "#/components/parameters/plugin_item",
        },
      ],
      get: {
        tags: ["plugin", "plugin items"],
        summary: "get plugin item data",
        operationId: "getPluginItem",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["plugin", "plugin items"],
        summary: "delete plugin item",
        operationId: "deletePluginItem",
        responses: {
          204: {
            description: "plugin item delete successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}/{item}/_set": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
        {
          $ref: "#/components/parameters/plugin_item",
        },
      ],
      post: {
        tags: ["plugin", "plugin items"],
        summary: "edit plugin item using update set operator",
        operationId: "editPluginItem",
        requestBody: {
          description: "update set values",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/plugin_item",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}/{item}/_connect": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
        {
          $ref: "#/components/parameters/plugin_item",
        },
      ],
      post: {
        tags: ["plugin", "plugin items"],
        summary: "connect plugin item",
        operationId: "connectPluginItem",
        requestBody: {
          description:
            "plugin item settings. If omitted connects with current settings.",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/plugin_item/properties/settings",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Connect",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}/{item}/_disconnect": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
        {
          $ref: "#/components/parameters/plugin_item",
        },
      ],
      post: {
        tags: ["plugin", "plugin items"],
        summary: "disconnect plugin item",
        operationId: "disconnectPluginItem",
        responses: {
          200: {
            $ref: "#/components/responses/200Connect",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/plugin/{plugin}/{plugin_collection}/{item}/_load": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/plugin",
        },
        {
          $ref: "#/components/parameters/plugin_collection",
        },
        {
          $ref: "#/components/parameters/plugin_item",
        },
      ],
      post: {
        tags: ["plugin", "plugin items"],
        summary: "reload plugin item setup",
        operationId: "loadPluginItem",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/plugin",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/collections": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      get: {
        tags: ["collection", "game"],
        summary: "get schemas of all current data collections",
        description:
          "Schemas are an estimation from the merge of exiting documents and data collection schema definitions by plugins\n",
        operationId: "getCollections",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  additionalProperties: {
                    type: "object",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/collection": {
      post: {
        tags: ["collection"],
        summary: "create a new data collection",
        operationId: "createCollection",
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
        ],
        requestBody: {
          description: "collection name",
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/collection",
              },
            },
          },
        },
        responses: {
          201: {
            description: "create successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    created_id: {
                      type: "string",
                    },
                    db_collection_created: {
                      type: "boolean",
                      description:
                        "true if there wasn't already a collection with that name in the database",
                    },
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
    },
    "/game/{game}/collection/{collection}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/collection",
        },
      ],
      post: {
        tags: ["collection"],
        summary: "create a new document in a collection",
        operationId: "createDocument",
        requestBody: {
          description: "document to be added to collection",
          content: {
            "application/json": {
              schema: {
                type: "object",
              },
            },
          },
        },
        responses: {
          201: {
            $ref: "#/components/responses/201Created",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
        },
      },
      get: {
        tags: ["collection"],
        summary: "find documents by using query parameters",
        description:
          "omit parameters to get all the documents or use _sort_by parameter to get a sorted list of documents that match the query",
        operationId: "findDocuments",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "list of documents",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    type: "object",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["collection"],
        summary: "delete data collection",
        description: "This deletes all the collections documents!",
        operationId: "deleteCollection",
        responses: {
          204: {
            description: "collection delete successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/collection/{collection}/{document}": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
        {
          $ref: "#/components/parameters/collection",
        },
        {
          $ref: "#/components/parameters/document",
        },
      ],
      get: {
        tags: ["collection"],
        summary: "get document data",
        operationId: "getDocument",
        responses: {
          200: {
            description: "successful",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      put: {
        tags: ["collection"],
        summary: "update collection document",
        operationId: "updateDocument",
        requestBody: {
          description: "modified collection document data",
          content: {
            "application/json": {
              schema: {
                type: "object",
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
      delete: {
        tags: ["collection"],
        summary: "delete collection document",
        operationId: "deleteDocument",
        responses: {
          204: {
            description: "document delete successful",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/collection/{collection}/{document}/{operator}": {
      post: {
        tags: ["collection"],
        summary: "edit document using update operator",
        operationId: "editDocument",
        parameters: [
          {
            $ref: "#/components/parameters/game",
          },
          {
            $ref: "#/components/parameters/collection",
          },
          {
            $ref: "#/components/parameters/document",
          },
          {
            $ref: "#/components/parameters/operator",
          },
        ],
        requestBody: {
          description: "update values",
          content: {
            "application/json": {
              schema: {
                type: "object",
                example: {
                  someProperty: "newValue",
                  "some.nested.property": "changedValue",
                },
              },
            },
          },
        },
        responses: {
          200: {
            $ref: "#/components/responses/200Update",
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
    "/game/{game}/items": {
      parameters: [
        {
          $ref: "#/components/parameters/game",
        },
      ],
      get: {
        tags: ["items"],
        summary:
          "get selected documents from plugin and data collections alike",
        description:
          "Use query parameter to get specific documents. 'collection' and 'type' can be used to get (specific) plugin or data collections only. Use optional _sort_by parameter to get a sorted list of documents that match the query. Omit query parameter to get all the documents\n",
        operationId: "findItems",
        parameters: [
          {
            name: "name",
            in: "query",
            schema: {
              type: "string",
            },
          },
          {
            $ref: "#/components/parameters/sort_by",
          },
          {
            $ref: "#/components/parameters/sort_direction",
          },
          {
            $ref: "#/components/parameters/limit",
          },
          {
            $ref: "#/components/parameters/skip",
          },
          {
            $ref: "#/components/parameters/params",
          },
        ],
        responses: {
          200: {
            description: "list of documents",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/item",
                  },
                },
              },
            },
          },
          400: {
            $ref: "#/components/responses/400Invalid",
          },
          404: {
            $ref: "#/components/responses/404NotFound",
          },
        },
      },
    },
  },
  components: {
    parameters: {
      user: {
        name: "user",
        in: "path",
        description: "user _id",
        required: true,
        schema: {
          type: "string",
        },
      },
      game: {
        name: "game",
        in: "path",
        description: "name of game",
        required: true,
        schema: {
          type: "string",
        },
      },
      level: {
        name: "level",
        in: "path",
        description: "level _id or name property",
        required: true,
        schema: {
          type: "string",
        },
      },
      state: {
        name: "state",
        in: "path",
        description: "state id",
        required: true,
        schema: {
          type: "string",
        },
      },
      session: {
        name: "session",
        in: "path",
        description: "session _id or name property",
        required: true,
        schema: {
          type: "string",
        },
      },
      plugin: {
        name: "plugin",
        in: "path",
        description: "plugin name",
        required: true,
        schema: {
          type: "string",
        },
      },
      plugin_collection: {
        name: "plugin_collection",
        in: "path",
        description: "name of plugin collection",
        required: true,
        schema: {
          type: "string",
        },
      },
      plugin_item: {
        name: "item",
        in: "path",
        description: "name or _id of plugin item",
        required: true,
        schema: {
          type: "string",
        },
      },
      collection: {
        name: "collection",
        in: "path",
        description: "name of collection",
        required: true,
        schema: {
          type: "string",
        },
      },
      document: {
        name: "document",
        in: "path",
        description: "document _id or name property",
        required: true,
        schema: {
          type: "string",
        },
      },
      params: {
        name: "params",
        in: "query",
        schema: {
          type: "object",
          default: {},
          additionalProperties: {
            type: "string",
          },
        },
      },
      operator: {
        name: "operator",
        in: "path",
        description:
          "make a specific update operation. <br><br>  **set** update the specified fields only<br>  **remove** remove the specified fields<br>  **push** add an element to an array<br>  **add** add an elemnt to an array if it does not exist<br> <br> You can use any [NeDB operator](https://www.npmjs.com/package/nedb#updating-documents) or if you use mongodb any [mongo update operator](https://docs.mongodb.com/manual/reference/operator/update/). Just omit the $ character.\n",
        required: true,
        schema: {
          type: "string",
          enum: [
            "_set",
            "_remove",
            "_inc",
            "_min",
            "_max",
            "_mul",
            "_rename",
            "_push",
            "_add",
            "_pop",
            "_pull",
            "_pullAll",
          ],
        },
      },
      sort_by: {
        name: "_sort_by",
        in: "query",
        schema: {
          type: "string",
        },
      },
      sort_direction: {
        name: "_sort_direction",
        description: "sort order. >= 0 means A to Z, < 0 means Z to A",
        in: "query",
        schema: {
          type: "integer",
          enum: [-1, 1],
        },
      },
      limit: {
        name: "_limit",
        description:
          "Maximum number of entries to return. 0 returns all entries.",
        in: "query",
        schema: {
          type: "integer",
          minimum: 0,
        },
      },
      skip: {
        name: "_skip",
        description: "Do not return the first n entries",
        in: "query",
        schema: {
          type: "integer",
          minimum: 0,
        },
      },
    },
    schemas: {
      game_preview: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          setup: {
            $ref: "#/components/schemas/game_setup",
          },
        },
      },
      game_setup: {
        type: "object",
        properties: {
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
            description: "user id",
          },
        },
      },
      level_preview: {
        type: "object",
        properties: {
          _id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
          modified_at: {
            type: "string",
          },
          modified_by: {
            type: "string",
          },
          config: {
            type: "object",
            properties: {
              arguments: {
                $ref: "#/components/schemas/arguments",
              },
            },
          },
        },
      },
      action_preview: {
        type: "object",
        properties: {
          action: {
            type: "string",
            description: "action name as defined by plugin",
          },
          title: {
            type: "string",
            description: "friendly action name",
          },
          description: {
            type: "string",
            description: "brief action description",
          },
          plugin: {
            type: "string",
            description: "the plugin that provides this action",
          },
          mode: {
            $ref: "#/components/schemas/action_mode",
          },
        },
      },
      session_preview: {
        type: "object",
        properties: {
          _id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          level: {
            type: "string",
            description: "level _id",
          },
          level_name: {
            type: "string",
            description: "level name",
          },
          game: {
            type: "string",
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
        },
      },
      plugin_preview: {
        type: "object",
        required: ["name"],
        properties: {
          name: {
            type: "string",
          },
          settings: {
            type: "object",
          },
          core: {
            type: "boolean",
            description:
              "If true, plugin is included with every game by default.",
          },
          description: {
            type: "string",
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
          modified_at: {
            type: "string",
          },
          modified_by: {
            type: "string",
          },
        },
      },
      user: {
        type: "object",
        properties: {
          _id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          login: {
            type: "string",
          },
        },
      },
      user_session: {
        type: "object",
        properties: {
          socket: {
            type: "string",
            description: "user socket session id",
          },
          user: {
            type: "string",
            description: "user login property",
          },
        },
      },
      game: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          setup: {
            $ref: "#/components/schemas/game_setup",
          },
          level: {
            type: "array",
            items: {
              $ref: "#/components/schemas/level_preview",
            },
          },
          actions: {
            type: "array",
            items: {
              $ref: "#/components/schemas/action_preview",
            },
          },
          sessions: {
            type: "array",
            items: {
              $ref: "#/components/schemas/session_preview",
            },
          },
          plugins: {
            type: "array",
            items: {
              $ref: "#/components/schemas/plugin_preview",
            },
          },
          collections: {
            type: "array",
            items: {
              description: "collection name",
              type: "string",
            },
          },
        },
      },
      plugin: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          core: {
            type: "boolean",
            description:
              "if true, plugin is part of basics, included in each game and can not be removed",
          },
          settings: {
            type: "object",
            properties: {
              data: {
                type: "object",
              },
              schema: {
                $ref: "#/components/schemas/meta_schema",
              },
            },
          },
          items: {
            type: "object",
            additionalProperties: {
              type: "object",
              properties: {
                items: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/plugin_item",
                  },
                },
                schema: {
                  $ref: "#/components/schemas/meta_schema",
                },
              },
            },
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
          modified_at: {
            type: "string",
          },
          modified_by: {
            type: "string",
          },
        },
      },
      plugin_item: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          settings: {
            type: "object",
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
          modified_at: {
            type: "string",
          },
          modified_by: {
            type: "string",
          },
        },
      },
      item: {
        type: "object",
        description:
          "Data or Plugin item for use in dashboard view (async api)",
        properties: {
          name: {
            type: "string",
          },
          type: {
            type: "string",
            enum: ["plugin", "data"],
          },
          collection: {
            type: "string",
            description: "Plugin or Data collection name",
          },
          dashboard: {
            type: "object",
            properties: {
              favorite: {
                type: "boolean",
                description: "show or hide item in favorites dashboard",
              },
              hidden_properties: {
                type: "array",
                description:
                  "item properties that are not shown or not highlighted in items dashboard. Nested properties addressed by dot notation e.g. 'dont.show.thisproperty'",
                items: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      session: {
        type: "object",
        properties: {
          _id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          level: {
            type: "string",
            description:
              "name of level the session is based on (should be level _id actually)",
          },
          game: {
            type: "string",
            description: "name of game the session is located in",
          },
          created_at: {
            type: "string",
          },
          created_by: {
            type: "string",
          },
          references: {
            type: "object",
            description: "snapshot of currently referenced documents",
            additionalProperties: {
              type: "object",
            },
          },
          current_states: {
            type: "array",
            description: "list of latest dispatched states in each path.",
            items: {
              $ref: "#/components/schemas/session/properties/history/items",
            },
          },
          active_listener: {
            type: "object",
            description: "states with currently waiting listeners",
            additionalProperties: {
              type: "array",
              items: {
                $ref: "#/components/schemas/listener",
              },
            },
          },
          history: {
            type: "array",
            description:
              "list off all states that where dispatched since. Contain data that was generated with them",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string",
                },
                name: {
                  type: "string",
                },
                path: {
                  type: "string",
                },
                date: {
                  type: "string",
                },
              },
            },
          },
          states: {
            type: "object",
            description:
              "storeage of latest occurance of specific state (by name)",
            additionalProperties: {
              type: "object",
            },
          },
        },
      },
      arguments: {
        description:
          "level parameter arguments are always of type object. A session though maybe launched with a list of arguments that will be mapped to the level arguments.",
        type: "object",
        additionalProperties: {
          type: "object",
          properties: {
            collection: {
              type: "string",
            },
            query: {
              type: "string",
            },
          },
        },
      },
      collection: {
        type: "object",
        required: ["name"],
        additionalProperties: false,
        properties: {
          name: {
            description: "name of collection to be created",
            type: "string",
            not: {
              type: "string",
              enum: ["level", "sessions", "plugins", "setup"],
            },
          },
        },
      },
      level: {
        type: "object",
        default: {
          name: "new_level",
          config: {
            arguments: {
              Player: {
                collection: "player",
              },
            },
          },
          states: {
            _state_0: {
              name: "START",
              view: {
                position: {
                  x: 40,
                  y: 40,
                },
              },
              comments: [
                "When a new session is created, the START state starts.",
              ],
              run: ["_action_0"],
              listen: [],
            },
            _state_1: {
              name: "QUIT",
              view: {
                position: {
                  x: 800,
                  y: 500,
                },
              },
              comments: [],
              run: ["_action_1"],
              listen: [],
            },
          },
          actions: {
            _action_0: {
              action: "next",
              plugin: "control",
              payload: {
                next: "",
              },
              mode: "run",
            },
            _action_1: {
              action: "quit",
              plugin: "control",
              payload: null,
              mode: "run",
            },
          },
          contents: {},
        },
        example: {
          _id: "2ZN32ayOujErm2Hl",
          name: "my_level",
          config: {
            arguments: {},
          },
          states: {
            stateID1: {
              name: "START",
              path: ["main"],
              view: {
                position: {
                  x: 0,
                  y: 0,
                },
              },
              comments: ["Here the journey begins"],
              run: ["ActionID1"],
              listen: ["ActionID2"],
            },
          },
          stateID2: {
            name: "QUIT",
            path: ["main"],
            view: {
              position: {
                x: 100,
                y: 0,
              },
            },
            comments: ["Here it ends"],
            run: [],
            listen: [],
          },
          actions: {
            ActionID1: {
              name: "action-1",
              action: "send_message",
              plugin: "telegram",
              mode: "run",
              payload: {},
            },
            ActionID2: {
              name: "action-2",
              action: "timeout",
              plugin: "time",
              mode: "listen",
              payload: {},
            },
          },
          contents: {
            ContentID1: {
              de: "sein oder nicht sein",
              en: "to be or not to be",
            },
          },
        },
        properties: {
          _id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          config: {
            type: "object",
            properties: {
              arguments: {
                $ref: "#/components/schemas/arguments",
              },
            },
          },
          states: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/state",
            },
          },
          actions: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/action",
            },
          },
          contents: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/content",
            },
          },
        },
      },
      states: {
        type: "object",
        description: "state elements of a selected set of states",
        properties: {
          states: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/state",
            },
          },
          actions: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/action",
            },
          },
          contents: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/content",
            },
          },
        },
      },
      state: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          path: {
            type: "array",
            items: {
              type: "string",
            },
          },
          view: {
            type: "object",
            description: "state appearance in level editor",
            properties: {
              position: {
                type: "object",
                properties: {
                  x: {
                    type: "number",
                  },
                  y: {
                    type: "number",
                  },
                },
              },
            },
          },
          comments: {
            type: "array",
            items: {
              type: "string",
            },
          },
          run: {
            type: "array",
            items: {
              type: "string",
              description: "list of executable action ids",
            },
          },
          listen: {
            type: "array",
            items: {
              type: "string",
              description: "list of listener action ids",
            },
          },
        },
      },
      action: {
        type: "object",
        required: ["action", "plugin", "payload"],
        properties: {
          name: {
            type: "string",
            description: "name of action instance",
          },
          action: {
            type: "string",
            description: "action name as defined by plugin",
          },
          plugin: {
            type: "string",
            description: "the plugin that provides this action",
          },
          mode: {
            $ref: "#/components/schemas/action_mode",
          },
          payload: {
            description: "action as described by plugin",
          },
        },
      },
      content: {
        type: "object",
        description:
          "each content element is refered to by an action. content element properties depend on what content types there are (e.g. different languages)",
        additionalProperties: true,
      },
      listener: {
        type: "object",
        properties: {
          date: {
            type: "string",
          },
          state: {
            type: "object",
            properties: {
              id: {
                type: "string",
              },
              name: {
                type: "string",
              },
            },
          },
          session: {
            type: "string",
          },
          action: {
            type: "string",
          },
          plugin: {
            type: "string",
          },
          mode: {
            type: "string",
          },
          name: {
            type: "string",
          },
          payload: {
            type: "object",
          },
          _id: {
            type: "string",
          },
        },
      },
      action_schema: {
        type: "object",
        description: "payload is action schema as described by plugin",
        properties: {
          action: {
            type: "string",
            description: "action name as defined by plugin",
          },
          title: {
            type: "string",
            description: "friendly name",
          },
          mode: {
            $ref: "#/components/schemas/action_mode",
          },
          icon: {
            type: "string",
            description: "action icon filename or svg",
          },
          tooltip: {
            type: "string",
            description: "brief description of action",
          },
          plugin: {
            type: "string",
            description: "the plugin that provides this action",
          },
          schema: {
            $ref: "#/components/schemas/meta_schema",
          },
        },
      },
      action_mode: {
        type: "string",
        enum: ["run", "listen"],
        default: "run",
        description:
          "action will finish during state init (run) or persist waiting for input (listen)",
      },
      meta_schema: {
        description:
          'this should be a reference to a meta schema somewhere else ($ref: "url://to.some.meta/schema/in/the/internetz.json")\n',
        type: "object",
      },
      path_update: {
        type: "object",
        description:
          "Updated paths for all states. Contains state elements cut down to the path property.",
        properties: {
          states: {
            type: "object",
            additionalProperties: {
              type: "object",
              properties: {
                path: {
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
        example: {
          states: {
            state_0: {
              path: ["start"],
            },
            state_1: {
              path: ["start"],
            },
            state_2: {
              path: ["start", "sidequest"],
            },
            state_3: {
              path: ["start", "timeout"],
            },
            state_4: {
              path: ["start", "sidequest", "timeout"],
            },
            state_5: {
              path: ["start", "easteregg"],
            },
          },
        },
      },
    },
    securitySchemes: {
      basicAuth: {
        type: "http",
        scheme: "basic",
      },
    },
    responses: {
      "200Update": {
        description: "update successful",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                changed: {
                  type: "boolean",
                  description:
                    "Indicate if ressource has changed. If ressource has not changed, modified properties are omitted. If adaptor uses mongodb, PUT requests for level and document will always respond with changed: true and append modified information\n",
                },
                modified_at: {
                  type: "string",
                },
                modified_by: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      "200StateUpdate": {
        description:
          "state update, create or delete  successful. Response includes updates on paths for all states that might have changed. Modification information refers to respective level document.  That is why even on a state create or delete operation, response will always contain 'modified' and not 'created' properties.\n",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                changed: {
                  type: "boolean",
                  description:
                    "Indicate if ressource has changed. If ressource has not changed, modified properties are omitted. If adaptor uses mongodb, PUT requests for level and document will always respond with changed: true and append modified information\n",
                },
                modified_at: {
                  type: "string",
                },
                modified_by: {
                  type: "string",
                },
                path_update: {
                  $ref: "#/components/schemas/path_update",
                },
              },
            },
          },
        },
      },
      "200Executed": {
        description: "operation executed",
        content: {
          "application/json": {
            schema: {
              type: "object",
            },
          },
        },
      },
      "200Connect": {
        description: "connection function executed",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                connected: {
                  type: "boolean",
                  description:
                    "optional indicator that connection was established",
                },
              },
            },
          },
        },
      },
      "201Created": {
        description: "create successful",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                created_id: {
                  type: "string",
                },
                created_at: {
                  type: "string",
                },
                created_by: {
                  type: "string",
                },
                created_path: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      "201StateCreated": {
        $ref: "#/components/responses/200StateUpdate",
      },
      "400Invalid": {
        description: "invalid request. See error object for details.",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  enum: [
                    "InvalidError",
                    "ValidationError",
                    "DuplicateError",
                    "SyntaxError",
                  ],
                },
                message: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      "404NotFound": {
        description: "Resource was not found. See error object for details.",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  enum: ["NotFoundError"],
                },
                message: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      "500ServerError": {
        description: "An internal Server Error occured. Consider bug report.",
        content: {
          "application/json": {
            schema: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                },
                message: {
                  type: "string",
                },
                stack: {
                  type: "string",
                },
              },
            },
          },
        },
      },
      UnauthorizedError: {
        description: "Authentication information is missing or invalid",
        headers: {
          WWW_Authenticate: {
            schema: {
              type: "string",
            },
          },
        },
      },
    },
  },
  security: [
    {
      basicAuth: [],
    },
  ],
};
