import { Manager } from "socket.io-client";

export class SocketApi {
  constructor(API_URL) {
    this.manager = new Manager(API_URL, {
      reconnectionDelayMax: Infinity, // exponential backoff
      autoConnect: false, // to not have double connect on first connection
    });
    this.globalSocketOptions = {
      auth: {
        /*token: Buffer.from("Development" + ":" + "dev_password").toString(
          "base64"
        ),*/
        token: localStorage.authtoken,
      },
    };
    this.liveSessions = null;
    this.game = null;
    this.level = null;
    this.log = null;
    this.liveItems = null;
  }

  addEventListeners(socket, listeners) {
    Object.entries(listeners).forEach(([event, callback]) =>
      socket.on(event, (data) => callback(data))
    );
  }

  removeEventListeners(socket) {
    if (socket) {
      socket.removeAllListeners();
    }
  }

  logConnect(level, listeners) {
    this.log = this.manager.socket(`/log/${level}`, this.globalSocketOptions);
    listeners = Object.assign(
      {
        connect: () => {
          // console.log("log:: connected", this.log);
        },
        reconnect: () => {
          // console.log("log:: reconnected");
        },
        connect_error: () => {
          // console.log("log:: connect_error");
        },
        disconnect: () => {
          // console.log("log:: disconnected");
        },
      },
      listeners
    );
    this.addEventListeners(this.log, listeners);
    if (this.log.disconnected && !this.log.connected) {
      this.log.connect({
        withCredentials: true,
      });
    }
  }

  logDisconnect() {
    if (this.log) {
      this.log.removeAllListeners();
      this.log.disconnect();
    }
    return;
  }

  gameConnect(game, listeners) {
    this.gameDisconnect;
    this.game = this.manager.socket(`/game/${game}`, this.globalSocketOptions);
    listeners = Object.assign(
      {
        connect: () => {
          //console.log("SocketApi: connected", game);
        },
        reconnect: () => {
          //console.log("SocketApi: reconnected", game);
        },
        connect_error: () => {
          //console.log("SocketApi: connect_error")
        },
        disconnect: () => {
          //console.log("SocketApi: disconnected", game);
        },
      },
      listeners
    );
    this.addEventListeners(this.game, listeners);
    if (this.game.disconnected && !this.game.connected) {
      this.game.connect({
        withCredentials: true,
      });
    }
  }

  gameDisconnect() {
    if (this.game) {
      this.game.removeAllListeners();
      this.game.disconnect();
    } else {
      //console.log("already disconnected");
    }
    return;
  }

  levelConnect(game, level_id, listeners) {
    this.levelDisconnect;
    const ns = `/game/${game}/level/${level_id}`;
    // console.log("connecting level", game, level_id, "with namespace:", ns);
    this.level = this.manager.socket(ns, this.globalSocketOptions);
    listeners = Object.assign(
      {
        connect: () => {
          //console.log("SocketApi: connected", level);
        },
        reconnect: () => {
          //console.log("SocketApi: reconnected", level);
        },
        connect_error: () => {
          //console.log("SocketApi: connect_error", ns);
        },
        disconnect: () => {
          //console.log("SocketApi: disconnected", level);
        },
      },
      listeners
    );
    this.addEventListeners(this.level, listeners);
    if (this.level.disconnected && !this.level.connected) {
      this.level.connect();
    }
  }

  levelDisconnect() {
    if (this.level) {
      this.level.removeAllListeners();
      this.level.disconnect();
    } else {
      //console.log("already disconnected");
    }
    return;
  }

  liveSessionsConnect(game, listeners) {
    //console.log("disconnect successful. will now connect");
    this.liveSessionsDisconnect();
    const ns = `/game/${game}/live/sessions`;
    this.liveSessions = this.manager.socket(ns, this.globalSocketOptions);
    // console.log("connecting to live infos", game, "with namespace:", ns);

    listeners = Object.assign(
      {
        connect: () => {
          //console.log("SocketApi: connected", game, session);
        },
        reconnect: () => {
          //console.log("SocketApi: reconnected", game, session);
        },
        connect_error: () => {
          //console.log("SocketApi: connect_error")
        },
        disconnect: () => {
          //console.log("SocketApi: disconnected", game, session);
        },
      },
      listeners
    );
    this.addEventListeners(this.liveSessions, listeners);
    if (this.liveSessions.disconnected && !this.liveSessions.connected) {
      //console.log("not connected yet", this.liveSessions);
      this.liveSessions.connect();
    }
    //console.log("connecting socket:", this.liveSessions);
  }

  liveSessionsDisconnect() {
    //console.log("SocketApi: trying to disconnect from session", this.liveSessions);
    if (this.liveSessions) {
      this.liveSessions.removeAllListeners();
      this.liveSessions.disconnect();
      //console.log("disconnected");
    } else {
      //console.log("already disconnected");
    }
    return;
  }

  liveItemsConnect(game, listeners) {
    this.levelDisconnect;
    const ns = `/game/${game}/live/items`;
    // console.log("connecting to live infos", game, "with namespace:", ns);
    this.liveItems = this.manager.socket(ns, this.globalSocketOptions);
    listeners = Object.assign(
      {
        connect: () => {
          //console.log("SocketApi: connected", level);
        },
        reconnect: () => {
          //console.log("SocketApi: reconnected", level);
        },
        connect_error: () => {
          //console.log("SocketApi: connect_error", ns);
        },
        disconnect: () => {
          //console.log("SocketApi: disconnected", level);
        },
      },
      listeners
    );
    this.addEventListeners(this.liveItems, listeners);
    if (this.liveItems.disconnected && !this.liveItems.connected) {
      this.liveItems.connect();
    }
  }

  liveItemsDisconnect() {
    if (this.liveItems) {
      this.liveItems.removeAllListeners();
      this.liveItems.disconnect();
    } else {
      //  console.log("already disconnected");
    }
    return;
  }
}
