<template>
  <span
    class="icon"
    :class="{ 'is-cursor-pointer': interactive }"
    :title="tooltip"
    v-html="iconSvg"
  >
  </span>
</template>

<script>
import { computed } from "@vue/runtime-core";
import feather from "feather-icons";

export default {
  name: "Icon",
  props: {
    tooltip: { type: String, required: false, default: "" },
    interactive: { type: Boolean, required: false, default: true },
    icon: { type: String, required: true, default: "feather" },
    fill: { type: String, required: false },
    // Note: we could add any SVG Attributes here
  },
  emits: ["onClick"],
  setup(props) {
    const iconSvg = computed(() => {
      if (feather.icons[props.icon]) {
        return feather.icons[props.icon].toSvg({
          ...(props.fill && { fill: props.fill }),
        });
      }
      // TODO: remove hardcoded svgs and dynamiclly load from extra assets file
      else if (props.icon == "unplug") {
        return `<svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1307_10359)"><path d="M17.8042 17.8042L18.8875 16.7208C20.4416 15.1667 21.3241 13.065 21.3453 10.8672C21.3665 8.66946 20.5249 6.55107 19.0011 4.96722C15.7374 1.5775 10.2114 1.65771 6.88404 4.98504L5.93471 5.93438" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.9004 18.7779L7.65922 22.0191" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.66162 12.5395L1.42077 15.7804" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.8874 4.85135L22.5142 1.22463" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 23L1 0.999998" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_1307_10359"><rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)"/></clipPath></defs></svg>`;
      } else if (props.icon == "plug") {
        return '<svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.62697 7.2983C3.07274 8.85234 2.19015 10.9541 2.16885 13.1518C2.14756 15.3496 2.98927 17.468 4.5131 19.0519C7.77672 22.4416 13.3028 22.3614 16.6301 19.0341L19.1619 16.5023C19.2435 16.4207 19.3083 16.3238 19.3525 16.2172C19.3966 16.1105 19.4193 15.9963 19.4193 15.8809C19.4193 15.7654 19.3966 15.6512 19.3525 15.5445C19.3083 15.4379 19.2435 15.3411 19.1619 15.2595L8.53525 4.63247C8.37041 4.46773 8.14692 4.37518 7.91387 4.37518C7.68082 4.37518 7.45729 4.46773 7.29245 4.63247L4.62697 7.2983Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.3433 6.51137L15.8546 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5815 12.7498L22.0929 8.23874" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.62672 19.1678L1 22.7945" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
      }
    });
    // console.log(iconSvg);
    return { iconSvg };
  },
};
</script>
