<template>
  <div class="toolbar p-0 has-background-white is-full-height is-unselectable">
    <div class="tabs is-boxed has-text-weight-semibold">
      <ul>
        <li class="tab-link is-active" @click="openTab($event, 'tab_action')">
          <a>ACTIONS</a>
        </li>
        <li class="tab-link" @click="openTab($event, 'tab_assets')">
          <a>VARIABLES</a>
        </li>
        <li class="tab-link" @click="openTab($event, 'tab_media')">
          <a>MEDIA</a>
        </li>
      </ul>
    </div>
    <div class="tab-container pl-5 pr-5 has-text-black">
      <!--  -->
      <!-- TAB ACTIONS CONTAINER -->
      <!--  -->
      <div id="tab_action" class="tab-content">
        <div v-if="sortedActions">
          <div
            v-for="(plugin, key, index) in sortedActions"
            :key="`plugin-${key}`"
          >
            <!-- PLUGIN HEADING -->
            <h5 :class="['mb-1', index != 0 ? 'mt-5' : '']">
              {{ key.toUpperCase() }}
            </h5>
            <!-- PLUGIN ACTION ITEM -->
            <div
              v-for="action in plugin"
              :key="`action-${action.title}`"
              :title="`${action.tooltip || action.action}`"
              class="bar-item"
              :class="[
                $store.getters.stylesByElement(action.plugin, 'bgColorAction'),
                ...toolbarItemStyle,
              ]"
              draggable="true"
              @dragstart="setDragPayload($event, action)"
              @dblclick="log($event, action)"
              @mouseover="hoverOver = action.title"
              @mouseleave="hoverOver = ''"
            >
              <img
                :src="action.mode == 'listen' ? ListenSVG : RunSVG"
                class="is-absolute-vcenter"
                alt="Action Type Icon"
                :title="`This Action is of Mode ${action.mode}`"
              />
              <span
                class="is-flex-grow-1 pl-1 has-text-black has-text-weight-semibold"
              >
                {{ action.title }}
              </span>
              <a
                v-if="
                  hoverOver == action.title &&
                  action.documentation &&
                  action.documentation != ''
                "
                class="is-flex pr-1"
                :href="action.documentation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  class="has-text-grey"
                  :icon="'help-circle'"
                  :tooltip="`${action.tooltip}\nClick ? for help.`"
                  :interactive="false"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- TAB VARIABLES CONTAINER -->
      <!--  -->
      <div id="tab_assets" class="tab-content" style="display: none">
        <!-- VARIABLES LEVEL VARIABLES HEADING -->
        <h2 class="mb-1">LEVEL ATTRIBUTES</h2>
        <!-- VARIABLES LEVEL VARIABLES ITEM -->
        <div
          v-for="(item, index) in level_attributes"
          :key="index"
          :class="[
            [...toolbarItemStyle],
            item.depth == 0 ? '' : '',
            item.depth == 1 ? 'mt-4' : '',
            'ml-' + (item.depth - 1) * 2,
          ]"
          draggable="true"
          @dragstart="
            setDragPayloadAsset($event, {
              asset: item.value,
            })
          "
          :title="item.value"
        >
          {{ item.title }}
        </div>
        <!--  -->
        <!--  -->
        <!-- VARIABLES LEVEL VARIABLES HEADING -->
        <h2 class="mb-1">LEVEL ARGUMENTS</h2>
        <!-- VARIABLES LEVEL VARIABLES ITEM -->
        <div
          v-for="(item, index) in level_arguments"
          :key="index"
          :class="[
            [...toolbarItemStyle],
            item.depth == 0 ? '' : '',
            item.depth == 1 ? 'mt-4' : '',
            'ml-' + (item.depth - 1) * 2,
          ]"
          draggable="true"
          @dragstart="
            setDragPayloadAsset($event, {
              asset: item.value,
            })
          "
          :title="item.value"
        >
          {{ item.title }}
        </div>
        <!--  -->
        <!-- VARIABLES PLUGIN ITEMS HEADING -->
        <h2 class="mb-1 mt-5">PLUGIN ITEMS</h2>
        <!-- VARIABLES PLUGIN ITEMS ITEM -->
        <div
          v-for="(item, index) in plugin_items"
          :key="index"
          :class="[
            [...toolbarItemStyle],
            item.depth < 0 ? 'is-hidden' : '',
            item.depth == 0 ? 'mt-4' : '',
            'ml-' + item.depth * 2,
          ]"
          draggable="true"
          @dragstart="
            setDragPayloadAsset($event, {
              asset: item.value,
            })
          "
          :title="item.value"
        >
          {{ item.title }}
        </div>
        <!--  -->
        <!-- VARIABLES ACTION DATA HEADING -->
        <h2 class="mb-1 mt-5">ACTION DATA</h2>
        <!-- VARIABLES ACTION DATA ITEM -->
        <div
          v-for="(item, index) in action_data"
          :key="index"
          :class="[
            [...toolbarItemStyle],
            item.depth == 0 ? 'is-hidden' : '',
            item.depth == 1 ? 'mt-4' : '',
            'ml-' + (item.depth - 1) * 2,
          ]"
          draggable="true"
          @dragstart="
            setDragPayloadAsset($event, {
              asset: item.value,
            })
          "
          :title="item.value"
        >
          {{ item.title }}
        </div>
        <!--    -->
        <!-- VARIABLES FUNCTIONS HEADING -->
        <h2 class="mb-1 mt-5">FUNCTIONS</h2>
        <!-- VARIABLES FUNCTIONS ITEM -->
        <div
          v-for="(item, index) in functions"
          :key="index"
          :class="[[...toolbarItemStyle], item.depth == 0 ? 'is-hidden' : '']"
          draggable="true"
          @dragstart="
            setDragPayloadAsset($event, {
              asset: item.value,
            })
          "
          :title="item.value"
        >
          {{
            item.value
              .replace("[[", "")
              .replace("]]", "")
              .replace("function.", "")
          }}
        </div>
      </div>
      <!--  -->
      <!-- TAB MEDIA CONTAINER -->
      <!--  -->
      <div id="tab_media" class="tab-content" style="display: none">
        <div v-if="assets_media">
          <h2 class="mb-1">MEDIA ITEMS / FILES</h2>
          <div
            v-for="(item, index) in assets_media"
            :key="index"
            :class="[...toolbarItemStyle]"
            draggable="true"
            @dragstart="
              setDragPayloadAsset($event, {
                asset: item,
              })
            "
            :title="item"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import RunSVG from "@/assets/run.svg";
import ListenSVG from "@/assets/listen.svg";

export default {
  name: "Toolbar",
  setup() {
    const store = useStore();
    const sortedActions = computed(() => store.getters.actionsSortedByPlugin());
    const level = computed(() => store.state.data.level);
    const assets = computed(() => store.state.data.level_variables);
    const media = computed(() => store.state.data.media);

    function parseAssetItems(input_items) {
      let collection = input_items.map((item) => {
        let newitem = {};
        let arr = item.split(".");
        let depth = arr.length - 1;
        let title = arr[depth].replace("]]", "").replace("[[", "");
        newitem["value"] = item;
        newitem["title"] = title;
        newitem["depth"] = depth;
        return newitem;
      });
      return collection;
    }

    const level_arguments = computed(() =>
      assets.value?.level_arguments
        ? parseAssetItems(assets.value.level_arguments)
        : null
    );

    const level_attributes = computed(() =>
      assets.value?.level_attributes
        ? parseAssetItems(assets.value.level_attributes)
        : null
    );

    const plugin_items = computed(() =>
      assets.value?.plugin_items
        ? parseAssetItems(assets.value.plugin_items)
        : null
    );
    const action_data = computed(() =>
      assets.value?.action_data
        ? parseAssetItems(assets.value.action_data)
        : null
    );

    // console.log(action_data, plugin_items, level_arguments);

    const functions = computed(() =>
      assets.value?.functions ? parseAssetItems(assets.value.functions) : null
    );

    const assets_media = computed(() => (media.value ? media.value : null));

    const setDragPayload = (e, action) => {
      e.dataTransfer.setData("action", action.action);
      e.dataTransfer.setData("mode", action.mode);
    };
    const setDragPayloadAsset = (e, { value, asset }) => {
      e.dataTransfer.setData("asset", asset);
      e.dataTransfer.setData("value", JSON.stringify(value));
    };

    const toolbarItemStyle = [
      "mb-2",
      "pt-1",
      "pb-1",
      "pl-3",
      "is-grabbable",
      "is-position-relative",
      "has-border",
      "is-clipped",
      "has-overflow-ellipsis",
    ];

    const hoverOver = ref("");

    const log = (e, content) => console.log(content);

    const openTab = (e, tabName) => {
      // hide everything
      const links = document.getElementsByClassName("tab-link");
      const tabs = document.getElementsByClassName("tab-content");
      Array.from(links).forEach((node) => node.classList.remove("is-active"));
      Array.from(tabs).forEach((node) => (node.style.display = "none"));
      // show selection
      document.getElementById(tabName).style.display = "block";
      e.currentTarget.classList.add("is-active");
    };

    return {
      ListenSVG,
      RunSVG,
      setDragPayload,
      setDragPayloadAsset,
      log,
      openTab,
      sortedActions,
      level,
      assets,
      level_arguments,
      level_attributes,
      plugin_items,
      action_data,
      functions,
      assets_media,
      toolbarItemStyle,
      hoverOver,
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  border-right: 1px solid black;
  width: 18rem;
}

.toolbar .tabs {
  overflow-x: hidden;
}

.tab-container {
  overflow-y: scroll;
  height: 90%;
}
</style>
