<template>
  <table
    class="table has-background-transparent is-hoverable is-bordered is-fullwidth"
  >
    <thead>
      <tr>
        <th>LEVEL</th>
        <th>SESSION</th>
        <th>ACTIVE STATE</th>
        <th>IN STATE</th>
        <th>REFERENCES</th>
        <th>CONTENT</th>
        <th>IN SESSION</th>
        <th class="has-no-outline"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="level in levels" :key="level._id">
        <tr v-if="!sessions || !sessions.hasOwnProperty(level._id)">
          <td>
            <button
              class="button p-0 is-ghost has-text-weight-semibold"
              @click="$emit('levelSelect', level.name)"
            >
              {{ level.name }}
            </button>
            <Popup class="is-pulled-right">
              <template v-slot:anchor>
                <button
                  :ref="`toggleAdd_${level.name}`"
                  class="button has-no-outline has-background-transparent"
                >
                  <Icon :icon="'plus-circle'" />
                </button>
              </template>
              <template v-slot:content>
                <div class="dropdown-item p-0">
                  <SessionLauncher
                    :level="level.name"
                    @close="$refs[`toggleAdd_${level.name}`][0].click()"
                  />
                </div>
              </template>
            </Popup>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr
          v-else
          v-for="(session, index) in sessions[level._id]"
          :key="session"
          style="border: none"
        >
          <th v-if="index == 0" :rowspan="sessions[level._id].length">
            <button
              class="button p-0 is-ghost has-text-weight-semibold"
              @click="$emit('levelSelect', level.name)"
            >
              {{ level.name }}
            </button>
            <Popup class="is-pulled-right">
              <template v-slot:anchor>
                <button
                  :ref="`toggleAdd_${level.name}`"
                  class="button has-no-outline has-background-transparent"
                >
                  <Icon :icon="'plus-circle'" />
                </button>
              </template>
              <template v-slot:content>
                <div class="dropdown-item p-0">
                  <SessionLauncher
                    :level="level.name"
                    @close="$refs[`toggleAdd_${level.name}`][0].click()"
                  />
                </div>
              </template>
            </Popup>
          </th>
          <td>
            <button
              @click="$emit('sessionSelect', level.name, session._id)"
              class="button is-ghost has-text-is-live has-text-weight-semibold"
            >
              {{ session.name }}
            </button>
          </td>
          <td>
            <div v-for="state in session.current_states" :key="state._id">
              <span>{{ state.name }}</span> <br />
            </div>
          </td>
          <td class="is-family-monospace">
            <div v-for="state in session.current_states" :key="state._id">
              <span>{{ getTimeDiff(state.date, timestamp) }}</span> <br />
            </div>
          </td>
          <td class="content is-small">{{ session.references }}</td>
          <td class="content is-small">{{ session.content }}</td>
          <td class="is-family-monospace">
            {{ getTimeDiff(session.created_at, timestamp) }}
          </td>
          <td class="pl-1 has-no-outline">
            <div class="is-flex">
              <Icon
                class="m-1 has-text-is-live"
                :icon="'external-link'"
                @click="$emit('sessionSelect', level.name, session._id)"
              />

              <Popup :unfoldRight="true">
                <template v-slot:anchor>
                  <div :ref="`toggleDelete_${session._id}`">
                    <Icon class="pt-2" :icon="'trash-2'" />
                  </div>
                </template>
                <template v-slot:content>
                  <div class="dropdown-item p-1">
                    <div class="field is-grouped">
                      <p class="control">
                        <button
                          class="button is-danger"
                          @click="$emit('sessionDelete', session._id)"
                        >
                          Delete
                        </button>
                      </p>
                      <p class="control">
                        <button
                          class="button"
                          @click="
                            $refs[`toggleDelete_${session._id}`][0].click()
                          "
                        >
                          Cancel
                        </button>
                      </p>
                    </div>
                  </div>
                </template>
              </Popup>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { ref, onMounted, onUnmounted } from "@vue/runtime-core";
import * as Helpers from "@/services/helpers";

import Popup from "@/components/Template/Popup.vue";
import SessionLauncher from "@/components/Live/SessionLauncher.vue";

export default {
  name: "SessionList",
  props: ["levels", "sessions"],
  emits: ["levelSelect", "sessionSelect", "sessionDelete"],
  components: { Popup, SessionLauncher },
  setup() {
    const togglePopup = ref(false);
    const timestamp = ref(new Date());

    const setClock = () => (timestamp.value = new Date());
    const getTimeDiff = (dPast, dNow) => Helpers.getTimeDiffString(dPast, dNow);
    let timer;

    onMounted(() => (timer = setInterval(setClock, 1000)));
    onUnmounted(() => clearInterval(timer));
    const log = (e) => console.log(e);

    return { log, togglePopup, timestamp, getTimeDiff };
  },
};
</script>
