<template>
  <div class="pl-2 is-full-height">
    <h1>MY GAMES</h1>
    <div
      class="is-flex is-flex-direction-column is-flex-wrap-wrap mt-2"
      style="width: 30%"
    >
      <div
        v-for="game in games"
        :key="game.name"
        class="button is-link is-light pt-5 pb-5 mt-1 is-flex is-flex-direction-row is-justify-content-space-between"
        @click="handleGameClicked($event, game.name)"
      >
        <div class="content">
          <p>
            <strong>{{ game.name }} </strong>&nbsp;
            <small>
              {{ new Date(game?.setup?.created_at).toDateString() }}</small
            >
          </p>
        </div>
        <Icon
          :icon="'trash-2'"
          class="m-1"
          @click.stop="handleDeleteClicked($event, game.name)"
        />
      </div>
    </div>

    <div>
      <button class="button is-dark mt-2" @click="newGameModal.visible = true">
        NEW GAME
      </button>
      <!-- <button class="button is-dark m-2">IMPORT GAME</button> -->
    </div>

    <Modal
      v-if="newGameModal.visible"
      :modal="newGameModal"
      @closeModal="newGameModal.visible = false"
    >
      <template v-slot:header>NEW GAME</template>
      <label for="name">What shall we call your new game?</label>
      <input
        class="input"
        type="text"
        name="name"
        placeholder="name"
        v-model="newGameModal.name"
      />
      <template v-slot:footer>
        <button class="button is-primary m-1" @click="handleNewGameClicked">
          NEW GAME
        </button>
        <button class="button m-1" @click="newGameModal.visible = false">
          CANCEL
        </button>
      </template>
    </Modal>

    <Modal
      v-if="deleteGameModal.visible"
      :modal="deleteGameModal"
      @closeModal="deleteGameModal.visible = false"
      :cssClasses="{
        message: true,
        'is-danger': true,
      }"
    >
      <template v-slot:header>DELETE GAME</template>
      Do you really want do delete this game?
      <template v-slot:footer>
        <button class="button is-danger m-1" @click="deleteGame">DELETE</button>
        <button class="button m-1" @click="deleteGameModal.visible = false">
          CANCEL
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Modal from "@/components/Template/Modal.vue";

export default {
  name: "AdaptorView",
  components: { Modal },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();

    // We just need to load all available games here
    store.dispatch("selectIsLive", false);
    store.dispatch("getGames");

    // Compute necessary Store values for Component
    const games = computed(() => store.state.data.game_list);

    // Create local References
    const deleteGameModal = ref({ visible: false, name: null });
    const newGameModal = ref({ visible: false, name: null });

    // Create local Callback Functions

    const handleGameClicked = (e, game) => {
      store.dispatch("selectGame", game);
      router.push({ path: "/game", query: { game: game } });
    };

    const handleNewGameClicked = () => {
      const name = newGameModal.value.name;
      store.dispatch("createGame", { name, template: "basic" }).then(
        () => (newGameModal.value.visible = false),
        (error) => console.error(error) // on name rejected
      );
    };

    const handleDeleteClicked = (e, name) => {
      console.log("deleting game", name);
      deleteGameModal.value.name = name;
      deleteGameModal.value.visible = true; // show modal
    };

    const deleteGame = () => {
      console.log("adaptor sends delete action", deleteGameModal.value.name);
      store
        .dispatch("deleteGame", { name: deleteGameModal.value.name })
        .then(() => {
          deleteGameModal.value.visible = false;
        });
    };

    return {
      games,
      newGameModal,
      deleteGameModal,
      handleGameClicked,
      handleNewGameClicked,
      handleDeleteClicked,
      deleteGame,
    };
  },
};
</script>
