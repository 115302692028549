<template>
  <div ref="container"></div>
</template>

<script>
import { onMounted, onUnmounted, ref, watch } from "@vue/runtime-core";
import { cloneDeep, isEqual } from "lodash";
import { JSONEditor } from "@json-editor/json-editor";
import { AdaptorTheme } from "@/styles/json-editor/adaptorTheme.js";

export default {
  name: "JsonEditor",
  props: ["data", "schema", "options"],
  emits: ["dataChanged", "dataValidated"],
  setup(props, { emit }) {
    const container = ref(null);
    let editor;
    const options = {
      schema: prepareSchema(props.schema),
      startval: props.data,
      disable_collapse: true,
      object_layout: "table",
      show_errors: "change",
      theme: "adaptor",
      disable_array_reorder: true,
    };

    JSONEditor.defaults.theme = "adaptor";
    JSONEditor.defaults.themes.adaptor = AdaptorTheme;

    watch(
      () => props.data,
      (data) => {
        if (!isEqual(data, editor.getValue())) editor.setValue(data);
      }
    );

    onMounted(() => {
      editor = new JSONEditor(container.value, options);
      editor.on("ready", () => {
        // TODO get rid of this hack to show second tab ;)
        editor.element.querySelector(".je-tabholder--top").children[1]?.click();
      });
      editor.on("change", () => {
        const errors = editor.validate();
        if (errors.length) emit("dataValidated", { valid: false, errors });
        else emit("dataValidated", { valid: true, errors });
        if (!isEqual(props.data, editor.getValue()))
          emit("dataChanged", editor.getValue());
      });
    });

    onUnmounted(() => editor.destroy());

    return { container };
  },
};

// Note: change schema to align with json-edior style
function prepareSchema(schema) {
  let transformed;
  if (!Object.prototype.hasOwnProperty.call(schema, "format")) {
    transformed = cloneDeep(schema);
    transformed["format"] = "categories";
    transformed["basicCategoryTitle"] = "Info";
  }
  transformed["properties"]["is_not_synced"] = {
    type: "boolean",
    const: true,
    readOnly: true,
  };
  return transformed || schema;
}
</script>
