<template>
  <div class="has-background-purple-5 px-2">
    <h1>
      Dashboard <b v-if="game">{{ game.name }}</b>
    </h1>
    <br />
    <Dashboard style="height: 87%" />
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Dashboard from "../components/Live/Dashboard.vue";

export default {
  name: "GameSettings",
  components: { Dashboard },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // NOTE: we need to have info about game and its plugins here
    if (!route.query.game) router.push({ path: "/" });
    if (!store.state.data.game) store.dispatch("selectGame", route.query.game);
    onMounted(() => {
      store
        .dispatch("findLiveItems", {
          "dashboard.favorite": true,
          _sort_by: "modified_at",
          _sort_direction: -1,
        })
        .then(() =>
          store.dispatch("findLiveItems", {
            _limit: 50,
            _sort_by: "modified_at",
            _sort_direction: -1,
          })
        );
    });

    // Compute necessary Store values for Component
    const game = computed(() => store.state.data.game);

    // Create local References

    // Create local Callback Functions

    return {
      game,
    };
  },
};
</script>
