<template>
  <div id="main-container" class="is-flex is-flex-direction-column is-full-vh">
    <Navbar class="navbar-fixed-z" />
    <Auth v-if="authVisible" />
    <router-view class="is-flex-grow-1 is-routerview-high" />
    <Footer class="is-layer-3 is-footer-high" />
  </div>
</template>

<script>
import Navbar from "@/components/Page/Navbar.vue";
import Footer from "@/components/Page/Footer.vue";
import Auth from "@/components/Page/Auth.vue";

import { computed, watch, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  components: {
    Navbar,
    Footer,
    Auth,
  },
  setup() {
    const store = useStore();
    const is_live = computed(() => store.state["selected"]["is_live"]);
    const game = computed(() => store.state["data"]["game"]);
    const level = computed(() => store.state["selected"]["level_id"]);
    const authVisible = ref(false);

    document.addEventListener("toggleAuth", () => {
      console.log("App.vue received toggleAuth");
      if (!localStorage.authtoken || localStorage.authtoken == "null") {
        authVisible.value = true;
      } else {
        authVisible.value = false;
        window.location.reload();
      }
    });

    watch(is_live, () => {
      if (!is_live.value) {
        store.dispatch("liveSessionsDisconnect");
        store.dispatch("liveItemsDisconnect");
      } else if (game.value.name) {
        store.dispatch("liveItemsConnect");
        store.dispatch("liveSessionsConnect");
      }
    });

    watch(level, () => {
      if (!level.value) {
        store.dispatch("levelDisconnect");
      }
      console.log("globally connect to level", level.value);
      store.dispatch("levelConnect");
    });

    watch(game, () => {
      if (!game.value.name) {
        return store.dispatch("gameDisconnect");
      }
      store.dispatch("gameConnect");
    });

    return { authVisible };
  },
};
</script>
