<template>
  <div>
    <div class="box has-background-pink-2">
      <div class="field">
        <label class="label">New Level {{ level }} Session</label>
        <div class="control">
          <input
            class="input"
            placeholder="Session Name"
            v-model="sessionName"
          />
        </div>
      </div>
      <div class="field has-background-light p-3">
        <div v-for="(value, argument) in sessionArgs" :key="'arg' + argument">
          <div class="field">
            <label class="label">
              {{ argument }} ({{ value.collection }})
            </label>
            <div class="control">
              <input
                class="input has-background-dark has-text-light"
                placeholder="database query"
                :value="value.query"
                @change="handleArgsChange(argument, $event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button class="button is-dark" @click.stop.prevent="launchClicked">
            Start Session
          </button>
        </div>
        <div class="control">
          <button class="button is-dark" @click="$emit('close')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  components: {},
  props: ["game", "level"],
  name: "SessionLauncher",
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const game = props.game || route.query.game;
    const level = props.level || route.query.level;
    const sessionName = ref("");
    const sessionArgs = ref(store.getters.levelArguments(level));

    const handleArgsChange = (key, value) => {
      sessionArgs.value[key]["query"] = value;
    };

    const launchClicked = () => {
      store.dispatch("launchSession", {
        game,
        level,
        session: sessionName.value,
        args: sessionArgs.value,
      });
      emit("close");
    };
    return {
      launchClicked,
      handleArgsChange,
      sessionName,
      sessionArgs,
    };
  },
};
</script>
