<template>
  <!------------------------ PLUGIN  ------------------------>
  <div
    v-for="plugin in plugins"
    :key="plugin.name"
    class="plugin-settings-container has-background-white m-2"
  >
    <Collapsible :class="['pl-1', plugin.core ? 'has-background-light' : '']">
      <template v-slot:header>
        <div
          class="
            is-flex
            is-flex-direction-row
            is-align-items-center
            is-justify-content-space-between
            p-1
          "
        >
          <div>{{ plugin.name.toUpperCase() }}</div>
          <div v-if="!plugin.core" class="is-pulled-right">
            <Icon
              v-if="plugin.connectible"
              class="has-text-dark m-1"
              :icon="'plug'"
              @click.stop="handleConnectPluginClicked(plugin.name)"
            />
            <Icon
              v-if="plugin.connectible"
              class="has-text-dark m-1"
              :icon="'unplug'"
              @click.stop="handleDisconnectPluginClicked(plugin.name)"
            />
            <Icon
              v-if="plugin.connectible"
              :icon="'circle'"
              :tooltip="`Plugin ${
                plugin.connected ? 'connected' : 'disconnected'
              }`"
              :fill="plugin.connected ? 'green' : 'red'"
            />
            <Icon
              :icon="'refresh-cw'"
              :tooltip="'Reload Plugin'"
              class="ml-4"
              @click.stop="
                $store
                  .dispatch('loadPlugin', { plugin: plugin.name })
                  .then(() => {
                    toast.success('Updated Plugin settings.');
                  })
              "
            />
            <Icon
              :icon="'trash'"
              :tooltip="'Delete Plugin'"
              class="m-4"
              @click.stop="
                $store.dispatch('removePlugin', { plugin: plugin.name })
              "
            />
          </div>
        </div>
      </template>
      <template v-slot>
        <details>
          <summary>DATA</summary>
          <pre>{{ JSON.stringify(plugin, null, 2) }}</pre>
        </details>
        <!------------------------ PLUGIN SETTINGS  --------------------------->
        <!-- FIXME: JsonEditor does not update when schema Changes might need sth for that-->
        <JsonEditor
          v-if="!plugin.core"
          :schema="plugin.settings.schema"
          :data="plugin.settings.data"
          @dataChanged="
            $store
              .dispatch('updatePlugin', {
                name: plugin.name,
                data: { settings: { data: $event } },
              })
              .then(() => {
                return $store
                  .dispatch('syncPluginSettings', { plugin: plugin.name })
              })
              .catch(() => {
                toast.error('error plugin update');
              })
          "
        />
        <!------------------------ PLUGIN COLLECTIONS  ------------------------>
        <br v-if="!plugin.core" />
        <h2 v-if="!plugin.core">ITEMS</h2>
        <div
          v-for="(value, collection, index) in plugin.items"
          :key="index"
          class="ml-2 m-1 p-1"
        >
          <h2>{{ collection.toUpperCase() }}</h2>
          <!------------------------ PLUGIN ITEMS IN COLLECTION --------------->
          <div
            v-for="(item, index) in value.items"
            :key="collection + '_item_' + index"
            class="mb-2 p-2 has-background-blue-4 has-text-black"
          >
            <span>{{ item?.name || `${collection}_${index}` }}</span>
            <div class="is-pulled-right">
              <Icon
                v-if="item.connectible"
                class="has-text-black m-2"
                :icon="'plug'"
                :title="`Connect Plugin`"
                @click.stop="
                  $store.dispatch('connectPluginItem', {
                    plugin: plugin.name,
                    collection: collection,
                    index: index,
                  })
                "
              />
              <Icon
                v-if="item.connectible"
                class="has-text-black m-2"
                :icon="'unplug'"
                :title="`Disconnect Plugin Item`"
                @click.stop="
                  $store.dispatch('disconnectPluginItem', {
                    plugin: plugin.name,
                    collection: collection,
                    index: index,
                  })
                "
              />
              <Icon
                v-if="item.connectible"
                class="m-2"
                :icon="'circle'"
                :tooltip="`Plugin ${
                  item.connected ? 'connected' : 'disconnected'
                }`"
                :fill="item.connected ? 'green' : 'red'"
              />
              <Icon
                class="has-text-black m-2"
                :icon="'refresh-cw'"
                :title="`Reload Plugin Item`"
                @click.stop="
                  $store.dispatch('loadPluginItem', {
                    plugin: plugin.name,
                    collection: collection,
                    index: index,
                  })
                "
              />
              <Icon
                :icon="'trash'"
                :title="`Delete Plugin Item`"
                class="has-text-black ml-4"
                @click.stop="
                  handleDeletePluginItemClicked({
                    plugin: plugin.name,
                    collection: collection,
                    index: index,
                  })
                "
              />
            </div>
            <JsonEditor
              :schema="value.schema"
              :data="item"
              @dataChanged="
                $store.dispatch('updatePluginItem', {
                  plugin: plugin.name,
                  collection: collection,
                  index: index,
                  data: $event,
                })
              "
            />
            <button
              class="button mt-1 has-background-dark has-text-light"
              @click="
                handleSyncPluginItemClicked($event, {
                  plugin: plugin,
                  collection: collection,
                  index: index,
                  item: item,
                })
              "
            >
              SAVE
            </button>
          </div>
          <button
            class="button has-background-blue-5 has-text-black is-full-width"
            @click="
              $store.dispatch('addItemToPlugin', {
                plugin: plugin.name,
                collection,
              })
            "
          >
            <Icon :icon="'plus-circle'" />
          </button>
          <div></div>
        </div>
      </template>
    </Collapsible>
  </div>
  <!------------------------ ADD PLUGIN LIST --------------------------------->
  <h2 class="m-2 mb-3">AVAILABLE</h2>
  <div v-for="plugin in availablePlugins" :key="plugin">
    <div
      v-if="!plugins?.find((p) => p.name == plugin.name)"
      class="
        plugin-settings-container
        available-plugin
        is-flex
        is-flex-direction-row
        is-align-items-center
        is-justify-content-space-between
        p-1
        m-2
        has-background-purple-5
      "
    >
      <div>{{ plugin.name.toUpperCase() }}</div>
      <Icon
        :icon="'plus-circle'"
        class="is-pulled-right has-text-black m-4"
        @click.stop="handleAddPluginClicked(plugin.name)"
      />
    </div>
  </div>
  <!------------------------ DELETE PLUGIN MODAL --------------------------------->
  <Modal
    v-if="deletePluginItemModal.visible"
    :modal="deletePluginItemModal"
    @closeModal="deletePluginItemModal.visible = false"
    :cssClasses="{
      message: true,
    }"
  >
    <template v-slot:header>DELETE PLUGIN ITEM</template>
    <br />
    Are you sure?
    <template v-slot:footer>
      <button
        class="button is-primary m-1"
        @click="
          $store
            .dispatch('deletePluginItem', {
              plugin: deletePluginItemModal.plugin,
              collection: deletePluginItemModal.collection,
              index: deletePluginItemModal.index,
            })
            .then(() => {
              deletePluginItemModal.visible = false;
            })
        "
      >
        DELETE
      </button>
      <button class="button m-1" @click="deletePluginItemModal.visible = false">
        CANCEL
      </button>
    </template>
  </Modal>
</template>

<script>
import Collapsible from "@/components/Template/Collapsible.vue";
import JsonEditor from "@/components//Form/JsonEditor.vue";
import Modal from "@/components/Template/Modal.vue";

import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  name: "PluginSettings",
  components: { Collapsible, JsonEditor, Modal },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    // Compute necessary Store values for Component
    const plugins = computed(() => store.state.data.plugins);
    const availablePlugins = computed(() => store.state.data.plugins_list);

    // Create local References
    const deletePluginItemModal = ref({
      visible: false,
      plugin: null,
      collection: null,
      index: null,
    });

    // Create local Callback Functions

    const handleConnectPluginClicked = (plugin) =>
      store.dispatch("connectPlugin", { plugin: plugin });

    const handleDisconnectPluginClicked = (plugin) =>
      store.dispatch("disconnectPlugin", { plugin: plugin });

    const handleSyncPluginItemClicked = (
      e,
      { plugin, collection, index, item }
    ) => {
      store
        .dispatch("syncPluginItem", {
          plugin: plugin.name,
          collection: collection,
          index: index,
        })
        .then(() => {
          toast.success("Synched Plugin item.");
          if (item.is_not_synced) router.go(); // happens before all api requests are done. darn.
        });
    };

    const handleDeletePluginItemClicked = ({ plugin, collection, index }) => {
      deletePluginItemModal.value.plugin = plugin;
      deletePluginItemModal.value.collection = collection;
      deletePluginItemModal.value.index = index;
      deletePluginItemModal.value.visible = true;
    };

    const handleAddPluginClicked = (plugin) => {
      store.dispatch("addPlugin", { plugin });
    };

    return {
      toast,
      plugins,
      availablePlugins,
      deletePluginItemModal,
      handleConnectPluginClicked,
      handleDisconnectPluginClicked,
      handleSyncPluginItemClicked,
      handleDeletePluginItemClicked,
      handleAddPluginClicked,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.plugin-settings-container {
  border: 1px solid black;
}

.connection-indicator {
  display: inline-block;
  margin-top: 2px;
  height: 15px;
  width: 15px;
  border: $blue-4 solid 2px;
  border-radius: 50%;
  background-color: grey;
}

.connected {
  background-color: green;
}

.disconnected {
  background-color: red;
}
</style>
