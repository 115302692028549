<template>
  <div class="dropdown" :class="{ 'is-active': isOpen, 'is-up': unfoldUp }">
    <div
      class="dropdown-trigger is-flex"
      @click="
        isOpen = !isOpen;
        isOpen ? $emit('isOpen') : $emit('isClosed');
      "
    >
      <slot
        name="anchor"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      ></slot>
    </div>
    <div
      :class="{ 'is-right': unfoldRight }"
      class="dropdown-menu p-1"
      id="dropdown-menu"
      role="menu"
    >
      <div class="dropdown-content has-border p-0">
        <slot name="content"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "Popup",
  props: {
    unfoldUp: { type: Boolean, default: false },
    unfoldRight: { type: Boolean, default: false },
    highlight: { type: Boolean, default: false },
    toggle: { type: Boolean, default: false },
  },
  emits: ["isOpen", "isClosed"],
  setup(props) {
    const isOpen = ref(false);
    watch(
      () => props.toggle,
      () => {
        isOpen.value = props.toggle;
      }
    );
    const debuglog = (e) => console.log("popup", e);
    return { isOpen, debuglog };
  },
};
</script>

<style>
.is-right {
  right: 0;
  left: unset !important;
}
</style>
