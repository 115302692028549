<template>
  <div>
    <div v-if="schema" ref="container"></div>
    <div v-else>
      <textarea
        cols="25"
        rows="25"
        @change="handleChange(JSON.parse($event.target.value))"
        :value="JSON.stringify(data, null, 2)"
      >
      </textarea>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

import { cloneDeep } from "lodash";
import { JSONEditor } from "@json-editor/json-editor";
import { AdaptorTheme } from "@/styles/json-editor/adaptorTheme.js";

export default {
  name: "FormDefault",
  props: ["data", "schema"],
  emits: ["dataChanged", "dataValidation"],
  setup(props, { emit }) {
    const store = useStore();
    const container = ref(null);
    let editor;

    const localData = cloneDeep(props.data);
    const localSchema = cloneDeep(props.schema);
    // console.log(localData, localSchema);

    const options = {
      schema: localSchema.properties.payload, // prepareSchema(props.schema),
      startval: localData?.payload || {}, // TODO maybe BUG: watch prop and rerender!?
      disable_collapse: true,
      disable_array_delete: false,
      show_errors: "always",
      theme: "adaptor",
      disable_array_reorder: true,
    };

    JSONEditor.defaults.theme = "adaptor";
    JSONEditor.defaults.themes.adaptor = AdaptorTheme;

    ///////// DEFINE OUR INPUTS/ EDITORS //////////////

    // https://phd.dmstr.io/en/docs/guide/tutorials/json-editor-customizations.md
    JSONEditor.defaults.editors.next = class Next extends (
      JSONEditor.defaults.editors.string
    ) {
      postBuild() {
        super.postBuild();
        if (this.label) this.label.innerText = "next state";
        const dataList = document.createElement("datalist");
        store.getters.statesNameList.forEach((state) => {
          const option = document.createElement("option");
          option.value = state;
          dataList.appendChild(option);
        });
        dataList.setAttribute("id", "dlStates");
        this.container.appendChild(dataList);
        this.input.setAttribute("list", "dlStates");
        this.input.setAttribute("autocomplete", "off");
        this.input.classList.add("adaptor-next");
      }
    };

    JSONEditor.defaults.editors.adaptorString = class AdaptorString extends (
      JSONEditor.defaults.editors.string
    ) {
      postBuild() {
        super.postBuild();
        if (this.label) {
          const droppable = document.createElement("span");
          droppable.setAttribute("style", "font-size:0.5em");
          droppable.innerText = " (drop)";
          this.label.appendChild(droppable);
        }
        this.input.addEventListener("dragover", () => {
          showCaret(this.input);
        });
        this.input.addEventListener("drop", (e) => {
          e.stopPropagation();
          e.preventDefault();
          let asset = e.dataTransfer.getData("asset");
          let newvalue = [
            this.input.value.slice(0, this.input.selectionStart),
            asset,
            this.input.value.slice(this.input.selectionStart),
          ].join("");
          this.input.value = newvalue;
          this.input.dispatchEvent(new Event("change"));
        });
      }
    };

    // This moves the "Settings" or Properties Button to bottom of indent node
    // JSONEditor.defaults.editors.object = class AdaptorObject extends (
    //   JSONEditor.defaults.editors.object
    // ) {
    //   postBuild() {
    //     if (!this.container) return;
    //     const propBtnNode = this.container.querySelector(
    //       ".json-editor-btn-edit_properties"
    //     );

    //     if (Object.prototype.isPrototypeOf.call(Node.prototype, propBtnNode)) {
    //       const indentNode = this.container.querySelector(".je-indented-panel");
    //       const modalNode = this.container.querySelector(
    //         ".je-object__controls .adaptor-modal .property-selector"
    //       ).parentNode;

    //       indentNode.append(propBtnNode);
    //       indentNode.addEventListener(
    //         "DOMNodeInserted",
    //         () => {
    //           modalNode.style.left = `${propBtnNode.offsetLeft}px`;
    //           modalNode.style.top = `${
    //             propBtnNode.offsetTop + propBtnNode.offsetHeight
    //           }px`;
    //         },
    //         false
    //       );
    //     }
    //   }
    // };

    ///////// END DEFINE OUR INPUTS/ EDITORS //////////////

    function showCaret(el) {
      if (el != null) {
        el.focus();
      }
    }

    /////// REGISTER OUR SPECIAL INPUTS/EDITORS ////////////

    JSONEditor.defaults.resolvers.unshift(
      (schema) =>
        schema.type === "string" && schema.format === "adaptorState" && "next"
    );

    JSONEditor.defaults.resolvers.unshift(
      (schema) =>
        schema.type === "string" &&
        schema.format !== "adaptorState" &&
        !schema.enum &&
        "adaptorString"
    );

    /////// END REGISTERING ///////////////////////////////

    const handleChange = (data) => emit("dataChanged", data);

    onMounted(() => {
      let firstFlag = true;
      editor = new JSONEditor(container.value, options);
      editor.on("ready", () => {});
      editor.on("change", () => {
        const errors = editor.validate();
        emit("dataValidation", {
          valid: errors.length > 0 ? false : true,
          errors,
        });
        if (firstFlag) {
          firstFlag = false;
          return;
        }
        // TODO: dialog with error message + highlight action as with error
        handleChange(Object.assign(localData, { payload: editor.getValue() }));
      });
    });

    onUnmounted(() => editor.destroy());

    return { container, handleChange };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
textarea {
  width: 100%;
}
</style>
