<template>
  <div
    v-if="stateId"
    :id="state.name"
    :key="state"
    class="state has-state-style-default"
    :class="{
      'is-selected': isSelected && !isLiveMode,
      'is-layer-3': isSelected || isCurrent,
      'has-state-style-live': isCurrent,
      'has-background-adaptor': dragOver > 0,
    }"
    :stateid="stateId"
    @click.stop="handleSelectState"
    @drop.prevent="handleDrop"
    @dragover.prevent
    @dragenter="dragOver++"
    @dragend="dragOver = 0"
    @dragleave="dragOver--"
  >
    <div class="p-1">
      <div
        class="handle level pl-1 is-unselectable"
        :class="{ 'is-grabbable': !isLiveMode }"
        :title="`${state.name} [${state?.path || ''}]`"
      >
        <div class="level-left">
          <div class="level-item">
            <div class="field">
              <div class="control">
                <h5
                  v-show="!isEditName"
                  @pointerdown.prevent
                  @dblclick="handleEditStatename"
                  class="is-size-5 is-clipped has-overflow-ellipsis has-text-weight-semibold is-cursor-default"
                  :class="{
                    'has-text-purple-1':
                      state.name == 'START' || state.name == 'QUIT',
                  }"
                  style="max-width: 15ch"
                >
                  {{ state.name }}
                </h5>
                <input
                  ref="stateNameInput"
                  v-show="isEditName"
                  :value="state.name"
                  @blur="handleStateNameChange"
                  @keyup.enter="handleStateNameChange"
                  class="input is-full-height has-text-weight-semibold has-background-light"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <Popup
              v-if="
                isSelected &&
                state.name != 'START' &&
                state.name != 'QUIT' &&
                !$store.state.selected.is_live
              "
              :toggle="togglePopup"
              :highlight="true"
              @isOpen="togglePopup = true"
            >
              <template v-slot:anchor>
                <Icon :icon="'trash-2'" />
              </template>
              <template v-slot:content>
                <div class="dropdown-item p-2">
                  <div class="field is-grouped">
                    <p class="control">
                      <a class="button is-danger" @click="handleDeleteClick">
                        Delete State
                      </a>
                    </p>
                    <p class="control">
                      <a class="button is-light" @click="togglePopup = false">
                        Cancel
                      </a>
                    </p>
                  </div>
                </div>
              </template>
            </Popup>
            <Comment
              :comments="state.comments"
              :isUnlocked="isSelected"
              @updateComment="handleUpdateComment"
              @deleteComment="handleDeleteComment"
            />
          </div>
        </div>
      </div>
    </div>
    <hr
      v-if="state.run.length"
      class="state-hr"
      :class="[isCurrent ? 'has-background-is-live' : 'has-background-black']"
    />
    <div v-if="state.run.length" class="p-1 pr-2 is-position-relative">
      <img
        :src="RunSVG"
        alt="Run Action Icon"
        class="is-absolute-vcenter"
        title="This section contains Run Actions. They are always executed straight away."
      />
      <ActionNode
        v-for="actionId in state.run"
        :key="actionId"
        :actionId="actionId"
        :stateName="state.name"
        :stateId="stateId"
        @selectAction="handleSelectAction(actionId)"
      />
    </div>
    <hr
      v-if="state.listen.length"
      class="state-hr"
      :class="[isCurrent ? 'has-background-is-live' : 'has-background-black']"
    />
    <div v-if="state.listen.length" class="p-1 is-position-relative">
      <img
        :src="ListenSVG"
        alt="Listen Icon"
        class="is-absolute-vcenter"
        title="This section contains Listen Actions. They wait for specific events."
      />
      <ActionNode
        v-for="actionId in state.listen"
        :key="actionId"
        :actionId="actionId"
        :stateName="state.name"
        :stateId="stateId"
        @selectAction="handleSelectAction(actionId)"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { watch, ref, computed, nextTick, provide } from "vue";
import { useToast } from "vue-toastification";

import ActionNode from "./ActionNode.vue";
import Comment from "./Comments.vue";
import Popup from "@/components/Template/Popup.vue";
import RunSVG from "@/assets/run.svg";
import ListenSVG from "@/assets/listen.svg";

export default {
  name: "StateNode",
  props: {
    stateId: {
      type: String,
      required: true,
    },
  },
  components: { ActionNode, Comment, Popup },
  emits: ["dragEvent", "stateUpdated", "actionUpdated", "nodeDeleted"],
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const state = computed(() => store.state.data.level.states[props.stateId]);
    const isSelected = computed(
      () => store.state.selected.state == props.stateId
    );
    const isCurrent = computed(() =>
      store.getters
        .currentLiveStates(store.state.selected.session)
        .includes(props.stateId)
    );

    const isLiveMode = computed(() => store.state.selected.is_live);
    const dragOver = ref(0); // NOTE: Counter because of child elements firing dragevents; on dragOver>0 we have element over state node
    const togglePopup = ref(false);
    const isEditName = ref(false);
    const stateNameInput = ref(null);

    const handleDeleteClick = () => {
      store.dispatch("deleteState", props.stateId);
    };

    const handleUpdateComment = (index, comment) => {
      store
        .dispatch("updateStateComment", {
          stateId: props.stateId,
          index,
          comment,
        })
        .then(() => {
          emit("stateUpdated", { status: state.value, stateId: props.stateId });
        });
    };

    const handleDeleteComment = (index) => {
      store
        .dispatch("deleteStateComment", {
          stateId: props.stateId,
          index,
        })
        .then(() => {
          emit("stateUpdated", { status: state.value, stateId: props.stateId });
        });
    };

    const handleDrop = (e) => {
      dragOver.value = 0;
      const action = e.dataTransfer.getData("action");
      const asset = e.dataTransfer.getData("asset");
      if (asset)
        toast.warning(
          `Could not drop asset: ${asset}.\nPlease drop asset on input fields marked (drop).`
        );
      else if (action)
        store.dispatch("addActionToState", {
          stateId: props.stateId,
          actionName: action,
        });
      else console.error("Could not handle drop Event.", e);
    };

    const handleSelectAction = (action) => {
      if (store.state.selected.is_live)
        return console.log("no action edits in live mode");
      store
        .dispatch("selectAction", {
          action,
          stateId: props.stateId,
        })
        .then(() => {
          emit("stateUpdated", { status: state.value, stateId: props.stateId });
        });
    };

    const handleSelectState = () => {
      store.dispatch("selectState", { stateId: props.stateId, trigger: true });
    };

    const handleStateNameChange = (e) => {
      isEditName.value = false;
      if (e.target.value === state.value.name) return;
      const stateId = props.stateId;
      store.dispatch("updateStateName", { stateId, name: e.target.value }).then(
        () => emit("stateUpdated", { status: state.value, stateId }),
        () => (e.target.value = state.value.name) // on name rejected use old
      );
    };

    const handleEditStatename = () => {
      if (
        state.value.name == "START" ||
        state.value.name == "QUIT" ||
        store.state.selected.is_live ||
        !isSelected.value
      )
        return;
      isEditName.value = true;
      nextTick(() => {
        stateNameInput.value.focus; // set focus to the textbox/textarea
        stateNameInput.value.setActive; // make the textbox/textarea the one that is going to receive mouse and keyboard input
        stateNameInput.value.select(); // some browsers require this line of code for it to work right
      });
    };

    watch(state.value, () => {
      // TODO: Check if this is still needed
      // emit("stateUpdated", { status: state.value, stateId: props.stateId });
    });

    // Note: we pass this variable to the great-grandchild ActionEditor to handle drop events that are not propagated
    provide("dragOverState", dragOver);

    return {
      ListenSVG,
      RunSVG,
      state,
      isSelected,
      isCurrent,
      isLiveMode,
      dragOver,
      togglePopup,
      isEditName,
      handleSelectAction,
      handleSelectState,
      handleDrop,
      handleDeleteClick,
      handleUpdateComment,
      handleDeleteComment,
      handleStateNameChange,
      handleEditStatename,
      stateNameInput,
    };
  },
};
</script>
