<template>
  <div class="p-4 is-flex is-flex-direction-column">
    <h1 class="subtitle">{{ isLive ? "SESSIONS" : "LEVEL" }} OVERVIEW</h1>
    <section v-if="levels" class="py-2 is-not-clipped is-max-full-height">
      <SessionList
        v-if="isLive"
        :levels="levels"
        :sessions="sessions"
        @sessionSelect="handleSessionClicked"
        @sessionDelete="handleDeleteSessionClicked"
        @levelSelect="handleSessionClicked"
      />
      <LevelList
        v-else
        :levels="levels"
        @levelSelect="handleLevelClicked"
        @levelDelete="handleDeleteLevelClicked"
        @levelUpdate="updateLevelStatus"
      />
    </section>

    <!-- ADD BUTTON AND MODAL -->
    <section v-if="!isLive" class="mt-5">
      <button class="button is-link" @click="addLevelModal.visible = true">
        NEW LEVEL
      </button>
    </section>
    <Modal
      v-if="addLevelModal.visible"
      :modal="addLevelModal"
      @closeModal="addLevelModal.visible = false"
      :cssClasses="{ message: true }"
    >
      <template v-slot:header>Add New Level</template>
      <div>
        <label for="levelname">Create new Level with name</label>
        <input
          class="input"
          type="text"
          name="levelname"
          placeholder="name"
          v-model="addLevelModal.name"
        />
      </div>
      <div class="mt-4">
        <label for="levefile">or import Level file</label>
        <input
          class="button input"
          type="file"
          name="levefile"
          accept="application/json"
          @change="handleImportLevelSelected($event)"
        />
      </div>
      <template v-slot:footer>
        <button
          class="button is-success"
          @click="handleNewLevelClicked($event)"
        >
          Add new Level
        </button>
        <button class="button" @click="addLevelModal.visible = false">
          Cancel
        </button>
      </template>
    </Modal>
    <!-- DELETE AND MODAL -->
    <Modal
      v-if="deleteLevelModal.visible"
      :modal="deleteLevelModal"
      @closeModal="deleteLevelModal.visible = false"
      :cssClasses="{
        message: true,
        'is-danger': true,
      }"
    >
      <template v-slot:header>DELETE LEVEL</template>
      Do you really want do delete level
      <b>{{ deleteLevelModal.name }}</b> ?
      <template v-slot:footer>
        <button class="button is-danger m-1" @click="deleteLevel">
          DELETE
        </button>
        <button class="button m-1" @click="deleteLevelModal.visible = false">
          CANCEL
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import LevelList from "@/components/Game/LevelList.vue";
import SessionList from "@/components/Game/SessionList.vue";
import Modal from "@/components/Template/Modal.vue";

export default {
  name: "GameView",
  components: { LevelList, SessionList, Modal },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      const game = route.query.game;
      // We need to have info about game and its plugins here
      if (!game) router.push({ path: "/" });
      // if (!store.state.data.game)
      store.dispatch("selectGame", game);
      if (isLive) store.dispatch("findSessions", { game: game });
    });

    // Compute necessary Store values for Component
    const game = computed(() => store.state.selected?.game);
    const levels = computed(() => store.getters.extendedLevelList);
    const sessions = computed(() => store.getters.sessionsSortedByLevel);
    const isLive = computed(() => store.state.selected.is_live);

    // Create local References
    const deleteLevelModal = ref({ visible: false, name: null });
    const addLevelModal = ref({ visible: false, name: null });

    // Create local Callback Functions

    const handleNewLevelClicked = () => {
      console.log(addLevelModal.value.name);
      if (addLevelModal.value.file) {
        const fr = new FileReader();
        fr.addEventListener("load", () => {
          const data = JSON.parse(fr.result);
          console.log(data);
          if (addLevelModal.value.name) data.name = addLevelModal.value.name;
          store.dispatch("createLevel", { level: data }).then(
            () => (addLevelModal.value.visible = false),
            (error) => console.error(error) // on name rejected
          );
        });
        fr.readAsText(addLevelModal.value.file);
      } else if (addLevelModal.value.name) {
        store
          .dispatch("createLevel", {
            level: { name: addLevelModal.value.name },
          })
          .then(
            () => (addLevelModal.value.visible = false),
            (error) => console.error(error) // on name rejected
          );
      }
    };

    const handleImportLevelSelected = (e) => {
      if (e.srcElement.files[0].type == "application/json")
        addLevelModal.value.file = e.srcElement.files[0];
    };

    const handleDeleteLevelClicked = (level) => {
      deleteLevelModal.value.name = level;
      deleteLevelModal.value.visible = true;
    };

    const deleteLevel = () => {
      store
        .dispatch("deleteLevel", {
          game: game.value,
          level: deleteLevelModal.value.name,
        })
        .then(() => (deleteLevelModal.value.visible = false));
    };

    const handleSessionClicked = (level, session) => {
      store.dispatch("selectSession", { session });
      router.push({
        path: "/editor",
        query: { game: game.value, level },
      });
    };

    const handleDeleteSessionClicked = (session) => {
      console.log("deleting session", session);
      store.dispatch("cancelSession", { game: game.value, session });
    };

    const handleLevelClicked = (level) => {
      router.push({
        path: "/editor",
        query: { game: game.value, level },
      });
    };

    const updateLevelStatus = (level, status) =>
      store.dispatch("editLevelStatus", { level, status });

    return {
      game,
      levels,
      sessions,
      isLive,
      deleteLevelModal,
      addLevelModal,
      handleNewLevelClicked,
      handleImportLevelSelected,
      handleDeleteLevelClicked,
      deleteLevel,
      handleLevelClicked,
      handleSessionClicked,
      handleDeleteSessionClicked,
      updateLevelStatus,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";
.play-button {
  position: fixed;
  top: 52px;
  right: 0;
}
</style>
