<template>
  <div class="auth has-background-adaptor">
    <div class="box p-5 has-border">
      <figure class="image mb-5">
        <img :src="LoginSVG" alt="Adaptor Login Image" />
      </figure>
      <header class="level">
        <div class="level-left">
          <div class="level-item">
            <h5 class="title is-size-5">LOGIN</h5>
          </div>
          <div class="level-item">
            <div class="tooltip is-flex">
              <span class="icon is-small">
                <Icon
                  class="has-text-dark"
                  :icon="'help-circle'"
                  :interactive="false"
                />
              </span>
              <span class="tooltiptext">
                Need credentials?<br />
                Contact your adaptor:ex admin.
              </span>
            </div>
          </div>
        </div>
      </header>
      <div class="content" @keyup.enter="authenticate">
        <div class="field">
          <!-- <label class="label">Username</label> -->
          <div class="control has-icons-left">
            <input
              class="input is-link"
              type="text"
              placeholder="username"
              autofocus
              v-model="user"
            />
            <span class="icon is-small is-left">
              <Icon :icon="'user'" :interactive="false" />
            </span>
          </div>
        </div>

        <div class="field">
          <!-- <label class="label">Password</label> -->
          <div class="control has-icons-left">
            <input
              class="input is-link"
              type="password"
              placeholder="password"
              v-model="password"
            />
            <span class="icon is-small is-left">
              <Icon :icon="'lock'" :interactive="false" />
            </span>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <p v-if="showError" class="help is-danger">
              Unknown username or wrong password.
            </p>
            <button
              class="button is-link"
              :class="{ 'is-loading': loading }"
              @click.stop="authenticate"
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { ref } from "@vue/runtime-core";
import LoginSVG from "@/assets/adaptor_login.svg";

export default {
  name: "Auth",
  setup() {
    const app = getCurrentInstance();
    const api = app.appContext.config.globalProperties.API_URL;
    const user = ref(null);
    const password = ref(null);
    const showError = ref(false);
    const loading = ref(false);

    const authenticate = () => {
      loading.value = true;
      showError.value = false;

      let headers = new Headers();

      //headers.append('Content-Type', 'text/json');
      headers.append(
        "Authorization",
        "Basic " +
          Buffer.from(user.value + ":" + password.value).toString("base64")
      );

      fetch(api + "/games", {
        // TODO: add auth endpoint server-side that returns token
        method: "GET",
        headers: headers,
      }).then((res) => {
        if (res.status != 401) {
          loading.value = false;
          showError.value = false;

          localStorage.setItem(
            "authtoken",
            Buffer.from(user.value + ":" + password.value).toString("base64")
          );
          // notify App.vue
          document.dispatchEvent(new Event("toggleAuth", { bubbles: true }));
        } else {
          // Note: timeout only for UX
          setTimeout(() => {
            loading.value = false;
            showError.value = true;
          }, 1000);
        }

        // TODO: replace with token returned from api. Atm: uses Basic Auth pseudotoken like Basic Auth Header would contain.
      });
    };

    return {
      LoginSVG,
      user,
      password,
      showError,
      loading,
      authenticate,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.auth {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  padding: 1em;
  height: 100vh;
  width: 100vw;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: #333333;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: -20px;
  left: 140%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #333333 transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
