<template>
  <div class="toolbar p-0 has-background-white is-full-height is-unselectable">
    <div class="tabs is-boxed has-text-weight-semibold">
      <ul>
        <li class="tab-link is-active" @click="openTab($event, 'tab_session')">
          <a>SESSION</a>
        </li>
        <li class="tab-link" @click="openTab($event, 'tab_things')">
          <a>CONTAINER THINGS</a>
        </li>
      </ul>
    </div>
    <div class="tab-container pl-2 pr-2 has-text-black">
      <!-- TAB CONTENT SESSIONS -->
      <div id="tab_session" class="tab-content">
        <!-- LIST OF SESSIONS -->
        <div
          v-for="session in sessions"
          :key="session._id"
          class="box p-3 field is-grouped"
          :class="[
            selected == session._id
              ? 'has-background-is-live'
              : 'has-background-light',
          ]"
        >
          <div
            class="control is-expanded is-clipped has-overflow-ellipsis"
            @click="sessionSelect(session._id)"
          >
            <label> {{ session.name }} </label>
          </div>
          <div @click="sessionDelete(session._id)">
            <Icon :icon="'trash-2'" />
          </div>
        </div>
        <hr />

        <!-- ADD NEW SESSION -->
        <Collapsible>
          <template v-slot:header>
            <button ref="collapsible" class="mt-4 button is-dark is-fullwidth">
              Create Session
            </button>
          </template>
          <template v-slot>
            <SessionLauncher @close="$refs.collapsible.click()" />
          </template>
        </Collapsible>
      </div>
      <!-- END OF TAB CONTENT SESSIONS -->

      <!-- TAB CONTENT CONTAINER THINGS -->
      <div id="tab_things" class="tab-content" style="display: none">
        <h2 class="mb-1">...coming soon...</h2>
      </div>
      <!-- END OF TAB CONTENT CONTAINER THINGS -->
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import Collapsible from "@/components/Template/Collapsible.vue";
import SessionLauncher from "@/components/Live/SessionLauncher.vue";

export default {
  name: "Livebar",
  components: { Collapsible, SessionLauncher },
  props: ["level"],
  setup(props) {
    const store = useStore();

    const collapsible = ref(null);

    const sessions = computed(() => store.getters.sessionsByLevel(props.level));
    const selected = computed(() => store.state.selected.session);
    const game = computed(() => store.state.selected.game);

    const log = (e) => console.log(e);

    const openTab = (e, tabName) => {
      // hide everything
      const links = document.getElementsByClassName("tab-link");
      const tabs = document.getElementsByClassName("tab-content");
      Array.from(links).forEach((node) => node.classList.remove("is-active"));
      Array.from(tabs).forEach((node) => (node.style.display = "none"));
      // show selection
      document.getElementById(tabName).style.display = "block";
      e.currentTarget.classList.add("is-active");
    };

    const sessionSelect = (session) => {
      console.log("select session", session);
      store.dispatch("selectSession", { session });
      store.dispatch("unselectAll");
    };

    const sessionDelete = (session) => {
      console.log("deleting session", session);
      store.dispatch("cancelSession", { game: game.value, session });
    };

    return {
      collapsible,
      sessions,
      selected,
      log,
      openTab,
      sessionSelect,
      sessionDelete,
    };
  },
};
</script>

<style lang="scss" scoped>
.toolbar {
  width: 18rem;
}

.toolbar .tabs {
  overflow-x: hidden;
}

.tab-container {
  overflow-x: hidden;
  overflow-y: auto;
  height: 90%;
}
</style>
