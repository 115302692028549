<template>
  <div ref="container">
    <div class="tabs">
      <ul>
        <li
          class="tab-link dashboard"
          @click="openTab($event, '#tab-favorites')"
        >
          <a>FAVORITES</a>
        </li>
        <li
          class="tab-link dashboard is-active"
          @click="openTab($event, '#tab-latest')"
        >
          <a>LATEST</a>
        </li>
      </ul>
    </div>
    <div class="tab-container is-full-height is-not-clipped">
      <div
        v-if="!live && showLiveInfo"
        class="notification is-warning is-light"
      >
        <span>
          Switch to
          <a @click="$store.dispatch('selectIsLive', { value: true })">
            Live Mode <Icon class="is-small" :icon="'play-circle'" />
          </a>
          to continuously get the latest Item data!</span
        >
        <Icon
          class="is-pulled-right m-1"
          :icon="'x-circle'"
          @click="showLiveInfo = false"
        />
      </div>
      <div id="tab-favorites" class="tab-content dashboard is-hidden">
        <div v-for="(chunk, index) in itemFavChuncks" :key="`itemrow_${index}`">
          <div class="tile is-ancestor">
            <div v-for="item in chunk" :key="item._id" class="tile is-parent">
              <article class="tile is-child box">
                <nav class="level mb-2">
                  <div class="level-left">
                    <div class="level-item">
                      <p class="has-text-is-live has-text-weight-semibold">
                        {{ item?.name || item?._id }}
                      </p>
                    </div>
                  </div>
                  <div class="level-right">
                    <Icon
                      class="m-1"
                      :icon="'star'"
                      :fill="item?.dashboard?.favorite ? '#796ae5' : 'none'"
                      @click.stop="toggleFavorite(item)"
                    />
                  </div>
                </nav>
                <div class="content">
                  <div>
                    Latest Update <strong>{{ item.modified_at }}</strong>
                  </div>
                  <pre>{{ item }}</pre>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div id="tab-latest" class="tab-content dashboard">
        <div
          v-for="item in itemStream"
          :key="item._id"
          class="has-background-white has-border m-2"
        >
          <Collapsible>
            <template v-slot:header>
              <nav class="level p-1 is-cursor-pointer">
                <div class="level-left">
                  <div class="level-item">
                    <p class="has-text-is-live has-text-weight-semibold">
                      {{ item?.name || item?._id }}
                    </p>
                  </div>
                  <div class="level-item">
                    <p>Latest Update {{ item.modified_at }}</p>
                  </div>
                </div>
                <div class="level-right">
                  <Icon
                    class="m-1"
                    :icon="'star'"
                    :fill="item?.dashboard?.favorite ? '#796ae5' : 'none'"
                    @click.stop="toggleFavorite(item)"
                  />
                </div>
              </nav>
            </template>
            <template v-slot>
              <pre>{{ item }}</pre>
            </template>
          </Collapsible>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { chunk } from "lodash";

import Collapsible from "@/components/Template/Collapsible.vue";

export default {
  name: "Dashboard",
  props: [],
  emits: [],
  components: { Collapsible },
  setup() {
    const store = useStore();
    const itemStream = computed(() => store.state.data.items);
    const itemFavChuncks = computed(() => {
      const favorites = itemStream.value.filter(
        (item) => item?.dashboard?.favorite
      );
      return chunk(favorites, 2);
    });
    const live = computed(() => store.state.selected.is_live);
    const showLiveInfo = ref(true);

    const container = ref(null);

    const openTab = (e, tabName) => {
      // hide everything
      const links = container.value.getElementsByClassName("tab-link");
      const tabs = container.value.getElementsByClassName("tab-content");
      Array.from(links).forEach((node) => node.classList.remove("is-active"));
      Array.from(tabs).forEach((node) => node.classList.add("is-hidden"));
      // show selection
      container.value.querySelector(tabName).classList.remove("is-hidden");
      e.currentTarget.classList.add("is-active");
    };

    const toggleFavorite = (item) => {
      const key = "dashboard";
      const value = {
        favorite: item?.dashboard?.favorite ? false : true,
        hidden_properties: item?.dashboard?.hidden_properties,
      };
      if (item.type == "plugin") {
        store.dispatch("editPluginItem", {
          item: item._id,
          plugin: item.plugin,
          plugin_collection: item.collection,
          key,
          value,
        });
      } else if (item.type == "data") {
        store.dispatch("editDocument", {
          id: item._id,
          collection: item.collection,
          key,
          value,
        });
      } else {
        console.error(
          `Dashboard: Unknown Item Type ${item.type}. Unable to set favorite.`
        );
      }
    };

    return {
      live,
      showLiveInfo,
      itemStream,
      itemFavChuncks,
      container,
      openTab,
      toggleFavorite,
    };
  },
};
</script>
