<template>
  <div class="is-flex is-flex-direction-column is-paddingless">
    <main
      id="stage"
      ref="stage"
      :class="[
        'is-flex-grow-1',
        dragOver ? 'has-background-light' : 'has-background-white',
      ]"
      @pointerdown.passive="handleStageAndStateDrags"
      @scroll="handleScroll"
      @drop.prevent="handleDrop"
      @dragover.prevent
      @dragenter="handleActionDragOverEvents($event, true)"
      @dragend="handleActionDragOverEvents($event, false)"
      @dragleave="handleActionDragOverEvents($event, false)"
    >
      <StateNode
        v-for="(state, key) in level.states"
        :key="key"
        :stateId="key"
        :style="{
          top: state.view.position.y + 'px',
          left: state.view.position.x + 'px',
        }"
        @stateUpdated="handleStateUpdate"
      />
      <div
        style="
          position: absolute;
          left: 4000px;
          top: 2000px;
          visibility: hidden;
        "
      >
        ...FIXME: this is here to make stage bigger...
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, provide, ref, watch } from "vue";
import StateNode from "./StateNode.vue";

export default {
  name: "Stage",
  components: {
    StateNode,
  },
  props: { updateLines: Number },
  setup(props) {
    const store = useStore();
    const level = computed(() => store.state.data.level);
    const selected = computed(() => store.state.selected.state);

    const lastStateUpdate = ref(null);
    const lastScroll = ref(null);

    const stage = ref(null);

    const handleScroll = (e) => (lastScroll.value = e);

    watch(() => props.updateLines, handleScroll);

    provide("globalStateUpdate", lastStateUpdate);
    provide("globalScrollEvent", lastScroll);

    onMounted(() => {});

    const handleStateUpdate = (e) => (lastStateUpdate.value = e);

    const dragOver = ref(false);

    const updateStatusAfterDrag = (e, stateId) => {
      const newX = e.target.parentNode.parentNode.offsetLeft;
      const newY = e.target.parentNode.parentNode.offsetTop;
      const oldX = level.value.states[stateId].view.position.x;
      const oldY = level.value.states[stateId].view.position.y;
      if (newX != oldX || newY != oldY) {
        store
          .dispatch("updateState", {
            key: stateId,
            value: {
              view: {
                position: {
                  x: newX,
                  y: newY,
                },
              },
            },
          })
      }
    };

    const handleDrop = (e) => {
      if (e.target.id != "stage" || !e.dataTransfer.getData("action")) return;
      dragOver.value = false;
      const action = e.dataTransfer.getData("action");
      const position = {
        x: e.layerX + e.target.scrollLeft,
        y: e.layerY + e.target.scrollTop,
      };
      store.dispatch("addState", { actionName: action, position });
    };
    const handleActionDragOverEvents = (e, isOver) => {
      if (e.target.id != "stage") return;
      dragOver.value = isOver;
    };

    const handleStageAndStateDrags = (e) => {
      let element, startX, startY, startLeft, startTop, raf;
      element = e.target;
      ////////////////////////////////////
      ///// scoped dragging functions:////
      const userPressedStateNode = () => {
        if (store.state.selected.is_live) return;
        startX = event.clientX;
        startY = event.clientY;
        startLeft = element.offsetLeft;
        startTop = element.offsetTop;

        // add listeners to stage to track drag of stateNode:
        stage.value.addEventListener("pointermove", userMovedStateNode, {
          passive: true,
        });
        stage.value.addEventListener("pointerup", userReleasedStateNode, {
          passive: true,
        });
        stage.value.addEventListener(
          "pointercancel",
          () => {
            //"pointercancel event. not caught at this moment because might not be relevant"
          },
          {
            passive: true,
          }
        );
        stage.value.addEventListener(
          "pointerleave",
          () => {
            //   "pointerleave event. not caught at this moment because might be wanted like this"
          },
          {
            passive: true,
          }
        );
      };

      const userPressedStage = () => {
        startX = event.clientX;
        startY = event.clientY;
        startLeft = element.scrollLeft;
        startTop = element.scrollTop;

        stage.value.style.cursor = "move";
        // add listeners to stage:
        stage.value.addEventListener("pointermove", userMovedStage, {
          passive: true,
        });
        stage.value.addEventListener("pointerup", userReleasedStage, {
          passive: true,
        });
        stage.value.addEventListener("pointercancel", userReleasedStage, {
          passive: true,
        });
        stage.value.addEventListener("pointerleave", userReleasedStage, {
          passive: true,
        });
      };

      const userMovedStateNode = (event) => {
        let deltaX = event.clientX - startX;
        let deltaY = event.clientY - startY;
        if (!raf) {
          raf = requestAnimationFrame(userMovedRaf);
        }
        function userMovedRaf() {
          let newx = startLeft + deltaX;
          let newy = startTop + deltaY;
          element.style.left = newx + "px";
          element.style.top = newy + "px";
          e.newposition = { x: newx, y: newy }; // what does this do now?
          lastScroll.value = e.newposition;
          raf = null;
        }
      };

      const userMovedStage = (event) => {
        let deltaX = event.clientX - startX;
        let deltaY = event.clientY - startY;
        if (!raf) {
          raf = requestAnimationFrame(userMovedRaf);
        }
        function userMovedRaf() {
          let newx = startLeft - deltaX;
          let newy = startTop - deltaY;
          element.scroll(newx, newy);
          raf = null;
        }
      };

      const userReleasedStateNode = (event) => {
        stage.value.removeEventListener("pointermove", userMovedStateNode);
        stage.value.removeEventListener("pointerup", userReleasedStateNode);
        stage.value.removeEventListener("pointercancel", userReleasedStateNode);
        stage.value.removeEventListener("pointerleave", userReleasedStateNode);

        updateStatusAfterDrag(event, element.attributes.stateId.value); // update store accordingly
        if (raf) {
          cancelAnimationFrame(raf);
          raf = null;
        }
      };

      const userReleasedStage = () => {
        stage.value.style.cursor = "default";
        stage.value.removeEventListener("pointermove", userMovedStage);
        stage.value.removeEventListener("pointerup", userReleasedStage);
        stage.value.removeEventListener("pointercancel", userReleasedStage);
        stage.value.removeEventListener("pointerleave", userReleasedStage);
        if (raf) {
          cancelAnimationFrame(raf);
          raf = null;
        }
      };
      ///// end scoped dragging functions://
      //////////////////////////////////////

      //////////////////////////////////////
      ///// main drag handling /////////////

      // check if stage or state were clicked
      if (element.classList.contains("handle")) {
        element = element.parentNode.parentNode;
        userPressedStateNode(e);
      }
      if (element.id == "stage") {
        //store.dispatch("unselectAll");
        userPressedStage(e);
      }
    };

    return {
      level,
      selected,
      handleScroll,
      handleStateUpdate,
      handleActionDragOverEvents,
      handleDrop,
      dragOver,
      stage,
      handleStageAndStateDrags,
    };
  },
};
</script>

<style lang="scss" scoped>
#stage {
  position: relative;
  overflow: auto;
  touch-action: none;
}
</style>
