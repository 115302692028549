<template>
  <div
    class="field has-addons"
    @mouseover="showEditBtn = !editMode"
    @mouseleave="showEditBtn = false"
  >
    <div class="control">
      <input
        ref="inputNode"
        list="statuses"
        name="status"
        class="input has-overflow-ellipsis is-cursor-default has-text-black"
        type="text"
        :value="selection"
        :title="selection"
        @click="switchMode('edit')"
        @change="updateValue()"
        @keyup.enter="updateValue()"
      />
      <datalist id="statuses">
        <option
          v-for="(option, index) in options"
          :key="'option' + index"
          :value="option"
        ></option>
      </datalist>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";

export default {
  name: "Datalist",
  props: { selection: String, options: Array },
  emits: ["selected"],
  setup(props, { emit }) {
    const inputNode = ref(null);
    const editMode = ref(false);

    const switchMode = (mode) => {
      if (mode == "edit") {
        editMode.value = true;
        inputNode.value.focus();
      } else {
        editMode.value = false;
        inputNode.value.blur();
      }
    };

    const updateValue = () => {
      const text = inputNode.value.value;
      if (text !== props.selection) emit("selected", text);
      switchMode("default");
    };

    return {
      inputNode,
      editMode,
      switchMode,
      updateValue,
    };
  },
};
</script>
