<template>
  <div class="is-flex is-flex-direction-column">
    <div class="is-flex-grow-1 columns is-marginless" style="height: 88%">
      <Toolbar v-if="!isLive" class="is-layer-3 is-fullheight" />
      <Stage
        v-if="level"
        class="column has-background-info"
        :updateLines="updateLines"
      />
      <Livebar
        v-if="isLive"
        :level="level?._id"
        class="is-layer-3 is-fullheight"
      />
    </div>
  </div>
</template>

<script>
import Stage from "@/components/Level/Stage.vue";
import Toolbar from "@/components/Level/Toolbar.vue";
import Livebar from "@/components/Live/LiveBar.vue";

import { watch, computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Stage,
    Toolbar,
    Livebar,
  },
  name: "LevelEditor",
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // Compute necessary Store values for Component
    const level = computed(() => store.state.data.level);
    const isLive = computed(() => store.state.selected.is_live);

    // We need to have info about level, actions, schema and media here
    const init = () => {
      const game = route.query.game;
      const level = route.query.level;

      if (!game) router.push({ path: "/" });
      else if (!level) router.push({ path: "/game", query: { game: game } });
      else store.dispatch("selectLevel", { game: game, level: level });
    };
    init();

    // Note: necessary to reload editor on dynamic query params change from router
    watch(
      () => route.query,
      () => init()
    );

    // Create local References
    const updateLines = ref(null);
    watch(isLive, () => {
      updateLines.value = new Date().getTime();
    });

    return {
      level,
      isLive,
      updateLines,
    };
  },
};
</script>
