import { createRouter, createWebHashHistory } from "vue-router";

import AdaptorView from "../views/AdaptorView.vue";
import GameView from "../views/GameView.vue";
import LevelEditor from "../views/LevelEditor.vue";
import GameSettings from "../views/GameSettings.vue";
import LevelConfig from "../views/LevelConfig.vue";
import GameDashboard from "../views/GameDashboard.vue";

const routes = [
  {
    path: "/",
    name: "AdaptorView",
    component: AdaptorView,
  },
  {
    path: "/game",
    name: "GameView",
    component: GameView,
  },
  {
    path: "/settings",
    name: "GameSettings",
    component: GameSettings,
  },
  {
    path: "/dashboard",
    name: "GameDashboard",
    component: GameDashboard,
  },
  {
    path: "/editor",
    name: "LevelEditor",
    component: LevelEditor,
  },
  {
    path: "/levelconfig",
    name: "LevelConfig",
    component: LevelConfig,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

export default router;
