import { merge } from "lodash";

export const mutations = {
  setData(state, { key, value }) {
    state["data"][key] = value;
  },
  setDataGameLevelStatus(state, { key, value }) {
    state.data.game.level[key]["status"] = value;
  },
  setSelected(state, { key, value }) {
    state["selected"][key] = value;
  },
  setLevelName(state, name) {
    state.data.level.name = name;
  },
  setPrompt(state, { message, webhook, schema, visible }) {
    console.log("setting prompt", webhook);
    state.prompt = { message, webhook, schema, visible };
    console.log(state.prompt);
  },
  updateState(state, { key, value }) {
    merge(state.data.level.states[key], value);
  },
  setState(state, { stateId, key, value }) {
    state.data.level.states[stateId][key] = value;
  },
  setCollection(state, { key, value, keys }) {
    state.data.collections[key] = value;
    if (keys) state.data.collections._keys[key] = keys;
  },
  setCollectionKeys(state, { key, value }) {
    if (!state.data.collections._keys) state.data.collections["_keys"] = {};
    state.data.collections._keys[key] = value;
  },
  updateAction(state, { key, value }) {
    state.data.level.actions[key] = value;
  },
  addActionToState(state, { stateId, actionId, mode }) {
    state.data.level.states[stateId][mode].push(actionId);
  },
  moveActionToEndOfState(state, { stateId, actionId, mode }) {
    const actions = state.data.level.states[stateId][mode];
    actions.push(actions.splice(actions.indexOf(actionId), 1)[0]);
  },
  addPluginItem(state, { plugin, collection, data }) {
    const pluignObj = state.data.plugins.find((p) => p.name == plugin);
    pluignObj.items[collection].items.unshift(data);
  },
  updatePluginItem(state, { plugin, collection, index, data }) {
    const pluignObj = state.data.plugins.find((p) => p.name == plugin);
    pluignObj.items[collection].items[index] = data;
  },
  deletePluginItem(state, { plugin, collection, index }) {
    const pluignObj = state.data.plugins.find((p) => p.name == plugin);
    pluignObj.items[collection].items.splice(index, 1);
  },
  updatePlugin(state, { name, data, overwrite }) {
    if (!overwrite) {
      merge(
        state.data.plugins.find((p) => p.name == name),
        data
      );
    } else {
      Object.assign(
        state.data.plugins.find((p) => p.name == name),
        data
      );
    }
  },
  addStateToStates(state, { stateId, position }) {
    // TODO: rework this to respect schema
    state.data.level.states = {
      ...state.data.level.states,
      [stateId]: {
        name: stateId,
        view: { position: { x: position.x, y: position.y } },
        run: [],
        listen: [],
      },
    };
  },
  updateContent(state, { contentId, value }) {
    // FIXME: add language support
    state.data.level.contents[contentId] = { de: value };
  },
  deleteState(state, stateId) {
    delete state.data.level.states[stateId];
  },
  deleteContent(state, { contentId }) {
    delete state.data.level.contents[contentId];
  },
  deleteActionFromState(state, { stateId, actionId }) {
    const stateRef = state.data.level.states[stateId];
    const runIndex = stateRef["run"].indexOf(actionId);
    const listenIndex = stateRef["listen"].indexOf(actionId);
    if (runIndex >= 0) {
      stateRef["run"].splice(runIndex, 1);
    } else if (listenIndex >= 0) {
      stateRef["listen"].splice(listenIndex, 1);
    }
  },
  addLogMessages(state, { data, maximum = 50 }) {
    const combined = state.data.serverlog.concat(data);
    if (combined.length > maximum)
      combined.splice(0, combined.length - maximum);
    state.data.serverlog = combined;
  },
  setLiveItems(state, items){
    state.data.items = items;
  },
  removeLiveItems(state, items) {
    items.forEach((id) => {
      const index = state.data.items.findIndex((s) => s?._id === id);
      state.data.items.splice(index, index >= 0 ? 1 : 0);
    });
  },
  setItem(state, { id, key, value }) {
    const item = state.data.items.find((i) => i?._id === id);
    item[key] = value;
  },
  updateLiveSessions(state, sessions) {
    sessions.forEach((session) => {
      let index = state.data.sessions.findIndex((s) => s?._id === session._id);
      if (index >= 0) {
        state.data.sessions[index] = session;
      } else {
        state.data.sessions.push(session);
      }
    });
  },
  removeLiveSessions(state, sessions) {
    sessions.forEach((id) => {
      const index = state.data.sessions.findIndex((s) => s?._id === id);
      state.data.sessions.splice(index, index >= 0 ? 1 : 0);
    });
  },
};
