import * as Helpers from "@/services/helpers";
import { nanoid } from "nanoid";
import { cloneDeep, clone, union } from "lodash";
import { useToast } from "vue-toastification";

const toast = useToast();

export const actions = {
  //////////////////////////////////////////////////////////////////////////////
  // COMPILATIONS
  //////////////////////////////////////////////////////////////////////////////
  selectGame({ commit, dispatch }, game) {
    commit("setSelected", { key: "game", value: game });
    dispatch("getGame", game);
    dispatch("findPlugins", game);
    commit("setData", { key: "sessions", value: [] });
    commit("setData", { key: "items", value: [] });
  },
  async selectLevel({ commit, state, dispatch }, { game, level }) {
    const [level_data, actions, level_schema, level_variables, media] =
      await Promise.all([
        this.$api.getLevel({ game, level }).then((r) => r.data),
        this.$api.getActions(game).then((r) => r.data),
        this.$api.getLevelSchema(game).then((r) => r.data),
        this.$api.getLevelVariables({ game, level }).then((r) => r.data),
        this.$api.getMedia({ game }).then((r) => r.data), // CONTINUE HERE
      ]).catch(console.error);

    commit("setData", { key: "level", value: level_data });
    commit("setData", { key: "actions", value: actions });
    commit("setData", { key: "level_schema", value: level_schema });
    commit("setData", { key: "level_variables", value: level_variables });
    commit("setData", { key: "media", value: media });

    commit("setSelected", { key: "level", value: level });
    commit("setSelected", { key: "level_id", value: level_data._id });
    commit("setSelected", { key: "game", value: game });
    if (!state.data.game) dispatch("getGame", game);
  },

  //////////////////////////////////////////////////////////////////////////////
  // CLIENT AND UI
  //////////////////////////////////////////////////////////////////////////////
  selectIsLive({ commit }, { value }) {
    commit("setSelected", { key: "is_live", value });
  },
  selectAction({ state, commit }, { action, stateId }) {
    action = state["selected"]["action"] != action ? action : null;
    commit("setSelected", { key: "action", value: action });
    if (state["selected"]["state"] != stateId)
      commit("setSelected", { key: "state", value: stateId });
  },
  selectState({ state, commit, dispatch }, { stateId, trigger }) {
    if (state["selected"]["state"] != stateId) {
      commit("setSelected", { key: "action", value: null });
      commit("setSelected", { key: "state", value: stateId });
    }
    /*console.log(
      `is_live: ${state["selected"]["is_live"]} session: ${state["selected"]["session"]} trigger: ${trigger}`
    );*/
    // Note: SOCKET EXPERIMENT moved this logic from LiveBar component in store
    if (state["selected"]["is_live"] && state["selected"]["session"] && trigger)
      // NOTE: SOCKET EXPERIMENT maybe this is a mode the client is in and should be a key in store
      dispatch("next", { stateId });
  },
  unselectAll({ state, commit }) {
    if (state["selected"]["action"] != null)
      commit("setSelected", { key: "action", value: null });
    if (state["selected"]["state"] != null)
      commit("setSelected", { key: "state", value: null });
  },
  triggerPrompt({ commit }, { message, webhook, schema }) {
    commit("setPrompt", { message, webhook, schema, visible: true });
  },
  cancelPrompt({ commit }) {
    commit("setPrompt", {
      message: null,
      webhook: null,
      schema: null,
      visible: false,
    });
  },
  async answerPrompt({ dispatch, state }, answer) {
    console.log("answer", answer);
    await this.$api
      .post(this.$API_URL + state.prompt.webhook, answer)
      .catch(console.error);
    dispatch("cancelPrompt", state);
  },
  async toggleServerLog({ commit, dispatch, state }) {
    const isVisible = state.selected.serverlogVisible;
    dispatch(isVisible ? "logDisconnect" : "logConnect");
    commit("setSelected", { key: "serverlogVisible", value: !isVisible });
  },

  //////////////////////////////////////////////////////////////////////////////
  // LIVE CONTROL SESSIONS
  //////////////////////////////////////////////////////////////////////////////
  async findSessions({ commit }, { game, level }) {
    const parameters = { game };
    if (level) parameters.level_name = level;
    this.$api
      .findSessions(parameters)
      .then((r) => commit("setData", { key: "sessions", value: r.data }))
      .catch((e) => {
        // FIXME: returns 404 when no sessions available. better would be: returning empty data
        if (e?.response?.status == 404)
          commit("setData", { key: "sessions", value: null });
      });
  },
  async launchSession({ dispatch }, { game, level, session, args }) {
    if (!session) session = "session_" + nanoid(8);
    this.$api
      .launchSession({ game }, { level, name: session, arguments: args })
      .then((res) => {
        dispatch("findSessions", { game });
        dispatch("selectSession", { session: res.data.created_id });
      })
      .catch((e) => {
        if (e.response.status == 400 && e?.response?.data?.message)
          toast.error(`Could not launch Session:\n${e.response.data.message}`);
        else toast.error(`Could not launch Session.`);
      });
  },
  async cancelSession({ dispatch }, { game, session }) {
    // const level = state["selected"]["level"];
    this.$api
      .cancelSession({ game, session })
      .then(() => {
        dispatch("findSessions", { game });
      })
      .catch(console.error);
  },
  next({ dispatch, state }, { stateId }) {
    const game = state["selected"]["game"];
    // const level = state["selected"]["level"];
    const session = state["selected"]["session"];
    // NOTE: SOCKET EXPERIMENT should we default to use socket connection if there is one to trigger next!?
    this.$api
      .next({ game, session }, { id: stateId })
      .then(() => {
        dispatch("findSessions", { game });
      })
      .catch(console.error);
  },
  selectSession({ commit }, { session }) {
    commit("setSelected", { key: "session", value: session });
  },
  levelConnect({ state, dispatch }) {
    dispatch("levelDisconnect");
    const game = state["selected"]["game"];
    const level_id = state["selected"]["level_id"];
    this.$socket.levelConnect(game, level_id, {
      connect_error: (e) => {
        console.log(e);
      },
    });
  },
  levelDisconnect() {
    this.$socket.levelDisconnect();
  },
  findLiveItems({ state, commit }, query) {
    const game = state["selected"]["game"];
    this.$api
      .findItems({ game, ...query })
      .then((res) => {
        const items = cloneDeep(state.data.items);
        for (let n = res.data.length - 1; n >= 0; n--) {
          const item = res.data[n];
          const index = items.findIndex((i) => i?._id === item._id);
          if (index >= 0) {
            items.splice(index, 1);
          }
          items.unshift(item);
        }
        commit("setLiveItems", items);
      })
      .catch(console.error);
  },
  liveItemsConnect({ state, dispatch, commit }) {
    dispatch("liveItemsDisconnect");
    const game = state["selected"]["game"];
    this.$socket.liveItemsConnect(game, {
      changed: (data) => {
        const items = cloneDeep(state.data.items);
        for (let n = data.length - 1; n >= 0; n--) {
          const item = data[n];
          const index = items.findIndex((i) => i?._id === item._id);
          if (index >= 0) {
            items.splice(index, 1);
          }
          items.unshift(item);
        }
        commit("setLiveItems", items);
      },
      removed: (data) => commit("removeLiveItems", data),
      connect_error: (e) => console.error(e),
    });
  },
  liveItemsDisconnect() {
    this.$socket.liveItemsDisconnect();
  },
  gameConnect({ state, dispatch }) {
    dispatch("gameDisconnect");
    const game = state["selected"]["game"];
    this.$socket.gameConnect(game, {
      session: () => {
        dispatch("findSessions", { game }); // level can be null if UI is in_live but no level in focus
      },
      connect: () => console.log("connected to game"),
    });
  },
  gameDisconnect() {
    this.$socket.gameDisconnect();
    // toast.success("game disconnecting", { timeout: 1500 });
  },
  liveSessionsConnect({ state, commit }) {
    const game = state["selected"]["game"];
    this.$socket.liveSessionsConnect(game, {
      changed: (data) => {
        const items = cloneDeep(state.data.items);
        for (let n = data.length - 1; n >= 0; n--) {
          const item = data[n];
          const index = items.findIndex((i) => i?._id === item._id);
          if (index >= 0) {
            items.splice(index, 1);
          }
          items.unshift(item);
        }
        commit("updateLiveSessions", items);
      },
      removed: (data) => commit("removeLiveSessions", data),
      connect_error: (e) => console.error(e),
    });
  },
  liveSessionsDisconnect({ commit }) {
    commit("setData", { key: "sessions", value: null });
    this.$socket.liveSessionsDisconnect();
  },

  //////////////////////////////////////////////////////////////////////////////
  // ACTION TO TEST AND LOG STUFF SHOULD BE REMOVED IN PRODUCTION
  //////////////////////////////////////////////////////////////////////////////
  dev_log_action(store, { msg, data }) {
    console.log(msg, data);
  },

  //////////////////////////////////////////////////////////////////////////////
  // ADAPTOR AND GAME
  //////////////////////////////////////////////////////////////////////////////
  logConnect({ commit, dispatch }, level = "debug") {
    dispatch("logDisconnect").then(
      this.$socket.logConnect(level, {
        // TODO: set maximum of log entries elsewhere
        log: (data) => {
          // console.log(data);
          commit("addLogMessages", { data, maximum: 100 });
        },
        connect_error: (e) => console.error(e),
      })
    );
  },
  logDisconnect({ commit }) {
    commit("setData", { key: "serverlog", value: [] });
    this.$socket.logDisconnect();
  },
  getGames({ commit }) {
    this.$api
      .getGames()
      .then((r) => commit("setData", { key: "game_list", value: r.data }))
      .catch(console.error);
  },
  async getPlugins({ commit }) {
    this.$api
      .getPlugins()
      .then((r) => commit("setData", { key: "plugins_list", value: r.data }))
      .catch(console.error);
  },
  getGame({ commit }, game) {
    this.$api
      .getGame(game)
      .then((r) => commit("setData", { key: "game", value: r.data }))
      .catch(console.error);
  },
  createGame({ dispatch, getters }, config) {
    const name = config.name;
    if (getters.gamesNameList.includes(name)) {
      toast.error(
        "Already assigned Game Name: " +
          name +
          "\n Please name your game differently."
      );
      return Promise.reject(new Error("duplicate game name: " + name));
    } else if (!Helpers.hasOnlyAllowedCharacters(name)) {
      toast.error(
        "Forbidden characters in Game Name: " +
          name +
          "\nPlease use only the letters A-Z, numbers or -._~:# and no spaces"
      );
      return Promise.reject(new Error("forbbiden characters in name: " + name));
    } else {
      this.$api
        .createGame(null, { name: config.name, template: "multiplayer" })
        .then(() => dispatch("getGames"))
        .catch(console.error);
    }
  },
  deleteGame({ dispatch }, config) {
    this.$api
      .deleteGame({ game: config.name })
      .then(() => dispatch("getGames"))
      .catch(console.error);
  },
  createLevel({ state, getters, dispatch }, { level }) {
    const game = state.selected.game;
    const name = level.name;
    if (getters.levelNameList.includes(name)) {
      toast.error(
        "Already assigned Level Name: " +
          name +
          "\n Please name your level differently."
      );
      return Promise.reject(new Error("duplicate game name: " + name));
    } else if (!Helpers.hasOnlyAllowedCharacters(name) || name == undefined) {
      toast.error(
        "Forbidden characters in Level Name: " +
          name +
          "\nPlease use only the letters A-Z, numbers or -._~:# and no spaces"
      );
      return Promise.reject(new Error("forbbiden characters in name: " + name));
    } else {
      this.$api
        .createLevel({ game }, level)
        .then(() => dispatch("selectGame", state.data.game.name))
        .catch(console.error);
    }
  },
  deleteLevel({ state, dispatch }, config) {
    this.$api
      .deleteLevel({ game: config.game, level: config.level })
      .then(() => dispatch("selectGame", state.data.game.name))
      .catch(console.error);
  },
  editLevelStatus({ state, dispatch }, { level, status }) {
    const game = state["selected"]["game"];
    this.$api
      .editLevel({ game, level, operator: "_set" }, { "config.status": status })
      .then(() => dispatch("selectGame", game))
      .catch(console.error);
  },
  async findDocuments({ commit, state }, collection) {
    const game = state.selected.game;
    return this.$api
      .findDocuments({ game, collection })
      .then((r) => {
        let keys = [];
        r.data.forEach((document) => keys.push(Object.keys(document)));
        commit("setCollection", {
          key: collection,
          value: r.data,
          keys: union(...keys),
        });
      })
      .catch(console.error);
  },
  deleteDocument({ dispatch, state }, { collection, document }) {
    const game = state.selected.game;
    return this.$api
      .deleteDocument({ game, collection, document })
      .then(() => dispatch("findDocuments", collection))
      .catch(console.error);
  },
  createDocument({ dispatch, state }, { collection, document }) {
    const game = state.selected.game;
    if (typeof document === "string") {
      try {
        document = JSON.parse(document);
      } catch (error) {
        toast.error("Can not convert to JSON:\n" + error);
        return Promise.reject(error);
      }
    }
    return this.$api
      .createDocument({ game, collection }, document)
      .then(() => dispatch("findDocuments", collection))
      .catch(console.error);
  },
  updateDocument({ dispatch, state }, { collection, document }) {
    const game = state.selected.game;
    if (typeof document === "string") {
      try {
        document = JSON.parse(document);
      } catch (error) {
        toast.error("Can not convert to JSON:\n" + error);
        return Promise.reject(error);
      }
    }
    return this.$api
      .updateDocument({ game, collection, document: document._id }, document)
      .then(() => dispatch("findDocuments", collection))
      .catch(console.error);
  },
  createCollection({ dispatch, state }, { name }) {
    const game = state.selected.game;
    return this.$api
      .createCollection({ game }, { name })
      .then(() => dispatch("selectGame", game)) // TODO: is there an endpoint to get collection lists
      .catch(console.error);
  },
  deleteCollection({ dispatch, state }, { collection }) {
    const game = state.selected.game;
    this.$api
      .deleteCollection({ game, collection })
      .then(() => dispatch("selectGame", game)) // TODO: is there an endpoint to get collection lists
      .catch(console.error);
  },
  findPlugins({ commit, state }, game) {
    if (!game) game = state["selected"]["game"];
    this.$api
      .findPlugins({ game })
      .then((r) => commit("setData", { key: "plugins", value: r.data }))
      .catch(console.error);
  },
  addPlugin({ state, dispatch }, { plugin }) {
    const game = state["selected"]["game"];
    this.$api
      .addPlugin({ game }, { name: plugin })
      .then(() => {
        dispatch("getGame", game); // TODO: is this necessary!?
        dispatch("findPlugins");
      })
      .catch(console.error);
  },
  removePlugin({ state, dispatch }, { plugin }) {
    const game = state["selected"]["game"];
    this.$api
      .removePlugin({ game, plugin })
      .then(() => {
        dispatch("getGame", game); // TODO: is this necessary!?
        dispatch("findPlugins");
      })
      .catch(console.error);
  },
  loadPlugin({ state, commit }, { plugin }) {
    const game = state["selected"]["game"];
    this.$api
      .loadPlugin({ game, plugin })
      .then((res) => {
        console.log("loadPlugin result:", res, res.data);

        commit("updatePlugin", {
          name: plugin,
          data: res.data,
          overwrite: true,
        }); // like this!
        console.log("new plugin data", state.data.plugins);
        //commit("setData", { key: "plugins", value: r.data });
      })
      .catch(console.error);
  },
  connectPlugin({ state, dispatch }, { plugin }) {
    const game = state["selected"]["game"];
    this.$api
      .connectPlugin({ game, plugin }) // TODO: add settings if you like
      .then((res) => {
        console.log("connectPlugin result:", res);
        dispatch("loadPlugin", { plugin });
      })
      .catch(console.error);
  },
  disconnectPlugin({ state, dispatch }, { plugin }) {
    const game = state["selected"]["game"];
    this.$api
      .disconnectPlugin({ game, plugin })
      .then((res) => {
        console.log("disconnectPlugin result:", res);
        dispatch("loadPlugin", { plugin });
      })
      .catch(console.error);
  },
  addItemToPlugin({ commit }, { plugin, collection, data }) {
    if (!data) data = { name: `${collection}_${nanoid(5)}` };
    data.is_not_synced = true;
    commit("addPluginItem", { plugin, collection, data });
  },
  updatePluginItem({ commit }, { plugin, collection, index, data }) {
    commit("updatePluginItem", { plugin, collection, index, data });
  },
  updatePlugin({ commit }, { name, data }) {
    commit("updatePlugin", { name, data });
  },
  syncPluginSettings({ state }, { plugin }) {
    const game = state["selected"]["game"];
    const data = state.data.plugins.find((p) => p.name == plugin);

    this.$api
      .updatePluginSettings({ game, plugin }, data.settings.data)
      .catch(console.error);
  },
  syncPluginItem({ state, commit }, { plugin, collection, index }) {
    const game = state["selected"]["game"];
    const obj = state.data.plugins.find((p) => p.name == plugin);
    const data = obj.items[collection].items[index];
    if (data.is_not_synced) {
      const dataTemp = cloneDeep(data);
      delete dataTemp.is_not_synced;
      return this.$api
        .createPluginItem(
          { game, plugin, plugin_collection: collection },
          dataTemp
        )
        .then((res) =>
          commit("updatePluginItem", {
            plugin,
            collection,
            index: index,
            data: Object.assign(dataTemp, { _id: res.data.created_id }),
          })
        )
        .catch(console.error);
    } else {
      return this.$api
        .editPluginItem(
          { game, plugin, plugin_collection: collection, item: data._id },
          data
        )
        .catch(console.error);
    }
  },
  deletePluginItem({ state, commit }, { plugin, collection, index }) {
    const game = state["selected"]["game"];
    const pluginObj = state.data.plugins.find((p) => p.name == plugin);
    const itemObj = pluginObj.items[collection].items[index];
    const itemIdentifier = itemObj["name"];
    if (itemObj?.is_not_synced) {
      commit("deletePluginItem", { plugin, collection, index });
    } else {
      this.$api
        .deletePluginItem({
          game,
          plugin,
          plugin_collection: collection,
          item: itemIdentifier,
        })
        .then(() => {
          commit("deletePluginItem", { plugin, collection, index });
        })
        .catch(console.error);
    }
  },
  connectPluginItem({ state, dispatch }, { plugin, collection, index }) {
    const game = state["selected"]["game"];
    const obj = state.data.plugins.find((p) => p.name == plugin);
    const data = obj.items[collection].items[index];
    this.$api
      .connectPluginItem({
        game,
        plugin,
        plugin_collection: collection,
        item: data._id,
      })
      .then((r) => {
        if (r?.data?.message) {
          const { message, webhook, schema } = r.data;
          dispatch("triggerPrompt", { message, webhook, schema });
        } else {
          dispatch("loadPlugin", { plugin });
        }
      })
      .catch(console.error);
  },
  disconnectPluginItem({ state, dispatch }, { plugin, collection, index }) {
    const game = state["selected"]["game"];
    const obj = state.data.plugins.find((p) => p.name == plugin);
    const data = obj.items[collection].items[index];
    this.$api
      .disconnectPluginItem({
        game,
        plugin,
        plugin_collection: collection,
        item: data._id,
      })
      .then(() => {
        dispatch("loadPlugin", { plugin });
      })
      .catch(console.error);
  },
  loadPluginItem({ state, commit }, { plugin, collection, index }) {
    const game = state["selected"]["game"];
    const obj = state.data.plugins.find((p) => p.name == plugin);
    const data = obj.items[collection].items[index];
    this.$api
      .loadPluginItem({
        game,
        plugin,
        plugin_collection: collection,
        item: data._id,
      })
      .then((r) => {
        commit("updatePluginItem", {
          plugin,
          collection,
          data: r.data?.items[collection]?.items[index] || {},
          index,
        });
      })
      .catch(console.error);
  },

  //////////////////////////////////////////////////////////////////////////////
  // LEVEL EDITOR
  //////////////////////////////////////////////////////////////////////////////
  editLevel({ state, dispatch }, { operator, query }) {
    // console.log(query);
    const game = state["selected"]["game"];
    const level = state["selected"]["level"];
    this.$api
      .editLevel({ game, level, operator }, query)
      .then(() => dispatch("selectLevel", { game, level }))
      .catch(console.error);
  },
  addState({ state, commit, dispatch }, { actionName, position }) {
    const stateId = "_state_" + nanoid(8);
    commit("addStateToStates", { stateId, position });
    // Note: add next action on new state if there is none
    if (actionName != "next" && state.settings.add_next)
      dispatch("addActionToState", { stateId, actionName: "next" });
    dispatch("addActionToState", { stateId, actionName });
  },
  updateState({ commit, dispatch }, { key, value }) {
    commit("updateState", { key, value });
    dispatch("syncState", { stateId: key });
  },
  deleteStateComment({ state, commit, dispatch }, { stateId, index }) {
    const comments = clone(state.data.level.states[stateId]?.comments || []);
    if (comments[index] !== undefined) {
      comments.splice(index, 1);
      commit("setState", { stateId, key: "comments", value: comments });
      dispatch("syncState", { stateId });
    }
  },
  updateStateComment({ state, commit, dispatch }, { stateId, index, comment }) {
    const comments = clone(state.data.level.states[stateId]?.comments || []);
    if (index < 0) comments.push(comment);
    else comments[index] = comment;
    commit("updateState", { key: stateId, value: { comments } });
    dispatch("syncState", { stateId });
  },
  editPluginItem(
    { state, commit },
    { item, plugin, plugin_collection, key, value }
  ) {
    const game = state["selected"]["game"];
    console.log({ item, plugin, plugin_collection, key, value });
    this.$api
      .editPluginItem(
        { game, plugin, plugin_collection, item, operator: "_set" },
        { [key]: value }
      )
      .then(commit("setItem", { id: item, key, value }))
      .catch(console.error);
  },
  editDocument({ state, commit }, { id, collection, key, value }) {
    const game = state["selected"]["game"];
    this.$api
      .editDocument(
        { game, collection, document: id, operator: "_set" },
        { [key]: value }
      )
      .then(commit("setItem", { id, key, value }))
      .catch(console.error);
  },
  updateStateName({ commit, getters, dispatch }, { stateId, name }) {
    if (getters.statesNameList.includes(name)) {
      toast.error(
        "Already assigned State Name: " +
          name +
          "\n Please name the state differently."
      );
      return Promise.reject(new Error("duplicate state name: " + name));
    } else if (!Helpers.hasOnlyAllowedCharacters(name)) {
      toast.error(
        "Forbidden characters in State Name: " +
          name +
          "\nPlease use only the letters A-Z, numbers or -._~:# and no spaces"
      );
      return Promise.reject(new Error("forbbiden characters in name: " + name));
    } else {
      commit("updateState", { key: stateId, value: { name } });
      dispatch("syncState", { stateId });
    }
  },
  syncState({ state, getters }, { stateId }) {
    const game = state["selected"]["game"];
    const level = state["data"]["level"]["_id"];
    const payload = {
      states: { [stateId]: state["data"]["level"]["states"][stateId] },
      actions: getters.actionsByState(stateId),
      contents: getters.contentsByState(stateId), // TODO: this seems very expensive to do every time!!!
    };
    this.$api.createState({ game, level }, payload).then().catch(console.error);
  },
  deleteState({ state, commit }, stateId) {
    const game = state["selected"]["game"];
    const level = state["data"]["level"]["_id"];
    this.$api
      .deleteState({ game, level, state: stateId })
      .then(() => commit("deleteState", stateId))
      .catch(console.error);
  },
  addActionToState(
    { state, getters, dispatch, commit },
    { stateId, actionName }
  ) {
    // TODO getter based on new actions list format
    const actionSchema = state["data"]["actions"].find(
      (a) => a.action === actionName
    );
    const actionId = actionSchema.action + "_" + nanoid(10);

    // Note: handle next action in state
    const nextsInState = Object.entries(
      getters.actionsByActionTypeInState(stateId, "next")
    );
    const listener = Object.entries(
      state["data"]["level"]["states"][stateId]["listen"]
    );
    if (nextsInState.length > 0 && actionName == "next") {
      toast.error(
        `Could not add Next Action to State.\nOnly one Next possible.`
      );
      return;
    }
    if (listener.length > 0 && actionName == "next") {
      toast.error(
        `Can not add Next Action to State.\nIt is not possible to add Next in States when Listeners are present.\nMaybe you want to use the Next property of the Listener.`
      );
      return;
    }
    if (nextsInState.length > 0 && actionSchema.mode == "listen") {
      toast.info(
        `Removed Next Action from State ${stateId},\nbecause a Listener was added.`
      );
      nextsInState.forEach((next) => {
        dispatch("deleteAction", { actionId: next[0], stateId });
      });
    }

    // finally add new action to state
    commit("addActionToState", { stateId, actionId, mode: actionSchema.mode });
    // move next action to bottom of list
    if (nextsInState.length > 0 && actionSchema.mode == "run")
      nextsInState.forEach((next) => {
        commit("moveActionToEndOfState", {
          stateId,
          actionId: next[0],
          mode: "run",
        });
      });
    // TODO render empty dataset based on schema
    commit("updateAction", {
      key: actionId,
      value: {
        action: actionSchema.action,
        plugin: actionSchema.plugin,
        mode: actionSchema.mode,
        name: `${actionSchema.action}_${
          getters.sumActionsInState(stateId, actionSchema.action) + 1
        }`,
        payload: Helpers.objectFromSchema(
          actionSchema.schema.properties.payload
        ),
      },
    });
    dispatch("syncState", { stateId });
  },
  updateAction({ getters, commit, dispatch }, { actionId, value, stateId }) {
    // FIXME: use adaptorContent flag from schema
    // TODO: only update if something changed

    // Note: to reuse existing action content ids we create a pool that gets used before new ones are created
    const existingIds = getters.contentIdsByAction(actionId);
    const contentKeys = [
      "text",
      "respond",
      "caption",
      "contains",
      "regex",
      "equals",
      "value",
      "play",
      "say",
      "flow",
      "file",
    ];
    const deepIterate = (obj) => {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          deepIterate(obj[key]);
        }
        // if content is in array of strings
        else if (
          contentKeys.includes(key) &&
          Array.isArray(obj[key]) &&
          obj[key].every((i) => typeof i !== "object")
        ) {
          for (const index in obj[key]) {
            const contentId = existingIds.shift() || "_content_" + nanoid(10);
            const contentValue = obj[key][index];
            obj[key][index] = contentId;
            commit("updateContent", { contentId, value: contentValue });
          }
        }
        // if content is in single strings
        else if (contentKeys.includes(key) && typeof obj[key] !== "object") {
          const contentId = existingIds.shift() || "_content_" + nanoid(10);
          const contentValue = obj[key];
          obj[key] = contentId;
          commit("updateContent", { contentId, value: contentValue });
        }
      }
      return obj;
    };
    value = deepIterate(cloneDeep(value)); // BUG: we need to clone this, but WHY?!?
    commit("updateAction", { key: actionId, value });
    if (existingIds.length >= 1)
      dispatch("deleteContents", { contentIds: existingIds }); // delete remaining contentIds
    dispatch("syncState", { stateId });
  },
  deleteAction({ dispatch, getters, commit }, { actionId, stateId }) {
    dispatch("deleteContents", {
      contentIds: getters.contentIdsByAction(actionId),
    });
    commit("deleteActionFromState", { stateId, actionId });
    dispatch("syncState", { stateId });
  },
  deleteContents({ commit }, { contentIds }) {
    contentIds.forEach((contentId) => {
      commit("deleteContent", { contentId });
    });
  },
};
