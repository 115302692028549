<template>
  <div
    v-if="level"
    class="has-background-purple-5 p-5 is-not-clipped is-full-height"
  >
    <p class="title is-4 is-uppercase">level Config</p>
    <p class="subtitle is-6">{{ level }}</p>
    <br />
    <p class="title is-4 is-uppercase">
      Attributes
      <Icon
        class="has-text-dark"
        :icon="'help-circle'"
        :tooltip="'Attributes can be used as simple variables in the level, or are displayed at certain places (e.g. the Status of the level in the Level Overview).'"
        :interactive="false"
      />
    </p>
    <div class="table-container">
      <table class="table has-background-transparent is-bordered">
        <thead>
          <tr>
            <th class="has-background-white" style="width: 20ch">NAME</th>
            <th class="has-background-white" style="width: 55ch">VALUE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attribute, index) in attrArr" :key="`attr_${index}`">
            <td class="has-background-white p-0">
              <div class="control">
                <input
                  class="input has-overflow-ellipsis is-cursor-default has-text-black"
                  type="text"
                  v-model="attribute[0]"
                  :disabled="unlocked != `attr_${index}`"
                />
              </div>
            </td>
            <td class="has-background-white p-0">
              <div class="control">
                <input
                  class="input has-overflow-ellipsis is-cursor-default has-text-black"
                  type="text"
                  v-model="attribute[1]"
                  :disabled="unlocked != `attr_${index}`"
                />
              </div>
            </td>
            <div>
              <Icon
                v-if="unlocked == `attr_${index}`"
                :icon="'check-square'"
                class="m-3"
                @click.stop="updateConfig()"
              />
              <Icon
                v-else
                :icon="'edit'"
                class="m-3"
                @click.stop="unlocked = `attr_${index}`"
              />
              <Icon
                :icon="'trash-2'"
                @click.stop="deleteProperty(attribute[0])"
              />
            </div>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <button
        class="button is-link"
        @click="
          attrArr.push([`attribute${attrArr.length - 1}`, '-']);
          unlocked = `attr_${attrArr.length - 1}`;
          unsaved = true;
        "
        :disabled="unsaved"
      >
        ADD NEW
      </button>
    </div>
    <p
      class="title is-4 is-uppercase mt-6"
      title="Define arguments that can be passed at the start of the level and/or that are available with default values as variables in the level."
    >
      Arguments
      <Icon
        class="has-text-dark"
        :icon="'help-circle'"
        :tooltip="'NAME: variable name that is referenced in the level. COLLECTION: name of the collection from which the value is searched. QUERY: {MongoDB Query}. Arguments are values that are passed at the start of the level or are retrieved from the database.'"
        :interactive="false"
      />
    </p>
    <p class="subtitle is-6"></p>
    <br />
    <div class="table-container">
      <table class="table has-background-transparent is-bordered">
        <thead>
          <tr>
            <th class="has-background-white" style="width: 20ch">NAME</th>
            <th class="has-background-white" style="width: 20ch">COLLECTION</th>
            <th class="has-background-white" style="width: 40ch">
              DEFAULT QUERY
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(argument, index) in argsArr" :key="`args_${index}`">
            <td class="has-background-white p-0">
              <div class="control">
                <input
                  class="input has-overflow-ellipsis is-cursor-default has-text-black"
                  type="text"
                  v-model="argument[0]"
                  :disabled="unlocked != `args_${index}`"
                />
              </div>
            </td>
            <td class="has-background-white p-0">
              <div class="control">
                <input
                  class="input has-overflow-ellipsis is-cursor-default has-text-black"
                  type="text"
                  v-model="argument[1]"
                  :disabled="unlocked != `args_${index}`"
                />
              </div>
            </td>
            <td class="has-background-white p-0">
              <div class="control">
                <input
                  class="input has-overflow-ellipsis is-cursor-default has-text-black"
                  type="text"
                  v-model="argument[2]"
                  :disabled="unlocked != `args_${index}`"
                />
              </div>
            </td>
            <div>
              <Icon
                v-if="unlocked == `args_${index}`"
                :icon="'check-square'"
                class="m-3"
                @click.stop="updateConfig()"
              />
              <Icon
                v-else
                :icon="'edit'"
                class="m-3"
                @click.stop="unlocked = `args_${index}`"
              />
              <Icon
                :icon="'trash-2'"
                @click.stop="deleteProperty(`arguments.${argument[0]}`)"
              />
            </div>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <button
        class="button is-link"
        @click="
          argsArr.push([`argument${argsArr.length - 1}`, ,]);
          unlocked = `args_${argsArr.length - 1}`;
          unsaved = true;
        "
        :disabled="unsaved"
      >
        ADD NEW
      </button>
    </div>
    <hr />
    <Collapsible>
      <template v-slot:header>
        <div class="is-flex is-flex-direction-row is-align-items-center">
          <div>Raw Config</div>
          <Icon :icon="'chevron-right'" class="m-4" />
        </div>
      </template>
      <template v-slot>
        <pre class="is-size-7">{{ JSON.stringify(config, null, 4) }}</pre>
      </template>
    </Collapsible>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Collapsible from "@/components/Template/Collapsible.vue";

export default {
  name: "LevelConfig",
  components: { Collapsible },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // We need to have info about game and its plugins here
    if (!route.query.game) router.push({ path: "/" });
    else if (!route.query.level)
      router.push({ path: "/game", query: { game: route.query.game } });
    else
      store.dispatch("selectLevel", {
        game: route.query.game,
        level: route.query.level,
      });

    // Compute necessary Store values for Component
    const game = computed(() => store.state.data.game);
    const level = computed(() => store.state.data.level?.name);
    const config = computed(() => store.state.data.level.config);
    const attrArr = computed(() =>
      Object.entries(config.value).filter(([k]) => k != "arguments")
    );
    const argsArr = computed(() =>
      Object.entries(config.value.arguments).map(([k, v]) => {
        return [k, v["collection"], v["query"]];
      })
    );

    // Create local References
    const unlocked = ref(undefined);
    const unsaved = ref(false);

    const deleteProperty = (key) => {
      let query = { [`config.${key}`]: "" };
      store.dispatch("editLevel", { operator: "_remove", query });
      unsaved.value = false;
    };

    const updateConfig = () => {
      let obj = attrArr.value.reduce((acc, [k, v]) => ((acc[k] = v), acc), {});
      obj["arguments"] = argsArr.value.reduce(
        (acc, [k, c, q]) => ((acc[k] = { collection: c, query: q }), acc),
        {}
      );
      store.dispatch("editLevel", { operator: "_set", query: { config: obj } });
      unlocked.value = undefined;
      unsaved.value = false;
    };

    return {
      game,
      level,
      config,
      attrArr,
      argsArr,
      unlocked,
      unsaved,
      deleteProperty,
      updateConfig,
    };
  },
};
</script>
