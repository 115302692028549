<template>
  <Modal
    v-if="prompt.visible"
    :modal="prompt"
    @closeModal="$store.dispatch('cancelPrompt')"
    :cssClasses="{
      message: true,
      'is-danger': true,
    }"
  >
    <template v-slot:header>PROMPT HEADER</template>
    {{ prompt.message || "no message" }}
    <JsonEditor
      v-if="prompt.schema"
      :schema="prompt.schema"
      :data="{}"
      @dataChanged="answer = $event"
    />
    <template v-slot:footer>
      <button class="button" @click="$store.dispatch('answerPrompt', answer)">
        SEND
      </button>
      <button class="button" @click="$store.dispatch('cancelPrompt')">
        CANCEL
      </button>
    </template>
  </Modal>
</template>

<script>
import JsonEditor from "@/components//Form/JsonEditor.vue";
import Modal from "@/components/Template/Modal.vue";

import { useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";

export default {
  name: "Alert",
  props: [""],
  // emits: ["confirm", "cancel"],
  components: { Modal, JsonEditor },
  setup() {
    const store = useStore();
    const prompt = computed(() => store.state.prompt);

    const answer = ref(null);

    // console.log(prompt.value);

    return { prompt, answer };
  },
};
</script>
