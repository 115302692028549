import { cloneDeep } from "lodash";
import * as Helpers from "@/services/helpers";

export const getters = {
  gamesNameList: (state) => {
    return Object.values(state.data.game_list).reduce((accu, curr) => {
      accu.push(curr.name);
      return accu;
    }, []);
  },
  levelNameList: (state) => {
    return Object.values(state.data.game.level).reduce((accu, curr) => {
      accu.push(curr.name);
      return accu;
    }, []);
  },
  levelArguments: (state) => (name) => {
    const level = state.data.game.level.find((l) => l.name === name);
    return cloneDeep(level.config.arguments);
  },
  extendedLevelList: (state) => {
    const levels = cloneDeep(state.data.game?.level);
    if (!levels) return [];
    if (!state.data.plugins) return levels;
    else {
      // TODO if the backend provides info in level data
      // we don't need to manually look for default levels; see issue: #396
      state.data.plugins.forEach((plugin) => {
        if (!plugin.items) return;
        Object.entries(plugin.items).forEach(([collection, data]) => {
          if (!data.items) return;
          data.items.forEach((item) => {
            if (!item.settings.level) return;
            const level = levels.find((e) => e.name === item.settings.level);
            if (level)
              level.default = {
                level: item.settings.level,
                item: item.name,
                plugin: plugin.name,
                collection: collection,
              };
          });
        });
      });
      return levels;
    }
  },
  selections: (state) => {
    return {
      game: state["selected"]["game"],
      level: state["selected"]["level"],
    };
  },
  selectedActionData: (state) => {
    const id = state.selected.action;
    return state.data.level?.actions[id];
  },
  selectedActionSchema: (state) => {
    // TODO: refactor to new actions endpoint
    const id = state.selected.action;
    const selected = state.data.level.actions[id].action;
    const schema = state.data.actions?.find(
      (a) => a.action == selected
    )?.schema;
    return schema;
  },
  actionDefinitionByActionId: (state) => (actionId) => {
    const type = state.data.level.actions[actionId]?.action;
    const definition = state.data.actions.find((a) => a.action == type);
    return definition || undefined;
  },
  statesNameList: (state) => {
    return Object.values(state.data.level.states).reduce((accu, curr) => {
      accu.push(curr.name);
      return accu;
    }, []);
  },
  stateByName: (state) => (name) => {
    let entries = Object.entries(state.data.level.states);
    let match = entries.find((e) => e[1].name === name);
    return match;
  },
  actionsByState: (state) => (stateId) => {
    const stateData = state["data"]["level"]["states"][stateId];
    return [...stateData.listen, ...stateData.run].reduce(
      (res, key) => ((res[key] = state["data"]["level"]["actions"][key]), res),
      {}
    );
  },
  actionsByActionTypeInState: (state, getters) => (stateId, type) => {
    const stateActions = getters.actionsByState(stateId);
    let actions = {};
    Object.keys(stateActions).forEach((key) => {
      if (stateActions[key]["action"] === type)
        actions[key] = stateActions[key];
    });
    return actions;
  },
  sumActionsInState: (state, getters) => (stateId, actionType) => {
    const all = Object.entries(getters.actionsByState(stateId));
    return actionType
      ? // eslint-disable-next-line no-unused-vars
        all.filter(([key, value]) =>
          value ? value.action === actionType : false
        ).length
      : Object.keys(all).length;
  },
  contentsByState: (state, getters) => (stateId) => {
    const stateActions = getters.actionsByState(stateId);
    const allContentIds = Object.keys(state["data"]["level"]["contents"]);
    let values = [];
    const getAllValues = (obj) => {
      for (let key in obj) {
        typeof obj[key] === "object"
          ? getAllValues(obj[key])
          : values.push(obj[key]);
      }
    };
    getAllValues(stateActions);
    const stateContentIds = values.filter((value) =>
      allContentIds.includes(value)
    );
    return stateContentIds.reduce(
      (res, key) => ((res[key] = state["data"]["level"]["contents"][key]), res),
      {}
    );
  },
  contentIdsByAction: (state) => (actionId) => {
    const contents = [];
    const resolveContent = (obj) => {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          resolveContent(obj[key]);
        } else if (
          typeof obj[key] === "string" &&
          obj[key].startsWith("_content")
        ) {
          contents.push(obj[key]);
        }
      }
      return obj;
    };
    resolveContent(state.data.level.actions[actionId]);
    return contents;
  },
  actionByIdResolved: (state, getters) => (actionId) => {
    const resolved = cloneDeep(state.data.level.actions[actionId]);
    const resolveContent = (obj) => {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          resolveContent(obj[key]);
        } else if (
          typeof obj[key] === "string" &&
          obj[key].startsWith("_content")
        ) {
          obj[key] = getters.contentById(obj[key]);
        }
      }
      return obj;
    };
    resolveContent(resolved);
    return resolved;
  },
  contentById: (state) => (contentId) => {
    // FIXME: multi language support
    const content = state.data.level.contents[contentId]?.["de"];
    if (content == undefined)
      console.error(
        `No Content 'de' for ${contentId} in: ${JSON.stringify(
          state.data.level.contents[contentId],
          null,
          2
        )}`
      );
    return content || "";
  },
  actionsSortedByPlugin: (state) => () => {
    const all = state.data.actions;
    return all?.reduce((result, currentValue) => {
      if (!result[currentValue["plugin"]]) result[currentValue["plugin"]] = [];
      result[currentValue["plugin"]].push(currentValue);
      return result;
    }, {});
  },
  sessionsSortedByLevel: (state) => {
    const all = state.data.sessions;
    return all?.reduce((result, currentValue) => {
      if (!result[currentValue["level"]]) result[currentValue["level"]] = [];
      result[currentValue["level"]].push(currentValue);
      return result;
    }, {});
  },
  sessionsByLevel: (state) => (level) => {
    return state.data.sessions?.filter((session) => session.level == level);
  },
  stylesByElement:
    (state) =>
    (element, filter = []) => {
      // accepts undefined filters, single string or list
      filter = typeof filter === "string" ? [filter] : filter;
      const styles = state.styles[element] || state.styles["default"];
      let filtered = "";
      for (const [key, value] of Object.entries(styles)) {
        if (filter.length == 0) filtered += ` ${value}`;
        else if (filter.includes(key)) filtered += ` ${value}`;
      }
      return filtered;
    },
  stylesObjectByElement: (state) => (element) => {
    return state.styles[element] || state.styles["default"];
  },
  currentLiveStates: (state) => (sessionId) => {
    const session = state.data.sessions?.find((s) => s._id == sessionId);
    return session ? session.current_states.map((c) => c.id) : [];
  },
  actionInfo: (state, getters) => (actionId) => {
    const action = getters.actionByIdResolved(actionId);
    const template = getters.actionDefinitionByActionId(actionId)?.template;
    // FIXME: this should be rendered by server not in client
    const renderFunc = new Function("payload", "action", template);
    let info;
    try {
      info = renderFunc(action.payload, action);
    } catch (error) {
      console.error(error);
    }
    return {
      title: info?.title || action.name || action.action,
      subtitle: info?.subtitle || "",
      body: info?.body || Helpers.findAllByKey(action.payload || {}, "next"),
    };
  },
};
