<template>
  <div
    class="log-dimensions px-2 pb-2 is-flex is-flex-direction-column has-border-top"
    v-if="$store.state.selected.serverlogVisible"
  >
    <nav class="level mb-1">
      <div class="level-left">
        <div class="level-item pl-1">
          <Icon :icon="'terminal'" :interactive="false" />
        </div>
        <div class="level-item">
          <p class="subtitle is-5">Console</p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <button
            class="button is-ghost"
            @click="$store.dispatch('toggleServerLog')"
          >
            <Icon class="has-text-dark" :icon="'x'" :tooltip="'Close Log'" />
          </button>
        </div>
      </div>
    </nav>

    <div class="field is-horizontal m-0">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div
              class="select"
              @change="(e) => $store.dispatch('logConnect', e.target.value)"
            >
              <select>
                <option value="error">error</option>
                <option value="warn">warn</option>
                <option value="info">info</option>
                <option value="debug" selected>debug</option>
                <option value="trace">trace</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field has-addons">
          <p class="control is-expanded">
            <input
              type="text"
              class="input"
              v-model="filter"
              placeholder="Filter Log"
              @change="filterData"
            />
          </p>
          <p class="control">
            <button class="button">
              <Icon
                :icon="'delete'"
                :tooltip="'clear input'"
                @click="filter = ''"
              />
            </button>
          </p>
        </div>
      </div>
    </div>

    <div
      class="is-flex-grow-1 p-2 is-not-clipped has-background-black has-text-light is-family-monospace"
    >
      <p
        v-for="log in filtered.slice().reverse()"
        :key="log.id"
        class="is-selectable pb-2"
        :style="{ 'line-height': 1 }"
      >
        {{ log.id }}
        <span
          :class="{
            'has-text-danger': log.level == 'error',
            'has-text-warning': log.level == 'warn',
            'has-text-adaptor': log.level == 'info',
            'has-text-success': log.level == 'debug',
            'has-text-info': log.level == 'trace',
          }"
        >
          {{ log.level }}&nbsp;
        </span>
        <span class="has-text-adaptor">{{ log.topic }}</span>
        <br />
        {{ log.message }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "Log",
  setup() {
    const store = useStore();
    const serverlog = computed(() => store.state.data.serverlog);
    const filter = ref("");

    const filtered = computed(() =>
      serverlog.value.filter((log) => {
        const f = filter.value.toLowerCase();
        if (!f) return true;
        else if (log.message.toLowerCase().includes(f)) return true;
        else if (log.topic.toLowerCase().includes(f)) return true;
        else if (log.level.toLowerCase().includes(f)) return true;
        else if (log.id.toString().toLowerCase().includes(f)) return true;
        else return false;
      })
    );

    return { filtered, filter };
  },
};
</script>

<style lang="scss" scoped>
.log-dimensions {
  height: 60vh;
  width: 75ch;
}
</style>
