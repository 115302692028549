import empty from "json-schema-empty";

/*
 * Because Adaptor uses different protocols and languages we restrict naming of
 * States, Actions, Games etc to these characters:
 * ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
 * -._~:#&
 * no whitespaces
 */
export function hasOnlyAllowedCharacters(str) {
  if (!str) return false;
  const regexp =
    /^[ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789\-._~:#']+$/;
  return regexp.test(str);
}

/*
 * Calculates difference between two Dates.
 * Returns String formatted as "DD days hh:mm:ss", below 24 hours difference
 * days are omitted.
 */
export function getTimeDiffString(datePast, dateNow) {
  // console.log("diff",datePast, dateNow );
  const dayInSeconds = 86400,
    hourInSeconds = 3600,
    minuteInSeconds = 60;

  if (typeof datePast === "string") datePast = new Date(datePast);
  if (typeof dateNow === "string") dateNow = new Date(dateNow);
  let diffInSeconds = Math.abs(dateNow - datePast) / 1000;

  const days = Math.floor(diffInSeconds / dayInSeconds);
  diffInSeconds -= days * dayInSeconds;
  const hours = Math.floor(diffInSeconds / hourInSeconds);
  diffInSeconds -= hours * hourInSeconds;
  const minutes = Math.floor(diffInSeconds / minuteInSeconds);
  diffInSeconds -= minutes * minuteInSeconds;
  const seconds = Math.floor(diffInSeconds);

  let formatted = "";
  if (days > 0) formatted += days === 1 ? `${days} day ` : `${days} days `;
  formatted += hours < 10 ? `0${hours}:` : `${hours}:`;
  formatted += minutes < 10 ? `0${minutes}:` : `${minutes}:`;
  formatted += seconds < 10 ? `0${seconds}` : `${seconds}`;

  return formatted;
}

export function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  );
}

export function findAllByKey(obj, keyToFind) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) =>
      key === keyToFind
        ? acc.concat({ [keyToFind]: value })
        : typeof value === "object"
        ? acc.concat(findAllByKey(value, keyToFind))
        : acc,
    []
  );
}


/*
 * Builds object with minimal properties and values based on JSON Schema.
 * If default is defined it is returned.
 */
export function objectFromSchema(schema) {
  return empty(schema);
}
