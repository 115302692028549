<template>
  <div class="has-background-purple-5 pl-2 is-routerview-high">
    <h1>
      Game Settings <b v-if="game">{{ game.name }}</b>
    </h1>
    <br />

    <div v-if="game" class="tabs">
      <ul>
        <li class="tab-link is-active" @click="openTab($event, 'tab_plugins')">
          <a>PLUGINS</a>
        </li>
        <li class="tab-link" @click="openTab($event, 'tab_collections')">
          <a>COLLECTIONS</a>
        </li>
        <li class="tab-link" @click="openTab($event, 'tab_setup')">
          <a>SETUP</a>
        </li>
      </ul>
    </div>
    <div v-if="game" class="tab-container is-routerview-high is-not-clipped">
      <div id="tab_plugins" class="tab-content">
        <PluginSettings />
      </div>
      <div id="tab_collections" class="tab-content is-hidden">
        <CollectionSettings />
      </div>
      <div id="tab_setup" class="tab-content is-hidden">
        <div>{{ game.setup }}</div>
      </div>
    </div>
    <Prompt />
  </div>
</template>

<script>
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import PluginSettings from "@/components/Game/PluginSettings.vue";
import CollectionSettings from "@/components/Game/CollectionSettings.vue";
import Prompt from "@/components/Template/Prompt.vue";

export default {
  name: "GameSettings",
  components: { PluginSettings, CollectionSettings, Prompt },
  setup() {
    // Connect to Store and Router
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    // We need to have info about game and its plugins here
    if (!route.query.game) router.push({ path: "/" });
    if (!store.state.data.game) store.dispatch("selectGame", route.query.game);
    if (!store.state.data.plugins_list) store.dispatch("getPlugins");
    onMounted(() => {
      store.dispatch("findPlugins");
    });

    // Compute necessary Store values for Component
    const game = computed(() => store.state.data.game);

    // Create local References

    // Create local Callback Functions

    const openTab = (e, tabName) => {
      // hide everything
      const links = document.querySelectorAll("li.tab-link:not(.dashboard)");
      const tabs = document.querySelectorAll("div.tab-content:not(.dashboard)");
      Array.from(links).forEach((node) => node.classList.remove("is-active"));
      Array.from(tabs).forEach((node) => node.classList.add("is-hidden"));
      // show selection
      document.getElementById(tabName).classList.remove("is-hidden");
      e.currentTarget.classList.add("is-active");
    };

    return {
      game,
      openTab,
    };
  },
};
</script>
