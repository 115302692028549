import { JSONEditor } from "@json-editor/json-editor";

// TODO: customize this. see other src/themes in node module of @json-editor/json-editor
export class AdaptorTheme extends JSONEditor.AbstractTheme {
  getFormInputLabel(text, req) {
    const container = document.createElement("div");
    container.classList.add("je-form-input-label");

    const el = document.createElement("label");
    el.appendChild(document.createTextNode(text));
    if (req) container.classList.add("required");
    container.appendChild(el);
    return container;
  }

  //   getDescription(text) {
  //   return super.getInfoButton(text);
  //  }

  getDescription(text) {
    const el = super.getInfoButton(text);
    el.classList.add("je-tooltip-icon");
    return el;
  }

  getLinksHolder() {
    const el = document.createElement("div");
    el.style.display = "none"; // hide
    return el;
  }

  getFormControl(label, input, description, infoText) {
    const el = document.createElement("div");
    el.classList.add("form-control");

    if (label && description) label.appendChild(description);
    else if (description) el.appendChild(description);

    if (label) el.appendChild(label);

    if ((input.type === "checkbox" || input.type === "radio") && label) {
      input.style.width = "auto";
      label.insertBefore(input, label.firstChild);
      if (infoText) label.appendChild(infoText);
    } else {
      if (infoText && label) label.appendChild(infoText);
      el.appendChild(input);
    }
    return el;
  }

  getIndentedPanel() {
    const el = super.getIndentedPanel();
    el.classList.add("je-indented-panel");
    return el;
  }

  getTopIndentedPanel() {
    return this.getIndentedPanel();
  }

  getChildEditorHolder() {
    const el = super.getChildEditorHolder();
    el.classList.add("je-child-editor-holder");
    return el;
  }

  getHeaderButtonHolder() {
    const el = this.getButtonHolder();
    el.classList.add("je-header-button-holder");
    return el;
  }

  getButton(text, icon, title) {
    let classes = ["button", "is-small"];
    // TODO: fix this in proper place. this is only a test
    if (text == "Object Properties") {
      text = "Settings ±";
      classes.push("ml-1", "has-background-white");
    } else if (text == "Edit JSON") {
      text = "JSON";
      classes.push("ml-1", "has-background-white");
    }
    // else if (text == "Delete" || text == "Delete Content") {
    //   text = "";
    //   classes.push("has-background-transparent", "has-background-black");
    // }
    else if (
      text == "Delete condition" ||
      text == "Delete" ||
      text == "Delete Content" ||
      text == "Delete item"
    )
      return super.getButton(text, icon, title);
    const el = document.createElement("button");
    el.type = "button";
    el.classList.add(...classes);
    this.setButtonText(el, text, icon, title);
    return el;
  }

  getModal() {
    const el = document.createElement("div");
    el.style.display = "none";
    el.classList.add("adaptor-modal");
    return el;
  }

  getTextareaInput() {
    const el = document.createElement("textarea");
    el.classList.add("adaptor-textarea");
    const resize = (el) => {
      el.style.height = "auto";
      el.style.height = el.scrollHeight + 6 + "px";
    };
    setTimeout(() => resize(el), 1); // FIXME: trigger first resize on editor value init
    el.addEventListener("input", (evt) => resize(evt.target), false);
    return el;
  }

  getTable() {
    const el = super.getTable();
    el.classList.add("je-table");
    return el;
  }

  getTopTabHolder(propertyName) {
    const pName = typeof propertyName === "undefined" ? "" : propertyName;
    const el = document.createElement("div");
    el.innerHTML = `<div class='je-tabholder--top'></div><div class='je-tabholder--clear'></div><div class='content' id='${pName}'></div>`;
    return el;
  }

  markTabActive(row) {
    this.applyStyles(row.tab, {
      opacity: 1,
      // background: 'white'
    });
    if (typeof row.rowPane !== "undefined") {
      row.rowPane.style.display = "";
    } else {
      row.container.style.display = "";
    }
  }

  addInputError(input, text) {
    const group = this.closest(input, ".form-control") || input.controlgroup;

    if (!input.errmsg) {
      input.errmsg = document.createElement("div");
      input.errmsg.setAttribute("class", "errmsg");
      input.errmsg.style = input.errmsg.style || {};
      group.appendChild(input.errmsg);
    } else {
      input.errmsg.style.display = "block";
    }
    input.errmsg.innerHTML = "";
    input.errmsg.appendChild(document.createTextNode("Error: " + text));
  }

  removeInputError(input) {
    if (input.style) {
      input.style.borderColor = "";
    }
    if (input.errmsg) input.errmsg.style.display = "none";
  }

  getErrorMessage(text) {
    const el = super.getErrorMessage(text);
    el.setAttribute("class", "je-errmsg");
    return el;
  }
}

/* Custom stylesheet rules. format: "selector" : "CSS rules" */
AdaptorTheme.rules = {};

/* Add AdaptorTheme to JSONEditor */
// TODO: we could import the theme differently, to not load it everytime a editor is opend
// in main.js: import "./styles/json-editor/adaptorTheme.js";
// here:
// JSONEditor.defaults.theme = "adaptor";
// JSONEditor.defaults.themes.adaptor = AdaptorTheme;
