<template>
  <div
    class="has-border mt-2 mr-1 mb-2 ml-2 p-1 is-unselectable"
    :class="[styles.bgColorAction]"
  >
    <div v-if="stateSelected && !$store.state.selected.is_live">
      <Popup
        class="is-pulled-right ml-1"
        :toggle="togglePopup"
        @isOpen="togglePopup = true"
      >
        <template v-slot:anchor>
          <Icon :icon="'trash-2'" />
        </template>
        <template v-slot:content>
          <div class="dropdown-item p-2">
            <div class="field is-grouped">
              <p class="control">
                <a class="button is-danger" @click="handleDeleteClick">
                  Delete Action
                </a>
              </p>
              <p class="control">
                <a class="button is-light" @click="togglePopup = false">
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </template>
      </Popup>
    </div>

    <!-- DEFAULT SHOW ACTION INFOS -->
    <div
      v-if="!actionSelected"
      @click.stop="$emit('selectAction')"
      class="is-cursor-pointer"
    >
      <div class="is-flex is-align-items-center pb-1">
        <p class="has-text-weight-bold">{{ info.title }}</p>
        <Icon
          v-if="!isValid"
          class="ml-2 has-background-white has-text-danger bug-icon"
          :interactive="false"
          :icon="'alert-triangle'"
        />
      </div>
      <!-- CONTENT INFO -->
      <div v-if="info.subtitle" :class="[styles.bgColorNext, 'is-action-info']">
        <p class="has-text-weight-bold has-text-lines-2">{{ info.subtitle }}</p>
      </div>
      <div
        v-for="(row, index) of info.body"
        :key="actionId + '-' + index"
        :class="[styles.bgColorNext, 'is-action-info']"
      >
        <Line
          v-if="row.next"
          :from="stateName"
          :next="row.next"
          :text="row.text"
          class="has-text-lines-3"
        />
        <p v-else class="has-text-lines-3">
          {{ row.text && row.text.length > 0 ? row.text : "Next: not defined" }}
        </p>
      </div>
    </div>
    <!-- IF ACTION SELECTED SHOW EDITOR -->
    <ActionEditor
      v-else
      :stateId="stateId"
      @selectAction="$emit('selectAction')"
      @validateAction="(result) => (isValid = result)"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ActionEditor from "@/components/Level/ActionEditor.vue";
import Popup from "@/components/Template/Popup.vue";

import Line from "./Line.vue";

export default {
  name: "ActionNode",
  props: {
    actionId: {
      type: String,
      required: true,
    },
    stateName: {
      type: String,
      required: true,
    },
    stateId: {
      type: String,
      required: true,
    },
  },
  components: {
    ActionEditor,
    Line,
    Popup,
  },
  emits: ["selectAction"],
  setup(props) {
    const store = useStore();

    const styles = computed(() =>
      store.getters.stylesObjectByElement(
        store.getters.actionDefinitionByActionId(props.actionId)?.plugin
      )
    );
    const actionSelected = computed(
      () => store.state.selected.action == props.actionId
    );
    const stateSelected = computed(
      () => store.state.selected.state == props.stateId
    );
    const info = computed(() => store.getters.actionInfo(props.actionId));

    const togglePopup = ref(false);
    const isValid = ref(true);

    const handleDeleteClick = () => {
      store.dispatch("deleteAction", {
        actionId: props.actionId,
        stateId: props.stateId,
      });
    };

    return {
      styles,
      info,
      isValid,
      actionSelected,
      stateSelected,
      togglePopup,
      handleDeleteClick,
    };
  },
};
</script>
