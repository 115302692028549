<template>
  <div
    v-for="collection in names"
    :key="collection"
    class="has-background-white has-border m-2"
  >
    <!-- COLLECTIONS -->
    <Collapsible>
      <template v-slot:header>
        <div class="p-1" @click="findDocuments(collection)">
          <nav class="level">
            <!-- Left side -->
            <div class="level-left ml-1">
              <div class="level-item">
                <p class="subtitle">
                  {{ collection }}
                </p>
              </div>
            </div>
            <!-- Right side -->
            <div class="level-right">
              <p class="level-item is-danger">
                <a
                  class="has-text-danger"
                  @click.stop="
                    setModal({
                      title: 'Delete Collection',
                      text: `Do you really want do delete the Collection ${collection}?`,
                      confirmButtonText: `Delete ${collection}`,
                      style: 'is-danger',
                      onConfirm: () => deleteCollection(collection),
                    })
                  "
                >
                  Delete Collection
                </a>
              </p>
              <p class="level-item">
                <a
                  class="button is-success"
                  @click.stop="
                    setModal({
                      title: 'Create Item',
                      text: `Create new Item in Collection ${collection}`,
                      value: keys[collection]
                        ? keys[collection].reduce(
                            (o, key) => ({ ...o, [key]: '' }),
                            {}
                          )
                        : '{}',
                      confirmButtonText: 'Create',
                      onConfirm: () => createDocument(collection),
                      mode: 'multiline',
                    })
                  "
                >
                  Add Item
                </a>
              </p>
            </div>
          </nav>
        </div>
      </template>
      <template v-slot>
        <!-- FIX STYLE SET HEIGHT -->
        <div class="table-container" style="max-height: 60vh">
          <!-- TABLES -->
          <table
            class="table is-striped is-bordered"
            v-if="data[collection] && data[collection].length > 0"
          >
            <thead>
              <tr>
                <th
                  v-for="(key, index) in keys[collection]"
                  :key="`${collection}_key_${index}`"
                >
                  {{ key }}
                </th>
                <th>Document</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="document in data[collection]" :key="document._id">
                <td
                  v-for="(key, index) in keys[collection]"
                  :key="`${document._id}_${index}`"
                >
                  {{ document?.[key] || "" }}
                </td>
                <td>
                  <details>
                    <summary>JSON</summary>
                    {{ document }}
                  </details>
                </td>
                <td>
                  <div class="field is-grouped is-grouped-multiline">
                    <p class="control">
                      <a
                        class="button is-rounded is-light"
                        @click.stop="
                          setModal({
                            title: 'Update Item',
                            text: `Update Item ${document._id} in Colletction ${collection}?`,
                            value: JSON.stringify(document, null, 2),
                            confirmButtonText: 'Update',
                            onConfirm: () => updateDocument(collection),
                            mode: 'multiline',
                          })
                        "
                      >
                        <Icon class="has-text-dark" :icon="'edit'" />
                      </a>
                    </p>
                    <p class="control">
                      <a
                        class="button is-rounded is-light"
                        @click.stop="
                          setModal({
                            title: 'Delete Item',
                            text: `Do you really want do delete the Item ${document._id}?`,
                            confirmButtonText: `Delete`,
                            style: 'is-danger',
                            onConfirm: () =>
                              deleteDocument(collection, document._id),
                          })
                        "
                      >
                        <Icon class="has-text-danger" :icon="'trash-2'" />
                      </a>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- LOADING TABLE -->
          <a
            v-else-if="!data[collection]"
            class="button is-loading is-fullwidth"
          >
            Loading
          </a>
          <!-- NO DATA IN TABLE -->
          <span v-else class="m-3 content">
            No data in Collection {{ collection }}.
          </span>
        </div>
      </template>
    </Collapsible>
    <!-- ADD BUTTON -->
  </div>
  <section>
    <button
      class="button is-dark m-2"
      @click.stop="
        setModal({
          title: 'Create New Collection',
          text: `Give your collection a name. For better readability it is recommended to use UPPER CASE letters.`,
          confirmButtonText: 'Create',
          onConfirm: () => createCollection(),
          mode: 'input',
        })
      "
    >
      New Collection
    </button>
  </section>

  <!-- MODAL -->
  <Modal
    v-if="modal.visible"
    :modal="modal"
    @closeModal="modal.visible = false"
    :cssClasses="['message', modal.style]"
  >
    <template v-slot:header> {{ modal.title }} </template>
    <template v-if="modal.mode == 'input'" v-slot>
      <label for="value"> {{ modal.text }} </label>
      <input class="input" type="text" name="value" v-model="modal.value" />
    </template>
    <template v-else-if="modal.mode == 'multiline'" v-slot>
      <label for="value"> {{ modal.text }} </label>
      <pre
        class="has-text-black"
        contenteditable="true"
        @input="modal.value = $event.target.textContent"
        >{{ modal.originalValue }}</pre
      >
    </template>
    <template v-else v-slot>
      <label for="value"> {{ modal.text }} </label>
    </template>
    <template v-slot:footer>
      <button class="button m-1" :class="modal.style" @click="modal.onConfirm">
        {{ modal.confirmButtonText }}
      </button>
      <button class="button m-1" @click="modal.onDismiss">
        {{ modal.dismissButtonText }}
      </button>
    </template>
  </Modal>
</template>

<script>
import { reactive, computed, ref, toRefs } from "vue";
import { useStore } from "vuex";

import Collapsible from "@/components/Template/Collapsible.vue";
import Modal from "@/components/Template/Modal.vue";

export default {
  name: "CollectionSettings",
  props: [],
  emits: [],
  components: { Collapsible, Modal },
  setup() {
    const store = useStore();

    const collections = reactive({
      names: computed(() => store.state.data.game.collections),
      data: computed(() => store.state.data.collections),
      keys: computed(() => store.state.data.collections._keys),
    });

    const defaultModal = {
      visible: false,
      onConfirm: undefined, // function to call by confirm button in modal
      onDismiss: () => (modal.value.visible = false), // function to call by cancel button in modal
      title: "...",
      text: "...",
      confirmButtonText: "Confirm",
      dismissButtonText: "Cancel",
      style: "is-info", // delete or add
      mode: "", // input gives modal with input field
      value: undefined, // value from input field
      originalValue: undefined
    };

    const modal = ref({ ...defaultModal });

    function setModal(args) {
      modal.value = Object.assign(
        modal.value,
        defaultModal,
        { visible: true,
          originalValue: args?.value || "" },
        args
      );
    }

    function findDocuments(collection) {
      store.dispatch("findDocuments", collection);
    }

    function createDocument(collection) {
      store
        .dispatch("createDocument", {
          collection,
          document: modal.value.value,
        })
        .then(
          () => setModal({ visible: false }),
          (e) => console.error(e) // on parse to json error
        );
    }

    function updateDocument(collection) {
      store
        .dispatch("updateDocument", {
          collection,
          document: modal.value.value,
        })
        .then(
          () => setModal({ visible: false }),
          (e) => console.error(e) // on parse to json error
        );
    }

    function deleteDocument(collection, document) {
      store
        .dispatch("deleteDocument", { collection, document })
        .then(() => setModal({ visible: false }));
    }

    function createCollection() {
      store
        .dispatch("createCollection", { name: modal.value.value })
        .then(() => setModal({ visible: false }));
    }

    function deleteCollection(collection) {
      store
        .dispatch("deleteCollection", { collection })
        .then(() => setModal({ visible: false }));
    }

    return {
      ...toRefs(collections),
      modal,
      setModal,
      findDocuments,
      createDocument,
      updateDocument,
      deleteDocument,
      createCollection,
      deleteCollection,
    };
  },
};
</script>
