<template>
  <div :class="['dropdown', isUnlocked && isUnfold ? 'is-active' : '']">
    <!-- ICON ANCHOR/TRIGGER FOR DROPDOWN -->
    <div class="dropdown-trigger ml-1 mr-1 is-flex">
      <Icon
        :icon="'message-square'"
        :fill="comments && comments.length > 0 ? 'black' : undefined"
        :tooltip="comments?.[0]"
        @click="
          if (!isUnlocked) isUnfold = true;
          else isUnfold = !isUnfold;
        "
      />
    </div>

    <div class="dropdown-menu p-0">
      <div class="dropdown-content p-1 pt-2 has-border">
        <!-- DROPDOWN ITEMS LOOP -->
        <div v-for="(comment, index) in comments" :key="'comment-' + index">
          <div class="dropdown-item p-1">
            <!-- DELETE COMMENT ICON -->
            <Icon
              class="is-layer-3 mt-1 mr-2"
              style="position: absolute; right: 0px"
              :icon="'trash-2'"
              @click="$emit('deleteComment', index)"
            />
            <!-- COMMENT TEXTAREA -->
            <textarea
              class="textarea p-1"
              rows="3"
              @change="$emit('updateComment', index, $event.target.value)"
              :value="comment"
            />
          </div>
          <hr
            v-if="comments.length > 1 && index < comments.length - 1"
            class="dropdown-divider"
          />
        </div>
        <div class="dropdown-item p-1">
          <div v-if="addComment">
            <!-- NEW COMMENT TEXTAREA -->
            <textarea class="textarea p-1" rows="3" v-model="newComment" />
            <!-- NEW COMMENT BUTTONS -->
            <div class="field is-grouped mt-2">
              <p class="control">
                <a
                  class="button"
                  @click="
                    $emit('updateComment', -1, newComment);
                    isUnfold = false;
                    addComment = false;
                    newComment = '';
                  "
                >
                  Save
                </a>
              </p>
              <p class="control">
                <a
                  class="button"
                  @click="
                    addComment = false;
                    isUnfold = false;
                  "
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
          <!-- GENERAL COMMENT BUTTONS -->
          <div v-else class="field is-grouped mb-1">
            <p class="control">
              <a class="button" @click="addComment = true"> New Comment </a>
            </p>
            <p class="control">
              <a class="button" @click="isUnfold = false"> Close </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  name: "Comment",
  props: { comments: Array, isUnlocked: { type: Boolean, default: false } },
  emits: ["updateComment", "deleteComment"],
  setup(props) {
    const isUnfold = ref(false);
    const addComment = ref(false);
    const newComment = ref(null);
    watch(
      () => props.isUnlocked,
      () => {
        if (!props.isUnlocked) isUnfold.value = false;
      }
    );
    return {
      isUnfold,
      addComment,
      newComment,
    };
  },
};
</script>
