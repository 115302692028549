<template>
  <p ref="linestartDiv">
    {{ text ? text : `Next: ${next}` }}
  </p>
</template>

<script>
import {
  onMounted,
  ref,
  watch,
  inject,
  onBeforeUnmount,
  nextTick,
} from "@vue/runtime-core";

import LeaderLine from "leader-line-new";

export default {
  name: "Line",
  props: {
    text: String,
    next: String,
    from: String,
  },
  setup(props) {
    const linestartDiv = ref(null);
    const lineendDiv = ref(null);
    const lastScroll = inject("globalScrollEvent");
    const lastStateUpdate = inject("globalStateUpdate");

    const createNewLine = () => {
      if (lineendDiv.value) {
        linestartDiv.line = new LeaderLine(
          linestartDiv.value,
          lineendDiv.value,
          {
            color: "black",
            size: 2,
            endPlugSize: 2,
            endPlug: "arrow3",
            startPlug: "disc",
            startPlugSize: 3,
            startPlugColor: "white",
            startPlugOutline: true,
            startPlugOutlineSize: 1.5,
            startPlugOutlineColor: "black",
            startSocket: "right",
            endSocket: "left",
            path: "grid",
            startSocketGravity: 5,
            endSocketGravity: 13,
          }
        );
      } else {
        //console.log(`no lineendDiv. will not create line`);
      }
    };

    const updateLine = () => {
      const newlineendDiv = document.getElementById(props.next);
      // TODO: OPTIMIZATION: filter if neither from nor next changed. if so: only do line.position(), nothing else.

      if (linestartDiv.line && newlineendDiv) {
        lineendDiv.value = newlineendDiv;
        linestartDiv.line.end = lineendDiv.value;
        linestartDiv.line.position();
      } else if (linestartDiv.line && !newlineendDiv) {
        linestartDiv.line.remove();
        linestartDiv.line = null;
      } else if (!linestartDiv.line && newlineendDiv) {
        lineendDiv.value = newlineendDiv;
        createNewLine();
      }
    };

    onBeforeUnmount(() => {
      if (linestartDiv && linestartDiv.line) {
        linestartDiv.line.remove();
        linestartDiv.line = null;
      }
    });

    onMounted(() => {
      lineendDiv.value = document.getElementById(props.next); // get end point for line
      nextTick(() => createNewLine());
    });

    watch(
      () => props.next,
      () => {
        console.log("next changed", props.next);
        updateLine();
      }
    );

    watch(
      () => props.from,
      () => {
        console.log("from changed", props.from);
        updateLine();
      }
    );

    watch(lastScroll, function () {
      updateLine();
    });

    watch(lastStateUpdate, () => {
      nextTick(() => updateLine());
    });

    return {
      lastStateUpdate,
      linestartDiv,
    };
  },
};
</script>
