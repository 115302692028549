<template>
  <table
    class="
      table
      has-background-transparent
      is-bordered is-hoverable is-fullwidth
    "
  >
    <thead>
      <tr>
        <th @click="order = !order">
          <p class="is-flex is-align-items-center is-unselectable">
            Name
            <Icon :icon="order ? 'arrow-down' : 'arrow-up'" class="ml-2" />
          </p>
        </th>
        <th style="width: 25ch">Status/Comment</th>
        <th>Description</th>
        <th>Arguments</th>
        <th>Default</th>
        <th>Modified</th>
        <th>Created</th>
        <th class="has-no-outline"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="level in sorted" :key="level.name">
        <td class="is-clickable">
          <button
            @click="$emit('levelSelect', level.name)"
            class="
              button
              p-0
              is-primary is-fullwidth
              has-text-weight-semibold
              is-justify-content-flex-start
            "
          >
            <Icon class="m-1 has-text-adaptor" :icon="'arrow-up-right'" />
            {{ level.name }}
          </button>
        </td>
        <td class="">
          <Datalist
            :selection="level?.config?.status"
            :options="['done', 'work in progress', 'translate']"
            @selected="$emit('levelUpdate', level.name, $event)"
          />
        </td>
        <td class="content is-small">{{ level?.config?.description }}</td>
        <td class="content is-small">
          {{ Object.keys(level?.config?.arguments).join(", ") }}
        </td>
        <td class="content is-small">
          {{ level.default?.item }}
          <br />
          {{ level.default?.plugin }}
        </td>
        <td class="content is-small">
          {{ level.created_by }}
          <br />
          {{
            new Date(level.created_at)
              .toDateString()
              .replace("Invalid Date", "")
          }}
        </td>
        <td class="content is-small">
          {{ level.modified_by }}
          <br />
          {{
            new Date(level.modified_at)
              .toDateString()
              .replace("Invalid Date", "")
          }}
        </td>
        <td class="has-no-outline pl-1">
          <div class="is-flex">
            <Icon
              class="m-1 has-text-adaptor"
              :icon="'arrow-up-right'"
              @click="$emit('levelSelect', level.name)"
            />
            <Icon
              class="m-1"
              :icon="'trash-2'"
              @click.stop="$emit('levelDelete', level.name)"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Datalist from "@/components/Template/Datalist.vue";
import { computed, ref } from "@vue/runtime-core";
import { cloneDeep } from "lodash";

export default {
  name: "LevelList",
  props: {
    levels: { type: Array, required: true },
  },
  emits: ["levelSelect", "levelUpdate", "levelDelete"],
  components: { Datalist },
  setup(props) {
    const order = ref(true);
    const levelArray = computed(() => cloneDeep(props.levels));
    const sorted = computed(() => {
      if (order.value == true) {
        levelArray.value.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        levelArray.value.sort((a, b) => (a.name > b.name ? -1 : 1));
      }
      return levelArray.value;
    });

    return { sorted, order };
  },
};
</script>
