<template>
  <div>
    <nav
      class="navbar has-border-bottom has-border-top has-background-adaptor is-unselectable pr-2"
      :class="[isLive ? 'has-background-is-live' : 'has-background-adaptor']"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-menu">
        <div class="navbar-start">
          <!-- ADAPTOR ICON AND DROPDOWN -->
          <div class="navbar-item has-dropdown has-border-right is-hoverable">
            <a class="navbar-link is-arrowless">
              <img
                :src="isLive ? AdaptorSolidSVG : AdaptorSVG"
                alt="Adaptor Icon"
              />
            </a>
            <div class="navbar-dropdown">
              <router-link to="/" class="navbar-item" @click="blur">
                back to all games
              </router-link>
              <a class="navbar-item" @click.stop="logout">logout</a>
            </div>
          </div>
          <!-- GAME DROPDOWN -->
          <div
            v-if="this.$route.query.game"
            class="navbar-item has-dropdown has-border-right is-hoverable"
          >
            <a class="navbar-link is-arrowless pr-5">GAME</a>
            <div class="navbar-dropdown">
              <router-link
                :to="{ path: 'game', query: { game: this.$route.query.game } }"
                class="navbar-item"
                @click="blur"
              >
                overview
              </router-link>
              <router-link
                :to="{
                  path: 'dashboard',
                }"
                class="navbar-item"
                @click="blur"
              >
                dashboard
              </router-link>
              <router-link
                :to="{
                  path: 'settings',
                }"
                class="navbar-item"
                @click="blur"
              >
                settings
              </router-link>
            </div>
          </div>
          <!-- LEVEL DROPDOWN -->
          <div
            v-if="this.$route.query.level"
            class="navbar-item has-dropdown has-border-right is-hoverable"
          >
            <a class="navbar-link is-arrowless pr-5">LEVEL</a>
            <div class="navbar-dropdown">
              <router-link to="/editor" class="navbar-item" @click="blur">
                edit
                <!-- {{ this.$route.query.level }} -->
              </router-link>
              <router-link to="/levelconfig" class="navbar-item" @click="blur">
                config
                <!-- {{ this.$route.query.level }} -->
              </router-link>
              <!--<a class="navbar-item"> <del>save level</del> </a>-->
              <a class="navbar-item" @click="download(this.$route.query.level)">
                download
                <!-- {{ this.$route.query.level }} -->
              </a>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <router-link
            :to="{ path: 'game', query: { game: this.$route.query.game } }"
            class="navbar-item has-text-weight-semibold"
            @click="blur"
          >
            {{ this.$route.query.game }}
          </router-link>
          <div
            v-if="this.$route.query.game"
            class="navbar-item has-dropdown is-hoverable"
          >
            <a class="navbar-link has-text-weight-semibold">
              {{ this.$route.query.level }}
            </a>
            <div class="navbar-dropdown is-right is-layer-top">
              <router-link
                v-for="level in levelList"
                :key="level.name"
                :to="{
                  path: '/editor',
                  query: { game: this.$route.query.game, level: level.name },
                }"
                class="navbar-item"
                :class="{
                  'has-text-weight-bold': level.name == this.$route.query.level,
                }"
                @click="blur"
              >
                {{ level.name }}
              </router-link>
            </div>
          </div>

          <div
            class="navbar-item has-dropdown"
            :class="{ 'is-active': $store.state.selected.serverlogVisible }"
            @click="$store.dispatch('toggleServerLog')"
          >
            <button class="button is-ghost has-no-shadow">
              <Icon
                class="pt-1"
                :class="[
                  isLogOpen && !isLive ? 'has-text-is-live' : 'has-text-dark',
                ]"
                :icon="'terminal'"
              />
            </button>
            <div class="navbar-dropdown is-right">
              <!-- we need to stop propagation to avoid Bulma dropdown close -->
              <Log @click.stop />
            </div>
          </div>
          <div v-if="game" class="navbar-item" @click="toggleIsLive">
            <Icon
              :class="[isLive ? 'has-text-black' : 'has-text-is-live']"
              :icon="'play-circle'"
            />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import AdaptorSVG from "@/assets/adaptor.svg";
import AdaptorSolidSVG from "@/assets/adaptor_solid.svg";
import Log from "@/components/Page/Log.vue";

export default {
  name: "Navbar",
  components: { Log },
  setup() {
    const store = useStore();

    const levelList = computed(() => store.state.data.game?.level);
    const game = computed(() => store.state.selected?.game);
    const isLive = computed(() => store.state.selected.is_live);
    const isLogOpen = computed(() => store.state.selected.serverlogVisible);

    const logout = () => {
      localStorage.removeItem("authtoken");
      window.location.reload();
    };

    const download = (name) => {
      const json = JSON.stringify(store.state.data.level, null, 2),
        blob = new Blob([json], { type: "octet/stream" }),
        url = window.URL.createObjectURL(blob),
        downloader = document.createElement("a");
      downloader.href = url;
      downloader.download = `${name}.json`;
      downloader.click();
      window.URL.revokeObjectURL(url);
    };

    const blur = (e) => {
      // TODO: refactor for more nested elements clicked
      // TODO: get rid of blur function and automate add a listener to all dropdown menus to hide them after clicking an item - otherwise the menu stays open
      let menu = e.target.parentNode;
      menu.style.display = "none";
      setTimeout(() => {
        e.target.blur();
        // Reset the display property to its original state, so the menu can appear again next time
        menu.style.display = "";
      }, 200);
    };

    const toggleIsLive = () => {
      if (!isLive.value) {
        store.dispatch("findSessions", {
          game: game.value,
        });
        store.dispatch("selectIsLive", { value: true });
      } else {
        store.dispatch("selectIsLive", { value: false });
      }
    };
    const log = (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log(e);
    };

    return {
      log,
      AdaptorSVG,
      AdaptorSolidSVG,
      levelList,
      game,
      isLive,
      isLogOpen,
      logout,
      download,
      blur,
      toggleIsLive,
    };
  },
};
</script>
