import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import createStore from "@/store";
import { RestApi } from "@/services/api";
import { SocketApi } from "@/services/socket";
import "./styles/main.scss";
import "./styles/colors.scss";
import Icon from "@/components/Template/Icon.vue";
import Toast, { POSITION } from "vue-toastification";

async function main() {
  try {
    // 1. load config file from public folder and merge with defaults
    const response = await fetch("./config.json");
    if (response.status !== 200)
      throw new Error(`Could not load config file from ${response.url}`);
    const userConfig = await response.json();
    const defaultConfig = {
      API_URL: `${window.location.origin}/api`,
      LOG_STORE: false,
      APP_TITLE: "adaptor:ex",
    };
    if (userConfig.API_URL === "") delete userConfig.API_URL;
    const config = Object.assign(defaultConfig, userConfig);

    // 2. change app title
    document.title = config.APP_TITLE;

    // 3. create vue app and set global variable
    const app = createApp(App);
    app.config.globalProperties.$conf = config;

    // 4. create store, pass config and link api and socket clients
    const store = createStore(config.LOG_STORE || false);
    store.$api = new RestApi(config.API_URL).initClient();
    store.$socket = new SocketApi(config.API_URL);
    store.$API_URL = config.API_URL;

    // 5. mount app
    app
      .component("Icon", Icon)
      .use(store)
      .use(router)
      .use(Toast, { position: POSITION.BOTTOM_RIGHT })
      .mount("#app");
  } catch (error) {
    console.error(error);
    const errorDiv = document.createElement("div");
    const errorMsg = document.createTextNode(error.toString());
    errorDiv.appendChild(errorMsg);
    document.body.prepend(errorDiv);
  }
}

main();
